import React from "react";
import {css} from "@emotion/react";
import Image from "../../atoms/Image/Image";
import Title from "../../atoms/Title/Title";
import Paragraph from "../../atoms/Paragraph/Paragraph";

export default function MuchoHola({data}) {

    const sectionContentCss = css`
        display: flex;
        background-color: #096654;
        color: white;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    `;

    const sectionLeftCss = css`
        width: 50%;
        shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: calc(100vw * (calc(240 / var(--width_base))));
        padding-right: calc(100vw * (calc(240 / var(--width_base))));

        @media (max-width: 767px) {
            width: 100%;
            padding-left: calc(100vw * (calc(40 / var(--width_base))));
            padding-right: calc(100vw * (calc(40 / var(--width_base))));
            padding-top: calc(100vw * (calc(91 / var(--width_base))));
            padding-top: calc(100vw * (calc(91 / var(--width_base))));
            padding-bottom: calc(100vw * (calc(63 / var(--width_base))));
        }
    `;

    const sectionRightCss = css`
        width: 50%;
        shrink: 0;

        @media (max-width: 767px) {
            width: 100%;
        }
    `;

    const titleCss = css`
        font-size: calc(100vw * (calc(50 / var(--width_base))));
        margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
        max-width: calc(100vw * (calc(360 / var(--width_base))));
        font-family: "Poppins SemiBold", sans-serif;

        @media (max-width: 767px) {
            font-size: calc(100vw * (calc(30 / var(--width_base))));     
            margin-bottom: calc(100vw * (calc(20 / var(--width_base))));     
        }
    `;

    const textCss = css`
        font-family: "Poppins Light", sans-serif;
        font-size: calc(100vw * (calc(21 / var(--width_base))));
        line-height: calc(100vw * (calc(30 / var(--width_base))));
        max-width: calc(100vw * (calc(480 / var(--width_base))));

        @media (max-width: 767px) {
            font-size: calc(100vw * (calc(14 / var(--width_base))));    
            line-height: calc(100vw * (calc(22 / var(--width_base))));
        }
    `;

    return(
        <section>
            {/* {
                (data?.holaImagenPortada) &&
                <div class="only-desk">
                    <Image imagen={data?.holaImagenPortada} classEle="w-full"/>
                </div>
            } */}
            <div css={sectionContentCss}>
                <div css={sectionLeftCss}>
                    {
                        (data?.holaTitulo) &&
                        <Title styleCss={titleCss} text={data?.holaTitulo}/>
                    }
                    {
                        (data?.holaTexto) &&
                        <Paragraph styleCss={textCss} text={data?.holaTexto}/>
                    }
                </div>
                <div css={sectionRightCss}>
                    {
                        (data?.holaImagen) &&
                        <Image imagen={data?.holaImagen}/>
                    }
                </div>
            </div>
        </section>
    )
}