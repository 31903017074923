import React from 'react';
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import {css} from "@emotion/react";
import AnclasInicio from "./AnclasInicio";
import { Element } from 'react-scroll';


export default function BannerProyecto({imagenFondo,logo,posicionLogo}) {

  let alinearCss = {};

  switch (posicionLogo) {
    case "centro":
      alinearCss = css`
        object-position: center;
      `;
    break;
    default:
      alinearCss = css`
        object-position: left;
      `;
    break;
  }

  const imageFondoCss = css`
    //& img, &.gatsby-image-wrapper-constrained img{
    &{
      height: calc(100vw * (calc(1030 / var(--width_base))));
      object-position: bottom;
      width: 100%;
      object-fit: cover;
      @media (max-width: 767px){
        height: calc(100vw * (calc(279 / var(--width_base))));
      }
    }
  `;

  const logoCss = css`
    position: absolute !important;
    top: calc(100vw * (calc(60 / var(--width_base))));
    left: calc(100vw * (calc(300 / var(--width_base))));
    right: calc(100vw * (calc(300 / var(--width_base))));
    height: calc(100vw * (calc(145 / var(--width_base))));
    z-index: 1;
    max-width: calc(100vw * (calc(400 / var(--width_base))));
    object-fit: contain;

    @media (max-width: 1440px) and (min-width: 1025px) {
      left: calc(100vw * (calc(70 / var(--width_base))));
      right: calc(100vw * (calc(70 / var(--width_base))));
    }
    
    @media (max-width: 1024px) and (min-width: 768px) {
      left: calc(100vw * (calc(80 / var(--width_base))));
      right: calc(100vw * (calc(80 / var(--width_base))));
    }

    @media (max-width: 767px){
      top: calc(100vw * (calc(10 / var(--width_base))));
      left: calc(100vw * (calc(20 / var(--width_base))));
      right: calc(100vw * (calc(20 / var(--width_base))));
      height: calc(100vw * (calc(77 / var(--width_base))));
      max-width: calc(100vw * (calc(120 / var(--width_base))));
    }

    text-align: center;
    ${alinearCss};
    
    @media (max-width: 767px){
      object-position: left;  
    }
  `;

  const sectionCss = css`
    position: relative;
  `;


  return (
    <section css={sectionCss}>
      <Element name="portada"/>
      <AnclasInicio/>
      <Image classEle="w-full" styleCss={imageFondoCss} imagen={imagenFondo}/>
      <Image styleCss={logoCss} imagen={logo} objectFit="contain"/>
    </section>
  );

}

export const queryBannerProyecto = graphql`
  fragment bannerProyecto on Wordpress_Template_Proyecto_Proyecto{
    imagenFondoBanner {
      ...imagen
    }
    logoBanner {
      ...imagen
    }
    posicionLogoBanner
  }
`