import React from "react"
import { Link, navigate } from "gatsby"
import { scroller } from "react-scroll"

export default function MenuLink({
  children,
  to,
  activeClassName,
  partiallyActive,
  menuItem,
  ...other
}) {
  return (
    <>
      {to !== "" ? (
        <>
          {to === "#contacto-home" && (
            <Link
              className="menu__link"
              to={to}
              activeClassName="is-active"
              partiallyActive={partiallyActive}
              {...other}
              onClick={event => {
                event.preventDefault()
                navigate(`/${to}`)
                setTimeout(() => {
                  scroller.scrollTo("contacto", {
                    duration: 0,
                    delay: 0,
                    smooth: "easeInOutQuint",
                    offset:
                      typeof window !== `undefined` &&
                      window.innerWidth * (-120 / 1920),
                  })
                }, 7500)
              }}
            >
              {children}
            </Link>
          )}
          {to === "/armando/" && (
            <Link
              className="menu__link"
              to={to}
              activeClassName="is-active"
              partiallyActive={partiallyActive}
              {...other}
              onClick={event => {
                event.preventDefault()
                let eleQuienInicio = document.getElementById("armando-home")
                if (eleQuienInicio !== null) {
                  setTimeout(() => {
                    scroller.scrollTo("armando", {
                      duration: 500,
                      delay: 0,
                      smooth: "easeInOutQuint",
                      offset:
                        typeof window !== `undefined` &&
                        window.innerWidth * (-120 / 1920),
                    })
                  }, 100)
                } else {
                  navigate(to)
                }
              }}
            >
              {children}
            </Link>
          )}
          {to !== "#contacto-home" && to !== "/armando/" && (
            <Link
              className="menu__link"
              to={to}
              activeClassName="is-active"
              partiallyActive={partiallyActive}
              {...other}
            >
              {children}
            </Link>
          )}
        </>
      ) : (
        <a className="menu__link" href={to}>
          {children}
        </a>
      )}
    </>
  )
}
