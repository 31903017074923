import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { css } from "@emotion/react";
import BuscadorProyectos from '../../templates/Proyectos/BuscadorProyectos';

export default function FilterAccordion() {
    
    const accordinCss = css`
        margin-bottom: calc(100vw * (calc(62 / var(--width_base))));
    `;

    const accordionButtonCss = css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: calc(100vw * (calc(15 / var(--width_base))));
        padding-left: calc(100vw * (calc(15 / var(--width_base))));
        padding-top: calc(100vw * (calc(11 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(14 / var(--width_base))));
        border-top: calc(100vw * (calc(2 / var(--width_base)))) solid black;
        &:focus{
            outline: none;
        }
        
        &[aria-expanded="true"] .fa-plus, &[aria-expanded="false"] .fa-minus{
            display: none;
        }
        &[aria-expanded="false"]{
            border-bottom: calc(100vw * (calc(2 / var(--width_base)))) solid black;
        }
    `;

    const accordionPanelCss = css`
        padding: 0;
    `;

    return(
        <Accordion css={accordinCss}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton css={accordionButtonCss}>VER FILTROS <i className="fa fa-plus"/><i className="fa fa-minus"/></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel css={accordionPanelCss}>
                    <div>
                        <BuscadorProyectos />
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}