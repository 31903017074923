const getParametersUrl = async () => {
  const urlActual = window.location.href
  const url = new URL(urlActual)
  const parametros = new URLSearchParams(url.search)

  var utm_campaign = parametros.get("utm_campaign")
  var utm_content = parametros.get("utm_content")
  var utm_medium = parametros.get("utm_medium")
  var utm_source = parametros.get("utm_source")
  var utm_term = parametros.get("utm_term")
  var gclid = parametros.get("gclid")
  var fbc = parametros.get("fbc")
  var fbp = parametros.get("fbp")

  const dataJSON = sessionStorage.getItem("obj_data")
  const dataCaptationWaysJSON = sessionStorage.getItem("captation_ways")

  let obj_data = {}
  const default_value = "organic"
  let captation_ways = {}
  let source_id = parseInt(process.env.GATSBY_API_SOURCE_ID)
  let interest_type_id = parseInt(process.env.GATSBY_API_INTEREST_TYPE_ID)
  let input_channel_id = parseInt(process.env.GATSBY_API_INPUT_CHANNEL_ID)

  if (!dataCaptationWaysJSON) {
    const response = await fetch(
      `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/wp/v2/get/captation_ways`
    )
    const data = await response.json()
    captation_ways = JSON.parse(data)
    sessionStorage.setItem("captation_ways", data)
  } else {
    captation_ways = JSON.parse(dataCaptationWaysJSON)
  }

  if (dataJSON) {
    const data = JSON.parse(dataJSON)

    const send_utm_source = utm_source ?? data?.utm_source

    if (send_utm_source) {
      const objsource = captation_ways.data.find(
        item => item.attributes.name.toLowerCase() === send_utm_source
      )
      if (objsource) source_id = parseInt(objsource?.id)
    }

    obj_data = {
      utm_campaign: utm_campaign ?? data?.utm_campaign,
      utm_content: utm_content ?? data?.utm_content,
      utm_medium: utm_medium ?? data?.utm_medium,
      utm_source: utm_source ?? data?.utm_source,
      utm_term: utm_term ?? data?.utm_term,
      gclid: gclid ?? data?.gclid,
      source_id: source_id,
      interese_type_id: interest_type_id,
      input_channel_id: input_channel_id,
      ip_address: data?.ip_address,
      fbc: fbc ?? data?.fbc,
      fbp: fbp ?? data?.fbp,
    }
  } else {
    const send_utm_source = utm_source
    if (send_utm_source) {
      const objsource = captation_ways.data.find(
        item => item.attributes.name.toLowerCase() === send_utm_source
      )
      if (objsource) source_id = parseInt(objsource?.id)
    }

    const responseIpAddress = await fetch(
      `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/ipAddress/v1/ip`
    )
    const data = await responseIpAddress.json()
    const ipAddress = data?.ip_address

    obj_data = {
      utm_campaign: utm_campaign ?? default_value,
      utm_content: utm_content ?? default_value,
      utm_medium: utm_medium ?? default_value,
      utm_source: utm_source ?? default_value,
      utm_term: utm_term ?? default_value,
      gclid: gclid ?? "",
      source_id: source_id,
      interese_type_id: interest_type_id,
      input_channel_id: input_channel_id,
      ip_address: ipAddress,
      fbc: fbc ?? "",
      fbp: fbp ?? "",
    }
  }

  sessionStorage.setItem("obj_data", JSON.stringify(obj_data))
}

export default getParametersUrl
