import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import Image from "../../atoms/Image/Image";
import {css} from "@emotion/react";

export default function Logo() {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "896", idType: DATABASE_ID) {
          title
          template {
            ... on Wordpress_Template_Opciones {
              opciones {
                logoGeneral {
                  ...imagen
                }
              }
            }
          }
        }
      }
    }
  `)

  const logoCss = css`
    &.gatsby-image-wrapper-constrained img, &{
      height: calc(100vw * (calc(70 / var(--width_base))));
      object-fit: contain;
      @media (max-width: 767px){
        height: calc(100vw * (calc(53 / var(--width_base))));
        width: calc(100vw * (calc(199 / var(--width_base))));
      }
    }
  `;

  const imagen = data?.wordpress?.page?.template?.opciones?.logoGeneral

  return (
    <Link to="/">
      {
        imagen && <Image styleCss={logoCss} imagen={imagen} objectFit="contain"/>
      }
    </Link>
  );
}