import React from 'react';
import { graphql, Link } from "gatsby"
import {css} from "@emotion/react"
import Carousel from "../../organisms/Carousel/Carousel"
import Image from '../../atoms/Image/Image';
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function BannerInicio({slider}) {

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }

  const carousel = css`
    .slick-dots{
      left: calc(100vw * (calc(80 / var(--width_base))));
      bottom: calc(100vw * (calc(60 / var(--width_base))));
      text-align: left;
      
      & li{
        width: calc(100vw * (calc(29 / var(--width_base))));
        height: calc(100vw * (calc(29 / var(--width_base))));
        border-radius: 50%;
      }
      
      & li:not(.rounded){
        border: calc(100vw * (calc(3 / var(--width_base)))) solid;
      }

      & li.slick-active{
        background-color: black;
      }
      & button::before{
        display: none;
      }
    }
  `;

  const columnLeft = css`
    //height: calc(100vw * (calc(952 / var(--width_base))));
    height: calc(100vh - calc(100vw * (calc(70 / var(--width_base)))));
    min-height: calc(100vw * (calc(740 / var(--width_base))));
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

  const imagenCss = css`
    //height: calc(100vw * (calc(952 / var(--width_base))));
    height: calc(100vh - calc(100vw * (calc(70 / var(--width_base)))));
    min-height: calc(100vw * (calc(740 / var(--width_base))));
    width: 100%;
    object-fit: cover;
  `;

  const distritoCss = css`
    position: absolute;
    top: 10%;
    left: 0;
    padding: calc(100vw * (calc(10 / var(--width_base)))) calc(100vw * (calc(80 / var(--width_base))));
    z-index: 1;
    border-top: calc(100vw * (calc(3 / var(--width_base)))) solid;
    border-right: calc(100vw * (calc(3 / var(--width_base)))) solid;
    border-bottom: calc(100vw * (calc(3 / var(--width_base)))) solid;
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    font-weight: 500;
  `;

  const logoCss = css`
    //margin-top: calc(100vw * (calc(240 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    height: calc(100vw * (calc(250 / var(--width_base))));
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(37 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(70 / var(--width_base))));
  `;

  const botonCss = css`
    padding: calc(100vw * (calc(13 / var(--width_base)))) calc(100vw * (calc(20 / var(--width_base))));
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    font-weight: 600;
    //font-family: "";
  `;

  return (
    <section className="only-desk o-hidden">
      <Carousel styleCss={carousel} settings={settings}>
        {
        slider && slider.map((dato, i) => (
          <div key={i}>
            <Link to={dato?.link ? dato?.link : "/"} className="grid-100 grid-x-0">
              {
                (dato?.tipoBanner === "banner-barra-lateral") ?
                <>
                  <div
                    className="grid-30 grid-x-0 text-center"
                    css={css`
                    ${columnLeft};
                    background-color: ${dato.colorFondo}; 
                  `}>
                    {
                      dato.textoDistrito &&
                      <div
                        css={css`
                        ${distritoCss};
                        color: ${dato.colorTextoDistrito};
                        border-color: ${dato.colorBordeDistrito};
                      `}>
                        {dato.textoDistrito}
                      </div>
                    }
                    {
                      dato.logo &&
                      <Image imagen={dato.logo} styleCss={logoCss} objectFit="contain"/>
                    }
                    {
                      dato.texto &&
                      <Paragraph
                        text={dato.texto}
                        styleCss={css`
                        ${textCss};
                        color: ${dato.colorTexto};
                     `}/>
                    }
                    {
                      dato.textoBoton &&
                      <button
                        css={css`
                      ${botonCss};
                      color: ${dato.colorTextoBoton};
                      background-color: ${dato.colorFondoBoton};
                    `}>
                        {dato.textoBoton}
                      </button>
                    }

                  </div>
                  <div className="grid-70 grid-x-0">
                    {
                      dato.imagenFondo &&
                      <Image classEle="w-full" imagen={dato?.imagenFondo} styleCss={imagenCss}/>
                    }
                  </div>
                </>
                  :
                <Image classEle="w-full" imagen={dato?.imagenFondo} objectFit="cover" styleCss={imagenCss}/>
              }

            </Link>
          </div>
        ))
        }
      </Carousel>
    </section>
  );



}

export const queryBannerInicio = graphql`
  fragment bannerInicio on Wordpress_Template_Inicio_Inicio{
    sliderBanner {
      link
      colorBordeDistrito
      colorFondo
      colorFondoBoton
      colorTexto
      colorTextoBoton
      colorTextoDistrito
      tipoBanner
      imagenFondo {
        ...imagen
      }
      imagenFondoMovil {
        ...imagen
      }
      logo {
        ...imagen
      }
      fecha
      texto
      textoBoton
      textoDistrito
    }
  }
`
