import React from "react";
import {css} from "@emotion/react";
import AccordionProyectoItem from "./AccordionProyectoItem";

export default function AccordionProyectos({data}) {

    const sanIsidro = data && data.filter((item) => item?.distrito === "san-isidro" );
    const miraflores = data && data.filter((item) => item?.distrito === "miraflores" );
    const barranco = data && data.filter((item) => item?.distrito === "barranco" );

    const accordionCss = css`
      border-top : 1px solid black;
    `;

    return(
        <div css={accordionCss}>
            <AccordionProyectoItem data={{items: sanIsidro, title: "San Isidro"}} />
            <AccordionProyectoItem data={{items: miraflores, title: "Miraflores"}} />
            <AccordionProyectoItem data={{items: barranco, title: "Barranco"}} />
        </div>
    )
}