import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { graphql } from "gatsby"
import {css} from "@emotion/react";

export default function BannerPrograma({imagen, texto}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: calc(100vw * (calc(26 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(26 / var(--width_base))));
    }
  `;

  const textCss = css`
    color: white;
    font-size: calc(100vw * (calc(82 / var(--width_base))));
    line-height: calc(100vw * (calc(85 / var(--width_base))));
    & *:not(strong){
      font-family: "Museo Slab", serif;
    }
    & strong{
      font-family: "Museo 700", serif;
    }
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(29 / var(--width_base))));
      line-height: calc(100vw * (calc(29 / var(--width_base))));
    }
  `;

  return (
    <SectionBackground imagen={imagen} styleCss={sectionCss} bgType="fixed">
      <Paragraph text={texto} classEle="text-center" styleCss={textCss}/>
    </SectionBackground>
  );

}

export const queryBannerPrograma = graphql`
  fragment bannerPrograma on Wordpress_Template_ProgramaDeReferidos_Programadereferidos{
    textoBanner
    colorTextoBanner
    imagenFondoBanner {
      ...imagen
    }
  }
`