import React from 'react';
import BuscadorProyectos from "../Proyectos/BuscadorProyectos";
import Title from "../../atoms/Title/Title";
import { graphql, navigate } from "gatsby";
import {css} from "@emotion/react";
import MostrarProyectos from "../Proyectos/MostrarProyectos"
import TitleProyecto from "../../molecules/Title/TitleProyecto"

export default function ConoceInicio({titulo,proyectos, id, isProyecto}) {


  if(id){
    let proyectoActual = proyectos.filter(proyecto => proyecto.databaseId === id);
    let proyectosNuevos = proyectos.filter(proyecto => proyecto.databaseId !== id);
    proyectos = [...proyectosNuevos, proyectoActual[0]];
  }

  const evento = () => {
    navigate('/proyectos')
  }

  const sectionCss = css`
    padding-top: calc(100vw * (calc(61 / var(--width_base))));
    padding-left: calc(100vw * (calc(80 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(129 / var(--width_base))));
    overflow: hidden;
    @media (max-width: 767px){
      padding-top: calc(100vw * (calc(31 / var(--width_base))));
      padding-left: 0;
      padding-right: 0;
      padding-bottom: calc(100vw * (calc(100 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    line-height: calc(100vw * (calc(60 / var(--width_base))));
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(25 / var(--width_base))));
      line-height: calc(100vw * (calc(38 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      max-width: calc(100vw * (calc(250 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(37 / var(--width_base))));
    }
  `;

  return (
    <section className="grid-100" css={sectionCss}>
      {
        isProyecto ?
          <TitleProyecto styleCss={titleCss}/>
        :
        <Title typeEle="h2" text={titulo} styleCss={titleCss}/>
      }

      <BuscadorProyectos event={evento}/>
      <MostrarProyectos proyectos={proyectos}/>
    </section>

  );

}

export const queryConoceInicio = graphql`
  fragment conoceInicio on Wordpress_Template_Inicio_Inicio{
    tituloConoce
    proyectosConoce {
      ... on Wordpress_Proyecto {
        databaseId
        title
        uri
        template {
          ... on Wordpress_Template_Proyecto {
            proyecto {
              textoParaHomeDormitoriosGeneral
            }
          }
        }
        featuredImage {
          node {...imagen}
        }
        distritos {
          nodes {
            name
          }
        }
      }
    }
  }
`