import React from 'react';
import Paragraph from "../../atoms/Paragraph/Paragraph"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { graphql } from "gatsby"
import { css } from "@emotion/react"

export default function RecorridoPiloto({texto, ruta, textoAdicional, colorFondo}) {

  const sectionCss = css`
    padding-bottom: calc(100vw * (calc(100 / var(--width_base))));
    @media (max-width: 1024px) and (min-width: 768px) {
      padding-left: calc(100vw * (calc(80 / var(--width_base))));
      padding-right: calc(100vw * (calc(80 / var(--width_base))));
    }
  `;

  const iframeCss = css`
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw * (calc(853 / var(--width_base))));
    display: block;
    margin-bottom: calc(100vw * (calc(55 / var(--width_base))));
  `;

  const textCss = css`
    line-height: calc(100vw * (calc(55 / var(--width_base))));
    color: #3f3d1e;
    font-family: "CircularAirLight", sans-serif;
    font-size: calc(100vw * (calc(34 / var(--width_base))));
    font-weight: lighter;
    @media (max-width: 767px) {
        font-size: calc(100vw * (calc(16 / var(--width_base))));
        line-height: calc(100vw * (calc(20 / var(--width_base))));
        padding-right: calc(100vw * (calc(20 / var(--width_base))));
        padding-left: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const descriptionCss = css`
    padding-top: calc(100vw * (calc(60 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(60 / var(--width_base))));
    border-top: calc(100vw * (calc(1 / var(--width_base)))) solid black;
    border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid black;
    ${textCss}
  `;

  const textMainCss = css`
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    ${textCss}
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={1653} styleCss={sectionCss}>
      <Paragraph text={texto} classEle="text-center" styleCss={textMainCss}/>
      {
        ruta && <iframe title="Iframe Recorrido" src={ruta} width="100%" height="500px" css={iframeCss}/>
      }
      <Paragraph text={textoAdicional} classEle="text-center" styleCss={descriptionCss}/>
    </SectionBackground>
  );

}

export const queryRecorridoPiloto = graphql`
  fragment recorridoPiloto on Wordpress_Template_PilotoLosNogales_Pilotolosnogales{
    textoRecorrido
    recorridoUrlRecorrido
    textoAdicionalRecorrido
  }
`