import { css } from '@emotion/react';
import { Link } from 'gatsby';
import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import Title from '../../atoms/Title/Title';

export default function VerMas({styleEle}) {

    const iconCss = css`
        width: calc(100vw * (calc(73 / var(--width_base))));
        height: calc(100vw * (calc(73 / var(--width_base))));
        ${styleEle};
    `;

    const divCss = css`
        text-align: center;
        padding-top: calc(100vw * (calc(32 / var(--width_base))));
    `;

    const textCss = css`
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        line-height: calc(100vw * (calc(24 / var(--width_base))));
        font-weight: 400;
        margin-top: calc(100vw * (calc(12 / var(--width_base))));
    `;

    return(
        <div css={divCss}>
            <Link to="/proyectos/">
                <Icon type="ver-mas" styleEle={iconCss}/>
                <Title text="Ver más" styleCss={textCss}/>
            </Link>
        </div>
    );
}