import React from 'react';
import {css} from "@emotion/react";

export default function Paragraph({text, styleCss, classEle, type, brMov}) {

  if(type === "post-entry"){
    text = text.replace("Leer más","");
  }

  let marginParagreaphCss = {};

  if(type === "content"){
    marginParagreaphCss = css`
      & p{
        margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
      }
    `;
  }

  const brMovCss = css`
    & br{
      @media (max-width: 767px){
        display: none;
      }
    }
  `;

  const paragraphCss = css`
    ${styleCss};
    ${marginParagreaphCss};
    ${brMov && brMovCss}
  `;

  return (
    <div className={classEle} css={paragraphCss} dangerouslySetInnerHTML={{__html: text}}/>
  );
}

Paragraph.defaultProps = {
  styleCss: css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
  `,
  text: '',
  classEle: '',
  brMov: false
}