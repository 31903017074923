import React from "react"
import MenuItem from "../MenuItem/MenuItem";
import AnimateHeight from 'react-animate-height';

export default function HijosMenuItem ({menuItems, wordPressUrl, heightListSubMenu}){

    return (
       <AnimateHeight
         id='list-submenu'
         duration={300}
         height={heightListSubMenu}>
         <ul className="sub-menu">
            {
                menuItems.edges && menuItems.edges.map((menuItem,i)=>(
                    <MenuItem
                        key={i}
                        menuItem={menuItem.node}
                        wordPressUrl={wordPressUrl}
                        classEle="sub-menu__item"
                    />
                ))
            }
         </ul>
       </AnimateHeight>
    )
};