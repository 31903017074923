import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import TextArmando from "../../organisms/GroupText/TextArmando"

export default function EstamosArmando({colorFondo, titulo, texto, imagen1, imagen2}) {

  const contentCss = css`
    background-color:  ${colorFondo};
  `
  const imagenCss = css`
    & img{
      width: 100%;
      
    }
    
  `

  return (
    <SectionBackground widthContent={1200} styleCssContent={contentCss}>
      <div className="grid-40 grid-x-0 line-height-0">
        <Image imagen={imagen1} classEle="w-full"/>
      </div>
      <div className="grid-60 grid-x-0 line-height-0">
        <Image imagen={imagen2} classEle="w-full" styleCss={imagenCss}/>
        <TextArmando texto={texto} titulo={titulo} color_titulo="#343434"/>
      </div>
    </SectionBackground>
  );
}

export const queryEstamosArmando = graphql`
  fragment estamosArmando on Wordpress_Template_Armando_Armando{
    colorFondoEstamos
    textoEstamos
    tituloEstamos
    imagen2Estamos {
      ...imagen
    }
    imagen1Estamos {
      ...imagen
    }
  }
`
