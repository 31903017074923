import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
import Title from "../../atoms/Title/Title"

export default function ObrasInicio({imagenBacground,imagenTitle, linkButton,text, textButton, idEle}) {
  const sectionCss = css`
    height: 100vh;
    display: flex;
    align-items: center;
    min-height: calc(100vw * (calc(720 / var(--width_base))));
    
  `;

  const imagenFondoCss = css`
    max-width: calc(100vw * (calc(900 / var(--width_base))));
    @media (max-width: 767px) {
      max-width: calc(100vw * (calc(335 / var(--width_base))));
    }
  `;

  const textCss = css`
    color: white;
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    font-style: italic;
    margin-top: calc(100vw * (calc(60 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(22 / var(--width_base))));
      padding-left: calc(100vw * (calc(45 / var(--width_base))));
      padding-right: calc(100vw * (calc(45 / var(--width_base))));
    }
  `

  return (
    <SectionBackground imagen={imagenBacground} idEle={idEle} align="center" styleCss={sectionCss} bgType='fixed'>
      <Title classEle="d-none" text="Obras entregadas"/>
      <Image imagen={imagenTitle} styleCss={imagenFondoCss}/>
      <Paragraph text={text} styleCss={textCss}/>
      <Button link={linkButton} type="link" color="white" styleType="outline">{textButton}</Button>
    </SectionBackground>
  );

}

export const queryObrasInicio = graphql`
  fragment obrasInicio on Wordpress_Template_Inicio_Inicio{
    botonLinkObrasEntregadas {
      ... on Wordpress_Page {
        uri
      }
    }
    botonTextoObrasEntregadas
    textoObrasEntregadas
    tituloImagenObrasEntregadas {
      ...imagen
    }
    imagenFondoObrasEntregadas{
      ...imagen
    }
  }
`