import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import Image from "../../atoms/Image/Image";
import Title from "../../atoms/Title/Title";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import { graphql } from "gatsby";
import {css} from '@emotion/react';
import Button from "../../atoms/Button/Button"
import { Element } from "react-scroll"
import Icon from "../../atoms/Icon/Icon"

export default function FichaProyecto({titulo, colorFondo, tituloProyecto, descripcion, botones, colorPrimario, colorFondoBotones, colorIconoBotones, colorTitulo }) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(120 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: calc(100vw * (calc(50 / var(--width_base))));
    }
  `;

  const titleCss = css`
    margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
  `;

  const iconoCss = css`
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    & img, &.gatsby-image-wrapper-constrained img{
      height: calc(100vw * (calc(56 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(17 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
    }
  `;


  const descriptionTitleCss = css`
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    ${textCss}
  `;

  const descriptionTextCss = css`
    font-style: italic;
    margin-bottom: calc(100vw * (calc(70 / var(--width_base))));
    ${textCss}
  `;

  const buttonCss = css`
    
    @media (max-width: 767px){
      min-width: calc(100vw * (calc( 270/ var(--width_base))));
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={987} styleCss={sectionCss}>
      <Element name="ficha"/>
      <div className="grid-100 text-center">
        {titulo && <Title typeEle="h2" color={colorTitulo} text={titulo} type="draft" colorBorde={colorPrimario} styleCss={titleCss}/>}
      </div>
      {
        descripcion && descripcion.map((item, i)=>(
          <div className={`${descripcion.length > 3 ? "grid-50" : "grid-33"}  text-center`} key={i}>
            <Image imagen={item.icono} styleCss={iconoCss} objectFit="contain"/>
            <Title text={item.titulo} styleCss={descriptionTitleCss}/>
            <Paragraph text={item.texto} styleCss={descriptionTextCss}/>
          </div>
        ))
      }
      <div className="grid-100 text-center">
        {
          botones && botones.map((boton)=>(
            <Button link={boton?.pdf?.mediaItemUrl} styleType="normal" type="pdf" nombre={`${boton?.icono.toUpperCase()} ${tituloProyecto.toUpperCase()}`} color={colorFondoBotones} styleEle={buttonCss} typePdf={boton?.icono}>
              <Icon type={(boton?.icono === "brochure" ? "brochure":"planos")} color={colorIconoBotones}/>
              {boton?.texto}
            </Button>
          ))
        }


        {/*{*/}
        {/*  pdfBrochure &&*/}
        {/*    <Button link={pdfBrochure} styleType="normal" type="pdf" nombre={`Brochure ${tituloProyecto}`} color={colorFondoBotones} styleEle={buttonCss}>*/}
        {/*      <Icon type="brochure" color={colorIconoBotones}/>*/}
        {/*      Descargar Brochure*/}
        {/*    </Button>*/}
        {/*}*/}
        {/*{*/}
        {/*  pdfPlanos &&*/}
        {/*    <Button link={pdfPlanos} styleType="normal" type="pdf" nombre={`Planos ${tituloProyecto}`} color={colorFondoBotones} styleEle={buttonCss}>*/}
        {/*      <Icon type="planos" color={colorIconoBotones}/>*/}
        {/*      Descargar Planos*/}
        {/*    </Button>*/}
        {/*}*/}
      </div>
    </SectionBackground>
  );

}

export const queryFichaProyecto = graphql`
  fragment fichaProyecto on Wordpress_Template_Proyecto_Proyecto{
    tituloFicha
    colorFondoFicha
    colorIconoBotonesFicha
    colorFondoBotonesFicha
    descripcionFicha {
      texto
      titulo
      icono {
        ...imagen
      }
    }
#    pdfBrochureFicha {
#      mediaItemUrl
#    }
#    pdfPlanosFicha {
#      mediaItemUrl
#    }
    botonesFicha {
        icono
        pdf {
            mediaItemUrl
        }
        texto
    }
      
    colorPrimarioGeneral
  }
`
