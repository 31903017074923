import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby"

export default function Image({imagen, classEle, styleCss, objectFit}) {

  return (
    <>
      {
        imagen &&
          <>
            {
              (imagen?.mimeType === 'image/gif') ?
                // <img css={styleCss} src={imagen?.sourceUrlSharp?.publicURL} alt={`${imagen?.alt ? imagen?.alt : imagen?.title}`}/>
                <img css={styleCss} src={imagen?.sourceUrl} alt={`${imagen?.alt ? imagen?.alt : imagen?.title}`}/>
              :
                // <img css={styleCss}  className={` ${classEle}`} src={imagen?.sourceUrl} alt={`${imagen?.alt ? imagen?.alt : imagen?.title}`}/>
              <GatsbyImage
                objectFit={objectFit}
                css={styleCss}
                className={` ${classEle}`}
                image={imagen?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
                alt={`${imagen.alt ? imagen.alt : imagen.title}`}/>
            }
          </>
      }
    </>
  );

}

// export const query_imagen = graphql`
//   fragment imagen on Wordpress_MediaItem{
//     altText
//     title
//     mimeType
//     srcSet
//     sourceUrl
//   }
// `

export const query_imagen = graphql`
    fragment imagen on Wordpress_MediaItem{
        altText
        title
        mimeType
        srcSet
        sourceUrl
        sourceUrlSharp {
            childImageSharp {
                gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                    quality: 100
                )
            }
            publicURL
        }
    }
`

Image.defaultProps = {
  imagen: null,
  classEle: '',
  styleCss:{}
}
