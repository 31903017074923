import React from "react";
import {css} from "@emotion/react";
import Title from "../../atoms/Title/Title";
import MuchoSliderProyectos from "./MuchoSliderProyectos";
import AccordionProyectos from "../../organisms/Accordion/AccordionProjectos";

export default function MuchoDonde({data}) {

    const sectionContentCss = css`
        padding: 
            calc(100vw * (calc(132 / var(--width_base)))) 
            0
            calc(100vw * (calc(132 / var(--width_base)))) 
            calc(100vw * (calc(245 / var(--width_base))))
        ;
        @media (max-width: 767px) {
            padding: 
                calc(100vw * (calc(62 / var(--width_base)))) 
                calc(100vw * (calc(26 / var(--width_base)))) 
                calc(100vw * (calc(62 / var(--width_base)))) 
                calc(100vw * (calc(26 / var(--width_base)))) 
            ;    
        }
    `;

    const titleCss = css`
        font-size: calc(100vw * (calc(50 / var(--width_base))));
        max-width: calc(100vw * (calc(500 / var(--width_base))));
        font-family: "Poppins Regular", sans-serif;
        margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
        padding-left: calc(100vw * (calc(13 / var(--width_base))));

        @media (max-width: 767px) {
            font-size: calc(100vw * (calc(33 / var(--width_base))));
            max-width: calc(100vw * (calc(260 / var(--width_base))));
            margin-bottom: calc(100vw * (calc(47 / var(--width_base))));
        }
    `;

    const linkCss = css`
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        font-family: "Poppins Light", sans-serif;
        display: inline-block;
        margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
        text-decoration: underline;
        padding-left: calc(100vw * (calc(13 / var(--width_base))));
    `;

    return(
        <section>
            <div css={sectionContentCss}>
                {
                    (data?.dondeTitulo) &&
                    <Title styleCss={titleCss} text={data?.dondeTitulo}/>
                }
                {
                    (data?.dondeTextoBoton) &&
                    <div className="only-desk">
                        <a css={linkCss} href={data?.dondeUrlBoton}>{data?.dondeTextoBoton}</a>
                    </div>
                }

                {
                    data?.dondeProyectos &&
                    <>
                        <div className="only-desk">
                            <MuchoSliderProyectos data={data?.dondeProyectos}/>
                        </div>
                        <div className="only-mov">
                            <AccordionProyectos data={data?.dondeProyectos}/>    
                        </div>
                    </> 
                }
            </div>
        </section>
    )

}