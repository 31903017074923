import React from "react"
import { useEffect, useState } from "react"
import ReactModal from "react-modal"
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.76)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "none",
    zIndex: "999",
  },
  content: {
    inset: "initial",
    position: "relative",
    margin: "auto",
    border: "initial",
    backgroundColor: "transparent",
    overflow: "initial",
    padding: "0",
  },
}

export default function Modal({ active, image, url }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    ReactModal.setAppElement("body")
    const activeModal = sessionStorage.getItem("desactiveModalHome")
    if (!activeModal) {
      sessionStorage.setItem("desactiveModalHome", true)
      setTimeout(() => {
        setIsModalOpen(true)
      }, 7000)
    }
  }, [isModalOpen])

  const handleModalClose = e => {
    setIsModalOpen(false)
  }

  const imageCss = css`
    height: calc(100vw * (calc(600 / var(--width_base))));
    width: calc(100vw * (calc(600 / var(--width_base))));

    @media (max-width: 767px) {
      width: calc(100vw * (calc(300 / var(--width_base))));
      height: calc(100vw * (calc(300 / var(--width_base))));
    }
  `

  const btnCss = css`
    position: absolute;
    top: calc(100vw * (calc(30 / var(--width_base))));
    right: calc(100vw * (calc(30 / var(--width_base))));
    z-index: 1;
    display: inline-flex;

    @media (max-width: 767px) {
      top: calc(100vw * (calc(10 / var(--width_base))));
      right: calc(100vw * (calc(10 / var(--width_base))));
    }

    & svg {
      width: calc(100vw * (calc(33 / var(--width_base))));
      height: calc(100vw * (calc(33 / var(--width_base))));

      @media (max-width: 767px) {
        width: calc(100vw * (calc(23 / var(--width_base))));
        height: calc(100vw * (calc(23 / var(--width_base))));
      }
    }
  `

  return (
    <>
      {active && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={handleModalClose}
          contentLabel="Modal"
          style={customStyles}
        >
          <button onClick={handleModalClose} css={btnCss}>
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42 42L1 1"
                stroke="white"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M42 0.999999L1 42"
                stroke="white"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </button>
          <a href={url} target="_blank">
            <Image imagen={image} styleCss={imageCss} />
          </a>
        </ReactModal>
      )}
    </>
  )
}
