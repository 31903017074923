import React from 'react';
import {css} from '@emotion/react';
import { Link as LinkScroll } from 'react-scroll';

export default function AnclasInicio({styleCss}) {
  const anclasCss = css`
    position: fixed;
    right: 0;
    top: calc(100vw * (calc(70 / var(--width_base))));
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;

    &.only-desk{
      @media (max-width: 767px){
        display: none;
      }
    }
    & .anclas__link{
      color: #DEDEDE;
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      font-style: italic;
      padding:
              calc(100vw * (calc(8 / var(--width_base))))
              calc(100vw * (calc(35 / var(--width_base))));
      background-color: black;
      position: relative;
    }
    
    & .anclas__item:not(:last-of-type) .anclas__link{
      &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: calc(100vw * (calc(1 / var(--width_base)))) solid #DEDEDE;
        height: calc(100vw * (calc(15 / var(--width_base))));
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    ${styleCss}
  `;
  return (
    <ul css={anclasCss} className="only-desk">
      <li className="anclas__item"><LinkScroll className="anclas__link" to="portada" spy={true} smooth={true} duration={500} offset={(typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)}>Contacto</LinkScroll></li>
      <li className="anclas__item"><LinkScroll className="anclas__link" to="contenido" spy={true} smooth={true} duration={500} offset={(typeof window !== `undefined`) && window.innerWidth * (-140 / 1920)}>El proyecto</LinkScroll></li>
      <li className="anclas__item"><LinkScroll className="anclas__link" to="ficha" spy={true} smooth={true} duration={500} offset={(typeof window !== `undefined`) && window.innerWidth * (-140 / 1920)}>Ficha técnica</LinkScroll></li>
      <li className="anclas__item"><LinkScroll className="anclas__link" to="mapa" spy={true} smooth={true} duration={500} offset={(typeof window !== `undefined`) && window.innerWidth * (-70 / 1920)}>Ubicación</LinkScroll></li>
    </ul>
  );

}