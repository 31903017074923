import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import ProgramaForm from "../../organisms/Form/ProgramaForm"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
// import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function FormPrograma({colorFondoBoton,colorFondoInput,colorFondo,titulo}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(35 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    max-width: calc(100vw * (calc(1200 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    @media (max-width: 767px){
      margin-left: calc(100vw * (calc(20 / var(--width_base))));
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
      padding: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  // const titleCss = css`
  //   font-size: calc(100vw * (calc(17 / var(--width_base))));
  //   margin-bottom: calc(100vw * (calc(29 / var(--width_base))));
  //   color: ${colorFondoInput};
  // `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={551} styleCss={sectionCss}>
      {/* <Paragraph text={titulo} styleCss={titleCss}/> */}
      <ProgramaForm bgColorInput={colorFondoInput} bgColorSubmit={colorFondoBoton} colorFondoInput={colorFondoInput}/>
    </SectionBackground>
  );

}

export const queryFormPrograma = graphql`
  fragment formPrograma on Wordpress_Template_ProgramaDeReferidos_Programadereferidos{
    colorFondoBotonEnviarFormulario
    colorFondoCampoFormulario
    colorFondoFormulario
    tituloFormulario
  }
`