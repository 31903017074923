import React from "react"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Image from "../../atoms/Image/Image";
import { graphql } from "gatsby"
import { css, keyframes } from "@emotion/react"
import { Link as LinkScroll} from 'react-scroll';
import Title from "../../atoms/Title/Title"

export default function BannerProyectos({colorFondo, titulo, texto, imagen, tipoBanner, imagenFondoDesktop, imagenFondoMovil}) {

  const aniOpacity = keyframes`
    to {
      opacity: 1;
    }
  `

  const titleCss = css`
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: calc(100vw * (calc(88 / var(--width_base))));
    line-height: calc(100vw * (calc(105 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(150 / var(--width_base))));
    
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(45 / var(--width_base))));
      line-height: calc(100vw * (calc(54 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(37 / var(--width_base))));
    }
  `;


  const textCss = css`
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    //font-size: calc(100vw * (calc(40 / var(--width_base))));
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    font-family: "Lato", sans-serif;
    font-weight: bold;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(24 / var(--width_base))));
      max-width: calc(100vw * (calc(150 / var(--width_base))));
    }
  `;

  const sectionCss = css`
    opacity: 0;
    padding-left: calc(100vw * (calc(80 / var(--width_base))));
    padding-right: calc(100vw * (calc(80 / var(--width_base))));
    height: calc(100vh - calc(100vw * (calc(70 / var(--width_base)))));
    min-height: calc(100vw * (calc(600 / var(--width_base))));
    //animation: ${aniOpacity} .5s .4s ease-in forwards;
    
    &.is-visible{
      opacity: 1;
    }
    
    @media (max-width: 767px){
      height: initial;
      min-height: initial;
      padding-top: calc(100vw * (calc(76 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(97 / var(--width_base))));
    }
  `;

  const contentCss = css`
    @media (min-width: 768px){
      display: flex;
      align-items: center;
      height: 100%;
    }
  `;


  const imageCss = css`
    margin-top: calc(100vw * (calc(132 / var(--width_base))));
    & img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(492 / var(--width_base)))) !important;
      height: calc(100vw * (calc(372 / var(--width_base)))) !important;
      
      @media (max-width: 767px){
        width: calc(100vw * (calc(178 / var(--width_base)))) !important;
        height: calc(100vw * (calc(135 / var(--width_base)))) !important;
      }
    }
    @media (max-width: 767px){
      margin-top: calc(100vw * (calc(4 / var(--width_base))));
      margin-left: auto;
      max-width: calc(100vw * (calc(178 / var(--width_base))));
      width: calc(100vw * (calc(178 / var(--width_base)))) !important;
      display: block !important;
    }
  `;

  const linkCss = css`
    position: absolute;
    bottom: calc(100vw * (calc(60 / var(--width_base))));
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    
    &, & .icon-svg{
      width: calc(100vw * (calc(54 / var(--width_base))));
      height: calc(100vw * (calc(27 / var(--width_base))));
    }
    @media (max-width: 767px){
      bottom: calc(100vw * (calc(-77 / var(--width_base))));
    }
  `;

const link2Css = css`
  position: absolute;
  bottom: calc(100vw * (calc(60 / var(--width_base))));
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &, & .icon-svg{
    width: calc(100vw * (calc(54 / var(--width_base))));
    height: calc(100vw * (calc(27 / var(--width_base))));
  }
  @media (max-width: 767px){
    bottom: calc(100vw * (calc(20 / var(--width_base))));
  }
`;


const image2Css = css`
  & img, 
  &.gatsby-image-wrapper-constrained{
    width: 100%;
  }

  &.gatsby-image-wrapper-constrained.only-desk{
    @media (max-width: 767px){
      display: none !important;
    }
  }
  &.gatsby-image-wrapper-constrained.only-mov{
    @media (min-width: 768px){
      display: none !important;
    }
  }
`;

  return (
    <>
      {
        ( tipoBanner === "imagen-fondo") ?
          <div className="relative">
            <Image imagen={imagenFondoDesktop} styleCss={image2Css} classEle="only-desk"/>
            <Image imagen={imagenFondoMovil} styleCss={image2Css} classEle="only-mov"/>
            <div css={link2Css}>
              <LinkScroll to="proyectos" spy={true} smooth={true} duration={500} offset={ (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)}>
                <svg role="img" aria-labelledby="icono flecha hacia abajo" className="icon-svg" width="60" height="33" viewBox="0 0 60 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 3L30 30L57 3" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </LinkScroll>
            </div>
          </div>
          :
          <SectionBackground bgColor={colorFondo} styleCss={sectionCss} styleCssContent={contentCss} idEle="banner-proyecto" classEle="is-visible">
          <Title classEle="d-none" typeEle="h2" text={titulo}/>
          <div className="grid-70">
            <Paragraph text={titulo} styleCss={titleCss} brMov={true}/>
            <Paragraph text={texto} styleCss={textCss}/>
          </div>
          <div className="grid-30">
            <Image imagen={imagen} styleCss={imageCss}/>
          </div>
          <div css={linkCss}>
            <LinkScroll to="proyectos" spy={true} smooth={true} duration={500} offset={ (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)}>
              <svg role="img" aria-labelledby="icono flecha hacia abajo" className="icon-svg" width="60" height="33" viewBox="0 0 60 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 3L30 30L57 3" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </LinkScroll>
            {/*<LinkScroll to="proyectos" id="linkProyectos" spy={true} smooth={true} duration={0} offset={ (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)} />*/}
          </div>
        </SectionBackground>
      }
    </>    
  );

}

export const queryBannerProyectos = graphql`
  fragment bannerProyectos on Wordpress_Template_Proyectos_Proyectos{
    colorFondoBanner
    textoBanner
    tituloBanner
    imagenBanner {
      ...imagen
    }
    tipoBanner
    imagenFondoDesktop {
      ...imagen
    }
    imagenFondoMovil {
      ...imagen
    }
  }
`