import React, { useCallback, useRef, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { css } from "@emotion/react"
// import getUrlParameter from "../../atoms/Utm/Utm"
import { navigate } from "gatsby"

export default function BuenaSenalForm({ bgColorInput, bgColorSubmit }) {
  const [envio, setEnvio] = useState("")
  const formikRef = useRef(null)
  const [paginaGracias, setPaginaGracias] = useState("")
  const [valoresTipoProyecto, setValoresTipoProyecto] = useState("")

  const formCss = css`
    & .form-group {
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-error {
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-control {
      background-color: #f1efe5;
      padding: calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))));
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ccc;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border-radius: calc(100vw * (calc(10 / var(--width_base))));
    }
  `

  const submitCss = css`
    background-color: #c7c7c7;
    padding: calc(100vw * (calc(16 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))))
      calc(100vw * (calc(8 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    line-height: calc(100vw * (calc(12 / var(--width_base))));
    width: 100%;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    display: inline-block;
    margin-top: calc(100vw * (calc(5 / var(--width_base))));
    @media (max-width: 767px) {
      padding: calc(100vw * (calc(16 / var(--width_base))))
        calc(100vw * (calc(40 / var(--width_base))))
        calc(100vw * (calc(16 / var(--width_base))))
        calc(100vw * (calc(40 / var(--width_base))));
    }
  `

  const formAceptCss = css`
    color: white;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(3 / var(--width_base))));
    display: inline-block;
    font-family: "Gotham Thim", sans-serif;
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }

    & input {
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const apellidosCss = css`
    padding-left: calc(100vw * (calc(8 / var(--width_base))));
    padding-right: 0;
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(0 / var(--width_base))));
    }
  `

  const linkCss = css`
    color: white;
  `

  const spaceFormCss = css`
    height: calc(100vw * (calc(5 / var(--width_base))));
  `

  const onChangeDistrito = useCallback(event => {
    const valorSelect = event?.target?.value
    let arrTipos = []
    if (valorSelect) {
      switch (valorSelect) {
        case "Miraflores":
          arrTipos = [{ id: 24, name: "Oficinas Libertad" }]
          setValoresTipoProyecto(arrTipos)
          break
        case "San Isidro":
          arrTipos = [
            { id: 4, name: "Depas Los Nogales" },
            { id: 5, name: "Depas Basadre" },
            { id: 12, name: "Depas Ugarte y Moscoso 330" },
            { id: 16, name: "Depas Ugarte y Moscoso 370" },
            { id: 10, name: "Depas Machaypuito" },
            { id: 15, name: "Depas Laureles" },
          ]
          setValoresTipoProyecto(arrTipos)
          break
        case "Barranco":
          arrTipos = [{ id: 6, name: "Depas Saenz Peña" }]
          setValoresTipoProyecto(arrTipos)
          break
        case "":
          setValoresTipoProyecto([])
          break
        default:
          setValoresTipoProyecto([])
          break
      }
    } else {
      setValoresTipoProyecto([])
    }

    formikRef.current.setFieldValue("TipoProyecto", "")
    formikRef.current?.handleChange(event)
  }, [])

  const onChangeTipoProyecto = useCallback(event => {
    const valorSelect = event?.target?.value
    if (valorSelect) {
      switch (valorSelect) {
        // Oficinas Libertad
        case "14":
          setPaginaGracias("/proyecto/gracias-libertad/")
          break
        // Depas Los Nogales
        case "4":
          setPaginaGracias("/proyecto/gracias-nogales/")
          break
        // Depas Basadre
        case "5":
          setPaginaGracias("/proyecto/gracias-basadre/")
          break
        //Depas Ugarte y Moscoso 330
        case "12":
          setPaginaGracias("/proyecto/gracias-ugarte-y-moscoso-330/")
          break
        //Depas Ugarte y Moscoso 370
        case "16":
          setPaginaGracias("/proyecto/gracias-ugarte-y-moscoso-370/")
          break
        // Depas Machaypuito
        case "10":
          setPaginaGracias("/proyecto/gracias-machaypuito/")
          break
        // Depas Laureles
        case "15":
          setPaginaGracias("/proyecto/gracias-laureles/")
          break
        // Depas Saenz Peña
        case "6":
          setPaginaGracias("/proyecto/gracias-saenz/")
          break
        default:
          setPaginaGracias("/")
          break
      }
    }

    formikRef.current?.handleChange(event)
  }, [])

  return (
    <section>
      <Formik
        innerRef={formikRef}
        initialValues={{
          Nombre: "",
          Apellido: "",
          Celular: "",
          Email: "",
          Distrito: "",
          TipoProyecto: "",
          Me_interesa: "",
          Acepto_condiciones: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombre) errors.Nombre = "Campo requerido"
          if (!values.Apellido) errors.Apellido = "Campo requerido"
          if (!values.Celular) errors.Celular = "Campo requerido"
          if (!values.Email) errors.Email = "Campo requerido"
          if (!values.Distrito) errors.Distrito = "Campo requerido"
          if (!values.TipoProyecto) errors.TipoProyecto = "Campo requerido"
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          )
            errors.Email = "Correo Invalido"
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = "Campo requerido"
          // if (!values.Me_interesa) errors.Me_interesa = 'Campo requerido';
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          try {
            const meInteresa = values.Me_interesa
              ? "Me interesa ser parte de la comunidad de Armando Paredes y recibir información."
              : ""

            const jsonData = {
              email: values.Email,
              fname: values.Nombre,
              lname: values.Apellido,
              phone: values.Celular,
              project_id: parseInt(values.TipoProyecto),
              interest_type_id: data?.interese_type_id,
              source_id: data?.source_id,
              observation: `Me interesa: ${meInteresa}, fbc: ${data?.fbc}, fbp: ${data?.fbp}, ip_address: ${data?.ip_address}`,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: data?.utm_content,
              utm_term: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }

            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jsonData),
            })
              .then(res => res.json())
              .then(json => {
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()
                  if (paginaGracias) navigate(paginaGracias)

                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error)
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-60 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="Nombre"
                placeholder="Nombre*"
              />
              <ErrorMessage
                className="form-error"
                name="Nombre"
                component="div"
              />
            </div>
            <div className="grid-40 form-group" css={apellidosCss}>
              <Field
                className="form-control"
                type="text"
                name="Apellido"
                placeholder="Apellido*"
              />
              <ErrorMessage
                className="form-error"
                name="Apellido"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="tel"
                name="Celular"
                placeholder="Celular"
              />
              <ErrorMessage
                className="form-error"
                name="Celular"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="Email"
                placeholder="Correo*"
              />
              <ErrorMessage
                className="form-error"
                name="Email"
                component="div"
              />
            </div>

            <div className="grid-100 grid-x-0 form-group">
              <Field
                name="Distrito"
                as="select"
                className="form-control select-gris"
                onChange={event => onChangeDistrito(event)}
              >
                <option value="">Estoy buscando depa en:</option>
                <option value="Miraflores">Miraflores</option>
                <option value="San Isidro">San Isidro</option>
                <option value="Barranco">Barraco</option>
              </Field>
              <ErrorMessage
                className="form-error"
                name="Distrito"
                component="div"
              />
            </div>
            <div className="grid-100 grid-x-0 form-group">
              <Field
                name="TipoProyecto"
                as="select"
                className="form-control select-gris"
                onChange={onChangeTipoProyecto}
              >
                <option value="">Quiero saber más sobre este proyecto:</option>
                {valoresTipoProyecto &&
                  valoresTipoProyecto.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                className="form-error"
                name="TipoProyecto"
                component="div"
              />
            </div>
            <div css={spaceFormCss} />
            <div className="grid-100 grid-x-0">
              <label
                className="form-acept"
                htmlFor="Me_interesa"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Me_interesa"
                  id="Me_interesa"
                />
                &nbsp;&nbsp;&nbsp;Me interesa ser parte de la comunidad de
                Armando Paredes y recibir información.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Me_interesa"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;Acepto las condiciones de la{" "}
                <a
                  href="/politica-de-privacidad/"
                  css={linkCss}
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidad.
                </a>
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="grid-100 grid-x-0">
              <button
                className=""
                type="submit"
                disabled={isSubmitting}
                css={submitCss}
              >
                Enviar
              </button>
            </div>
            {envio === false && envio !== "" && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== "" && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
