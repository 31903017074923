import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Title from "../../atoms/Title/Title"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import {css} from '@emotion/react';
import Overlay from "../../organisms/Overlay/Overlay"
import ProyectoEntry from '../../organisms/ProyectoEntry/ProyectoEntry';

export default function GridEntregados() {

  const data = useStaticQuery(graphql`
  {
    wordpress {
      page(id: "309", idType: DATABASE_ID) {
        title
        uri
        template {
          ... on Wordpress_Template_Entregados {
            templateName
            entregados {
              grillasProyectos {
                ... on Wordpress_ProyectoEntregado {
                  id
                  title
                  uri
                  featuredImage {
                    node {
                      ...imagen
                    }
                  }
                  distritos {
                    nodes {
                      name
                    }
                  }
                  template {
                    ... on Wordpress_Template_Entregado {
                      templateName
                      entregado {
                        anoGeneral
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)

  let entregados = data?.wordpress?.page.template.entregados.grillasProyectos;
  
  let anterior = 0;
  entregados.forEach((entregado)=>{
    if(entregado.template.entregado.anoGeneral !== anterior){
      anterior = entregado.template.entregado.anoGeneral;
    }else{
      entregado.template.entregado.anoGeneral="";
    }
  })
  
  const sectionCss = css`
    padding-top: calc(100vw * (calc(99 / var(--width_base))));
    padding-left: calc(100vw * (calc(80 / var(--width_base))));
    padding-right: calc(100vw * (calc(80 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  `

  const titleOverlayCss = css`
    font-family: "Lato", sans-serif;
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(33 / var(--width_base))));
  `;

  const imagenOverlayCss = css`
    width: 100%;
    height: calc(100vw * (calc(412 / var(--width_base))));
  `;

  const overlayCss = css`
    overflow: hidden;
    margin-bottom: calc(100vw * (calc(57 / var(--width_base))));
  `;

  const iconOverlayCss = css`
    position: absolute;
    right: calc(100vw * (calc(57 / var(--width_base))));
    bottom: calc(100vw * (calc(60 / var(--width_base))));
    width: calc(100vw * (calc(32 / var(--width_base))));
    height: calc(100vw * (calc(32 / var(--width_base))));
  `;

  const textProyectoCss = css`
    font-weight: 400;
    ${titleOverlayCss}
  `;

  const textDistritoCss = css`
    font-weight: 700;
    ${titleOverlayCss}
  `;

  const anioCss = css`
    font-size: calc(100vw * (calc(30 / var(--width_base))));
    line-height: calc(100vw * (calc(37 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(24 / var(--width_base))));
    font-weight: 600;
  `;

  const boxEntregadosCss = css`
    display: flex;
    min-height: calc(100vw * (calc(94 / var(--width_base))));
  `;

  const anioEntregadosCss = css`
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    background-color: #FCF8F3;
    font-weight: 600;
    padding-left: calc(100vw * (calc(16 / var(--width_base))));
    padding-right: calc(100vw * (calc(16 / var(--width_base))));
    margin-right: calc(100vw * (calc(15 / var(--width_base))));
    min-width: calc(100vw * (calc(92 / var(--width_base))));
    flex-shrink: 0;
  `;

  return (
    <SectionBackground className="grid-100" styleCss={sectionCss}>
      {
        entregados && entregados.map((entregado, i)=>(
          <div className="grid-25 only-desk">
            {
              (entregado.template.entregado.anoGeneral !== "") ?
                <h3 css={anioCss}>{entregado.template.entregado.anoGeneral}</h3>
              :
                <div css={anioCss} className="is-hidden">&nsbp;</div>
            }

            <Overlay key={i} imagen={entregado.featuredImage.node} link={entregado.uri} styleCssImage={imagenOverlayCss} styleCss={overlayCss}>
              <Title styleCss={textProyectoCss} text={entregado.title}/>
              <Title styleCss={textDistritoCss} text={entregado.distritos.nodes[0].name}/>
              <svg role="img" aria-labelledby="icono más" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconOverlayCss}>
              <rect x="22.8452" width="7.31035" height="53" fill="black"/>
              <rect x="53" y="22.8448" width="7.31035" height="53" transform="rotate(90 53 22.8448)" fill="black"/>
              </svg>
            </Overlay>
          </div>
        ))
      }
      <div className="only-mov">
          <div css={boxEntregadosCss}>
            <Title text="" styleCss={anioEntregadosCss}/>
            <span/>
          </div>
        {
          entregados && entregados.map((entregado, i)=>(
            <div css={boxEntregadosCss}>
              <Title text={entregado.template.entregado.anoGeneral} styleCss={anioEntregadosCss}/>
              <ProyectoEntry proyecto={entregado} keyEle={i}/>
            </div>
          ))
        }
      </div>
    </SectionBackground>
  );

}