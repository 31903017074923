import React, { useState } from "react"
import Logo from "../../molecules/Logo/Logo"
import Menu from "../../molecules/Menu/Menu"
import TextWhatsapp from "../../molecules/TextWhatsapp/TextWhatsapp"
import { css } from "@emotion/react"
import MenuMovil from "../../molecules/Menu/MenuMovil"

export default function Header() {
  const cssHeader = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: calc(100vw * (calc(70 / var(--width_base))));
    z-index: 100;
    width: 100%;
    box-shadow: #000 0px 0px 6px;
    display: flex;
    align-content: center;

    @media (max-width: 767px) {
      position: relative;
      height: calc(100vw * (calc(53 / var(--width_base))));
      padding-left: calc(100vw * (calc(16 / var(--width_base))));
      padding-right: calc(100vw * (calc(16 / var(--width_base))));
    }
  `

  const cssHeaderContent = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100vw * (calc(1185 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  `

  const cssHeaderNav = css`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
    }
  `

  const [heightList, setHeightList] = useState(0)

  const handleClickMenuToogle = () => {
    setHeightList(heightList === 0 ? "auto" : 0)
  }

  return (
    <>
      <header css={cssHeader}>
        <div css={cssHeaderContent}>
          <div css={cssHeaderNav}>
            <Logo />
            <Menu />
            <button className="only-mov" onClick={handleClickMenuToogle}>
              <i className="fas fa-bars" />
            </button>
          </div>
          <TextWhatsapp iconWhatsapp={true} classEle="only-desk" />
        </div>
      </header>
      <MenuMovil heightList={heightList} />
    </>
  )
}
