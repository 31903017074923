import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { graphql } from "gatsby"
import {css} from '@emotion/react';
import Image from "../../atoms/Image/Image"

export default function MuchoArmando({imagen}) {

  const sectionCss = css`
    height: 100%;
    min-height: calc(100vw * (calc(800 / var(--width_base))));
    @media (max-width: 767px) {
      display: none;
    }
  `;

  const imagenCss = css`
    width: 100%;
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  `;

  return (
    <>
      <SectionBackground imagen={imagen} styleCss={sectionCss} bgType="fixed"/>
      <Image imagen={imagen} styleCss={imagenCss} classEle="only-desk"/>
    </>
    
  );

}

export const queryMuchosArmando = graphql`
  fragment muchosArmando on Wordpress_Template_Armando_Armando{
      imagenArmando {
        ...imagen
      }
  }
`