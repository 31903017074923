import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerArmando from "../components/templates/Armando/BannerArmando"
import PrimerosArmando from "../components/templates/Armando/PrimerosArmando"
import EstamosArmando from "../components/templates/Armando/EstamosArmando"
import NosInspiraArmando from "../components/templates/Armando/NosInspiraArmando"
import NuestraArmando from "../components/templates/Armando/NuestraArmando"
import NuestrosArmando from "../components/templates/Armando/NuestrosArmando"
import MuchoArmando from "../components/templates/Armando/MuchoArmando"
import VidaArmando from "../components/templates/Armando/VidaArmando"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_Armando {
            armando {
              ...bannerArmando
              ...primerosArmando
              ...estamosArmando
              ...nosInspirasArmando
              ...nuestraArmando
              ...nuestrosArmando
              ...muchosArmando
              ...vidaArmando
            }
          }
        }
      }
    }
  }
`

export default function Armando({ data }) {
  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }

  const page = data?.wordpress?.page

  const template = page?.template?.armando

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />

      <h1 className="d-none">{page.title}</h1>
      <BannerArmando
        titulo={template.tituloBanner}
        texto={template.textoBanner}
        botonTexto={template.botonTextoBanner}
        colorTexto={template.colorTextoBanner}
        imagen={template.imagenBanner}
      />
      <PrimerosArmando
        colorFondo={template.colorFondoPrimeros}
        titulo={template.tituloPrimeros}
        imagen={template.imagenPrimeros}
        texto={template.textoPrimeros}
      />
      <EstamosArmando
        colorFondo={template.colorFondoEstamos}
        texto={template.textoEstamos}
        titulo={template.tituloEstamos}
        imagen2={template.imagen2Estamos}
        imagen1={template.imagen1Estamos}
      />
      <NosInspiraArmando
        colorFondo={template.colorFondoNosInspira}
        texto={template.textoNosInspira}
        titulo={template.tituloNosInspira}
        imagen1={template.imagen1NosInspira}
        imagen2={template.imagen2NosInspira}
        imagen3={template.imagen3NosInspira}
      />
      <NuestraArmando
        imagen={template.imagenNuestra}
        titulo={template.tituloNuestra}
        texto={template.textoNuestra}
        colorFondo={template.colorFondoNuestra}
      />
      <NuestrosArmando
        colorFondo={template.colorFondoNuestros}
        texto={template.textoNuestros}
        titulo={template.tituloNuestros}
      />
      <MuchoArmando imagen={template.imagenArmando} />
      <VidaArmando imagen={template.imagenVida} />
    </LayoutGeneral>
  )
}
