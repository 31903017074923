import React from 'react';
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { css } from "@emotion/react"
import Title from "../../atoms/Title/Title"

export default function CuandoPrograma({titulo, beneficiosTi, beneficiosAmigo,bono,descuento,textoBeneficiosAmigo,textoBeneficiosTi}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(72 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(72 / var(--width_base))));
    @media (max-width: 767px){
      padding-right: calc(100vw * (calc(7 / var(--width_base))));
      padding-left: calc(100vw * (calc(7 / var(--width_base))));
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(36 / var(--width_base))));
    line-height: calc(100vw * (calc(36 / var(--width_base))));
    font-family: "Gotham", sans-serif;
    font-weight: bold;
    margin-bottom: calc(100vw * (calc(72 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const imageTextoCss = css`
    height: calc(100vw * (calc(90 / var(--width_base))));
    margin-top: calc(100vw * (calc(40 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(29 / var(--width_base))));
  `;

  const espacioImagenCss = css`
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    }
  `;

  return (
    <SectionBackground widthContent={1226} styleCss={sectionCss}>
      <div className="grid-100 grid-x-0">
        <Title text={titulo} styleCss={titleCss}/>
        <div className="grid-50">
          <Image imagen={beneficiosTi} classEle="w-full"/>
          <Image imagen={textoBeneficiosTi} styleCss={imageTextoCss} objectFit="contain"/>
          <Image imagen={bono} classEle="w-full" styleCss={espacioImagenCss}/>
        </div>
        <div className="grid-50">
          <Image imagen={beneficiosAmigo} classEle="w-full"/>
          <Image imagen={textoBeneficiosAmigo} styleCss={imageTextoCss} objectFit="contain"/>
          <Image imagen={descuento} classEle="w-full"/>
        </div>
      </div>
    </SectionBackground>
  );

}

export const queryCuandoPrograma = graphql`
  fragment cuandoPrograma on Wordpress_Template_ProgramaDeReferidos_Programadereferidos{
    tituloCuando
    beneficiosParaTiCuando {
      ...imagen
    }
    beneficiosParaTuAmigoCuando {
      ...imagen
    }
    textBeneficiosParaTuAmigoCuando {
      ...imagen
    }
    textoBeneficiosParaTiCuando {
      ...imagen
    }
    bonoCuando {
      ...imagen
    }
    descuentoCuando {
      ...imagen
    }
  }
`