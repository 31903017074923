import { css } from '@emotion/react';
import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import { navigate } from "gatsby"

export default function ValuesFilter({distritos, dormitorios, etapas}) {

    const valuesCss = css`
        width: 100%;
        margin-bottom: calc(100vw * (calc(83 / var(--width_base))));
        display: flex;
    `;

    const valuesItem = css`
        display: flex;
        text-transform: uppercase;
        font-size: calc(100vw * (calc(24 / var(--width_base))));
        line-height: calc(100vw * (calc(30 / var(--width_base))));
        align-items: center;
        cursor: pointer;
        padding: 0;
      
        &:not(:last-of-type){
            margin-right: calc(100vw * (calc(50 / var(--width_base))));
        }
    `;    

    const removeDistrito = (valor) =>{
      const seccionEle = document.getElementById("banner-proyecto");
      if(seccionEle) seccionEle.classList.remove("is-visible");
      const newDistritos = distritos.filter((distrito) => distrito !== valor);
      navigate(`/proyectos/?distrito=${newDistritos.join("|")}&dormitorio=${dormitorios.join("|")}&etapa=${etapas.join("|")}`);
      setTimeout(()=>{
        if(seccionEle) seccionEle.classList.add("is-visible");
      },500)
    }

  const removeDormitorios = (valor) =>{
    const seccionEle = document.getElementById("banner-proyecto");
    if(seccionEle) seccionEle.classList.remove("is-visible");
    const newDormitorios = dormitorios.filter((dormitorio) => dormitorio !== valor);
    navigate(`/proyectos/?distrito=${distritos.join("|")}&dormitorio=${newDormitorios.join("|")}&etapa=${etapas.join("|")}`);
    setTimeout(()=>{
      if(seccionEle) seccionEle.classList.add("is-visible");
    },500)
  }

  const removeEtapas = (valor) =>{
    const seccionEle = document.getElementById("banner-proyecto");
    if(seccionEle) seccionEle.classList.remove("is-visible");
    const newEtapas = etapas.filter((etapa) => etapa !== valor);
    navigate(`/proyectos/?distrito=${distritos.join("|")}&dormitorio=${dormitorios.join("|")}&etapa=${newEtapas.join("|")}`);
    setTimeout(()=>{
      if(seccionEle) seccionEle.classList.add("is-visible");
    },500)
  }

    return (
        <div css={valuesCss}>
            {
              distritos && distritos.map((distrito)=>(
                  <button css={valuesItem} onClick={()=>removeDistrito(distrito)}>
                      <Icon type="check"/>
                      {distrito}
                  </button>
              ))
            }
            {
              dormitorios && dormitorios.map((dormitorio)=>(
                <button css={valuesItem} onClick={()=>removeDormitorios(dormitorio)}>
                    <Icon type="check"/>
                    {dormitorio}
                </button>
             ))
            }
            {
              etapas && etapas.map((etapa)=>(
                <button css={valuesItem} onClick={()=>removeEtapas(etapa)}>
                    <Icon type="check"/>
                    {etapa}
                </button>
              ))
            }
        </div>
    )
}

ValuesFilter.defaultProps = {
  dormitorios: [],
  distritos: [],
  etapas: []
}