import React from 'react';
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"

export default function HitoMapa({keyEle,icon, classEle}) {

  const iconCss = css`
    &{
      z-index: 10;
    }
    &,& img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(20 / var(--width_base))));
      height: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  return (
    <div key={keyEle} id={`hitoMapa${keyEle}`} className={classEle}>
      {
        icon &&
        <Image imagen={icon} styleCss={iconCss} objectFit="contain"/>
      }
    </div>
  );

}

HitoMapa.defaultProps = {
  i: 0,
  icon: {}
}