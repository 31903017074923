import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerDepas from "../components/templates/OthersPage/BannerDepas"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_Depas {
            templateName
            depasConTerraza {
              correo
              direccion
              imagenClara {
                ...imagen
              }
              imagenOscura {
                ...imagen
              }
              logo {
                ...imagen
              }
              numeroWhatsapp
              texto
            }
          }
        }
      }
    }
  }
`

export default function Depas({ data }) {
  const page = data.wordpress.page
  const template = page?.template?.depasConTerraza

  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <BannerDepas
        imagenClara={template?.imagenClara}
        imagenOscura={template?.imagenOscura}
        correo={template?.correo}
        direccion={template?.direccion}
        logo={template?.logo}
        nroWhatsapp={template?.numeroWhatsapp}
        texto={template?.texto}
      />
    </LayoutGeneral>
  )
}
