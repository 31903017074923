import React from 'react';
import Image from "../../atoms/Image/Image"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { graphql } from "gatsby"
import {css} from '@emotion/react';
import Icon from "../../atoms/Icon/Icon"

export default function DetalleEntregado({imagen,info, color}) {

  const imagenCss = css`
    height: calc(100vw * (calc(998 / var(--width_base))));
    @media (max-width: 767px){
      display:none !important;
    }
  `;

  const itemCss = css`
    display: flex;
    align-items: center;
    padding-top: calc(100vw * (calc(25 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
    border-bottom: calc(100vw * (calc(2 / var(--width_base)))) solid ${color};
    @media (max-width: 767px){
      flex-flow:row-reverse;
      justify-content: space-between;
      border-bottom: initial;
      padding-top: calc(100vw * (calc(22 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(22 / var(--width_base))));
    }
  `;

  const iconCss = css`
    width: calc(100vw * (calc(69 / var(--width_base))));
    height: calc(100vw * (calc(69 / var(--width_base))));
    @media (max-width: 767px){
      width: calc(100vw * (calc(43 / var(--width_base))));
      height: calc(100vw * (calc(43 / var(--width_base))));
    }
  `;

  const itemTextoCss = css`
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    line-height: calc(100vw * (calc(34 / var(--width_base))));
    font-style: italic;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(19 / var(--width_base))));
    }
  `;

  const itemTitleCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    text-transform: uppercase;
    margin-top: calc(100vw * (calc(14 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(19 / var(--width_base))));
      margin-top: initial;
      margin-bottom: calc(100vw * (calc(4 / var(--width_base))));
      font-weight: 600;
    }
  `;

  const groupImagenCss = css`
    width: calc(100vw * (calc(250 / var(--width_base))));
    flex-shrink: 0;
    text-align: center;
  `;

  const contentRightCss = css`
    padding-left: calc(100vw * (calc(63 / var(--width_base))));
    padding-right: calc(100vw * (calc(140 / var(--width_base))));
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767px){
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20   / var(--width_base))));
    }
  `;

  const sectionCss = css`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  `;

  return (
    <section className="grid-100 grid-x-0" css={sectionCss}>
      <div className="grid-50 grid-x-0">
        <Image imagen={imagen} classEle="w-full" styleCss={imagenCss}/>
      </div>
      <div className="grid-50" css={contentRightCss}>
        {
          info && info.map((item, i)=>(
            <div key={i} css={itemCss}>
              <div css={groupImagenCss}>
                {/* <Image imagen={item.imagen} styleCss={itemImagenCss}/> */}
                {(i === 0) && <Icon type="icon-ubicacion" styleEle={iconCss} color={color}/>}
                {(i === 1) && <Icon type="icon-piso" styleEle={iconCss} color={color}/>}
                {(i === 2) && <Icon type="icon-metraje" styleEle={iconCss} color={color}/>}
                {(i === 3) && <Icon type="icon-calendario" styleEle={iconCss} color={color}/>}
                <Paragraph text={item.titulo} styleCss={itemTitleCss} classEle="only-desk"/>
              </div>
              <div>
                <Paragraph text={item.titulo} styleCss={itemTitleCss} classEle="only-mov"/>
                <Paragraph text={item.texto} styleCss={itemTextoCss}/>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );

}

export const queryDetalleEntregado = graphql`
  fragment detalleEntregado on Wordpress_Template_Entregado_Entregado{
    imagenDetalle {
      ...imagen
    }
    infoDetalle {
      texto
      titulo
      imagen {
        ...imagen
      }
    }
  }
`