import React from 'react';
import { css } from "@emotion/react"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function TextArmando({titulo,texto, color, color_titulo}) {

  const titleCss = css`
    font-size: calc(100vw * (calc(42 / var(--width_base))));
    line-height: calc(100vw * (calc(42 / var(--width_base))));
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    color: ${color_titulo};
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    font-style: italic;
    color: ${color};
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(67 / var(--width_base))));
      padding-right: calc(100vw * (calc(67 / var(--width_base))));
    }
  `;

  const sectionCss = css`
    padding: calc(100vw * (calc(80 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base)))) calc(100vw * (calc(20 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    text-align: center;
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(7 / var(--width_base))));
      padding-right: calc(100vw * (calc(7 / var(--width_base))));
    }
  `;

  const lineaCss = css`
    line-height: calc(100vw * (calc(70 / var(--width_base))));
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    font-family: "Source Sans Pro",sans-serif;
    font-style: italic;
    color: ${color};
  `;

  return (
    <div css={sectionCss}>
      <Title typeEle="h2" text={titulo} styleCss={titleCss}/>
      <span css={lineaCss}>----</span>
      <Paragraph text={texto} styleCss={textCss}/>
      <span css={lineaCss}>----</span>
    </div>
  );

}