import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {css} from "@emotion/react";
import List from "../List/List";

const Menu = () => {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        menuItems(where: {location: PRIMARY}) {
          nodes {
            id
            label
            url
            title
            target
            childItems(before: "") {
              edges {
                node {
                  id
                  label
                  url
                  title
                  target
                }
              }
            }
          }
        }
        generalSettings {
          url
        }
      }
    }
  `)

  var menu = css`
    margin-left: calc(100vw * (calc(20 / var(--width_base))));
    .menu__link{
      min-width: calc(100vw * (calc(117 / var(--width_base))));
      text-align: center;
      text-transform: uppercase;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      min-height: calc(100vw * (calc(70 / var(--width_base))));
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover{
        background-color: rgba(240, 240, 240, 0.78);
      }
    }
    .menu__item:not(:last-of-type) .menu__link:before{
      content: "";
      border-right: calc(100vw * (calc(1 / var(--width_base)))) solid black;
      height: calc(100vw * (calc(28 / var(--width_base))));
      width: calc(100vw * (calc(1 / var(--width_base))));
      position: absolute;
      right: 0;
    }
  `;

  const menuListCss = css`
    display: flex;
    align-items: center;
  `;

  const menuItems = data.wordpress.menuItems.nodes
  const wordPressUrl = data.wordpress.generalSettings.url

  return (
    <nav css={menu} className="only-desk">
      <List menuItems={menuItems} wordPressUrl={wordPressUrl} styleCss={menuListCss}/>
    </nav>
  );
}

export default Menu

