import React from 'react';
import Image from "../../atoms/Image/Image";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import { graphql, Link } from "gatsby"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import {css} from '@emotion/react';

export default function OtroProyecto({imagen, texto, colorFondo, colorFondoBox,linkPagina}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: initial;
      padding-bottom: initial;
    }
  `;

  const contentCss = css`
    display: flex;
    
    @media (max-width: 767px){
      flex-direction: column;
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(35 / var(--width_base))));
    padding: calc(100vw * (calc(50 / var(--width_base)))) calc(100vw * (calc(60 / var(--width_base))));
    width: 60%;
    flex-shrink: 0;
    margin-left: 40%;
    background-color: ${colorFondoBox};
    @media (max-width: 767px){
      width: 100%;
      margin-left: initial;
      font-size: calc(100vw * (calc(22 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const imagenCss = css`
    height: calc(100vw * (calc(100 / var(--width_base))));
    overflow: hidden;
    &.gatsby-image-wrapper-constrained{
      height: 100%;
      position: absolute !important;
      width: 40%;
      top: 0;
      bottom: 0;
      @media (max-width: 767px){
        position: relative !important;
        top: initial;
        bottom: initial;
        width: 100%;
        height: calc(100vw * (calc(152 / var(--width_base))));
      }
    }
  `;

  const linkCss = css`
    width: 100%;
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent="1200" styleCssContent={contentCss} styleCss={sectionCss}>
      <Link to={linkPagina ? linkPagina : "/"} css={linkCss}>
        {imagen && <Image imagen={imagen} styleCss={imagenCss}/>}
        {texto && <Paragraph text={texto} styleCss={textCss}/>}
      </Link>
    </SectionBackground>
  );

}

export const queryOtroProyecto = graphql`
  fragment otroProyecto on Wordpress_Template_Proyecto_Proyecto{
    imagenOtroProyecto {
      ...imagen
    }
    colorFondoOtroProyecto
    textoOtroProyecto
    colorFondoTextoOtroProyecto
    linkPaginaOtroProyecto {
      ... on Wordpress_Proyecto {
        uri
      }
    }
  }
`