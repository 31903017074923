import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import TextArmando from "../../organisms/GroupText/TextArmando"

export default function NuestraArmando({imagen, titulo, texto, colorFondo}) {

  const contentCss = css`
    background-color:  ${colorFondo};
  `

  const imagenCss = css`
    width: 100%;
  `

  return (
    <SectionBackground widthContent={1200} styleCssContent={contentCss}>
      <div className="grid-40 grid-x-0 line-height-0">
        <Image imagen={imagen} styleCss={imagenCss}/>
      </div>
      <div className="grid-60 grid-x-0 line-height-0">
        <TextArmando titulo={titulo} texto={texto} color="white" color_titulo="white"/>
      </div>
    </SectionBackground>
  );

}

export const queryNuestraArmando = graphql`
  fragment nuestraArmando on Wordpress_Template_Armando_Armando{
    colorFondoNuestra
    tituloNuestra
    textoNuestra
    imagenNuestra {
      ...imagen
    }
  }
`