import React from "react"
import { useEffect } from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerProyecto from "../components/templates/Proyecto/BannerProyecto"
import FormProyecto from "../components/templates/Proyecto/FormProyecto"
import DescripcionProyecto from "../components/templates/Proyecto/DescripcionProyecto"
import ElProyectoProyecto from "../components/templates/Proyecto/ElProyectoProyecto"
import FichaProyecto from "../components/templates/Proyecto/FichaProyecto"
import EligeProyecto from "../components/templates/Proyecto/EligeProyecto"
import MapaProyecto from "../components/templates/Proyecto/MapaProyecto"
import OtroProyecto from "../components/templates/Proyecto/OtroProyecto"
import ConoceInicio from "../components/templates/Inicio/ConoceInicio"
import getUri from "../helper/getUri"
import Modal from "../components/organisms/Modal/Modal"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      proyecto(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        distritos {
          nodes {
            name
          }
        }
        template {
          ... on Wordpress_Template_Proyecto {
            proyecto {
              linkDeWhatsapp
              colorPrimarioGeneral
              colorTextoTituloGeneral
              colorSecundarioGeneral
              textoParaHomeEstadoGeneral
              textoParaHomeDistritoGeneral
              textoParaHomeDormitoriosGeneral
              ...bannerProyecto
              ...formProyecto
              ...descripcionProyecto
              ...elProyecto
              ...fichaProyecto
              ...eligeProyecto
              ...mapaProyecto
              ...otroProyecto
            }
          }
        }
      }
      page(idType: DATABASE_ID, id: "912") {
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              ...conoceInicio
              activarModalHome
              activarModalProyectos
              urlModal
              imagenModal {
                ...imagen
              }
            }
          }
        }
      }
    }
  }
`

export default function Proyecto({ data }) {
  // if (typeof window !== "undefined") {
  //   let bodyHtml = document.querySelector("body")
  //   bodyHtml.classList.remove("no-scroll")
  // }

  const page = data.wordpress.proyecto
  const pageInicio = data.wordpress.page
  const template = page?.template?.proyecto
  const templateInicio = pageInicio?.template?.inicio

  useEffect(() => {
    if (typeof window !== "undefined") {
      let btnsWsp = document.querySelectorAll(".btn-wsp")
      if (template.linkDeWhatsapp)
        btnsWsp.forEach(btnWsp => {
          btnWsp.attributes.href.value = template.linkDeWhatsapp
        })
    }
  }, [])

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>

      <Modal
        active={templateInicio?.activarModalProyectos}
        image={templateInicio?.imagenModal}
        url={templateInicio?.urlModal}
      />
      <BannerProyecto
        imagenFondo={template.imagenFondoBanner}
        logo={template.logoBanner}
        posicionLogo={template.posicionLogoBanner}
      />
      <FormProyecto
        colorFondoEstado={template?.colorFondoEstadoFormulario}
        colorFondo={template?.colorFondoFormulario}
        colorFondoTexto={template?.colorFondoTextoFormulario}
        colorTextoEstado={template?.colorTextoEstadoFormulario}
        colorTexto={template?.colorTextoFormulario}
        text={template?.textoFormulario}
        distrito={page?.distritos.nodes[0].name}
        estado={template?.estadoFormulario}
        icon={template?.distritoIconoFormulario}
        apiInputChannelIds={template?.apiInputChannelIdsFormulario}
        apiNivelId={template?.apiNivelIdFormulario}
        apiProjectRelated={template?.apiProjectRelatedFormulario}
        apiSourceId={template?.apiSourceIdFormulario}
        paginaGracias={template?.paginaDeGraciasFormulario?.uri}
        presupuesto={template?.valoresDePresupuestoFormulario}
        colorPrimario={template?.colorPrimarioGeneral}
        colorFondoBoton={template?.colorFondoBotonFormulario}
        title={page.title}
      />
      <DescripcionProyecto
        titulo={template.tituloDescripcion}
        colorTitulo={template.colorTextoTituloGeneral}
        colorFondo={template.colorFondoDescripcion}
        texto={template.textoDescripcion}
        imagen={template.imagenDescripcion}
        icono={template.imagenIconoDescripcion}
        textoInfo={template.textoInfoDescripcion}
        colorPrimario={template.colorPrimarioGeneral}
      />
      <ElProyectoProyecto
        colorFondo={template.colorFondoElProyecto}
        detalle={template.detalleElProyecto}
        invertir={template.invertirDireccionElProyecto}
      />
      {template.departamentosEligeElQue && (
        <EligeProyecto
          tituloProyecto={page.title}
          titulo={template?.tituloEligeElQue}
          subtitulo={template?.subtituloEligeElQue}
          depas={template?.departamentosEligeElQue}
          colorFondo={template?.colorFondoEligeElQue}
          colorFondoTexto={template?.colorFondoTextoEligeElQue}
          colorFondoBotonDepaActivo={
            template?.colorFondoBotonDepaActivoEligeElQue
          }
          colorTextoBotonDepaActivo={
            template?.colorTexotBotonDepaActivoEligeElQue
          }
          colorFondoBotonDepaNoActivo={
            template?.colorFondoBotonDepaNoActivoEligeElQue
          }
          colorTextoBotonDepaNoActivo={
            template?.colorTextoBotonDepaNoActivoEligeElQue
          }
          colorFondoBarraTitulo={template?.colorFondoBarraTituloEligeElQue}
          colorTextoBarraTitulo={template?.colorTextoBarraTituloEligeElQue}
          colorBarraUbicacion={template?.colorFondoBarraUbicacionEligeElQue}
          colorTextoBarraUbicacion={
            template?.colorTextoBarraUbicacionEligeElQue
          }
          colorFondoBotonesUbicacion={
            template?.colorFondoBotonesBarraUbicacionEligeElQue
          }
          colorIconoBarraUbicacion={
            template?.colorIconoBarraUbicacionEligeElQue
          }
          colorIconoBotonesUbicacion={
            template?.colorIconoBotonesBarraUbicacionEligeElQue
          }
          colorPrimario={template.colorPrimarioGeneral}
          colorTitulo={template.colorTextoTituloGeneral}
        />
      )}
      {template?.descripcionFicha && (
        <FichaProyecto
          tituloProyecto={page.title}
          titulo={template?.tituloFicha}
          colorFondo={template?.colorFondoFicha}
          descripcion={template?.descripcionFicha}
          botones={template?.botonesFicha}
          // pdfBrochure={template?.pdfBrochureFicha?.mediaItemUrl}
          // pdfPlanos={template?.pdfPlanosFicha?.mediaItemUrl}
          colorPrimario={template?.colorPrimarioGeneral}
          colorFondoBotones={template?.colorFondoBotonesFicha}
          colorIconoBotones={template?.colorIconoBotonesFicha}
          colorTitulo={template.colorTextoTituloGeneral}
        />
      )}

      {template.logoMapa &&
        template.ubicaciones &&
        template.latitudMapa &&
        template.longitudMapa && (
          <MapaProyecto
            titulo={template.tituloMapa}
            colorTitulo={template.colorTextoTituloGeneral}
            logo={template.logoMapa}
            ubicaciones={template.ubicaciones}
            colorPrimario={template.colorPrimarioGeneral}
            latitud={template.latitudMapa}
            longitud={template.longitudMapa}
          />
        )}
      {template.imagenOtroProyecto && (
        <OtroProyecto
          imagen={template.imagenOtroProyecto}
          colorFondo={template.colorFondoOtroProyecto}
          texto={template.textoOtroProyecto}
          colorFondoBox={template.colorFondoTextoOtroProyecto}
          linkPagina={template?.linkPaginaOtroProyecto?.uri}
        />
      )}

      <ConoceInicio
        isProyecto={true}
        proyectos={templateInicio?.proyectosConoce}
        id={page.databaseId}
      />
    </LayoutGeneral>
  )
}
