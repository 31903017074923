import { css } from '@emotion/react';
import React from 'react';
import { Link as LinkScroll } from 'react-scroll';
import Icon from '../../atoms/Icon/Icon';

export default function UbicacionWhatsapp({linkWsp, colorFondo, colorIcon}) {
    
    const linkCss = css`
        width: 50%;
        float: left;
        text-align: center;
        padding: calc(100vw * (calc(15 / var(--width_base)))) calc(100vw * (calc(30 / var(--width_base))));
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        background-color: ${colorFondo};
        color: white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        
        &:nth-of-type(2){
            opacity: .7;
        }
    `;

    const iconCss = css`
        font-size: calc(100vw * (calc(24 / var(--width_base))));
    `;

    return(
        <div className="only-mov">
            <LinkScroll css={linkCss} to="mapa" spy={true} smooth={true} duration={500} offset={0}>
                <Icon type="marker" styleEle={iconCss} color={colorIcon}/>
                UBICACIÓN
            </LinkScroll>
            <a css={linkCss} href={`https://api.whatsapp.com/send?phone=51${linkWsp}`} target="_blank" rel="noreferrer">
                <Icon type="whatsapp" styleEle={iconCss} color={colorIcon}/>
                WHATSAPP
            </a>
        </div>
    )
}


UbicacionWhatsapp.defaultProps = {
    linkWsp: "913062358",
    colorFondo: "black",
    colorIcon: "white"
}