import React from 'react';
import Carousel from "../../organisms/Carousel/Carousel";
import { graphql, Link } from "gatsby";
import {css} from '@emotion/react';
import Image from '../../atoms/Image/Image';

export default function GaleriaEntregado({galeria}) {


  const sectionCss = css`
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(52 / var(--width_base))));
    }
  `;

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <svg role="img" aria-labelledby="slider icono flecha izquierda" width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 1L2 16.5L17.5 32" stroke="white" stroke-width="2"/>
      </svg>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <svg role="img" aria-labelledby="slider icono flecha derecha" width="18" height="33" viewBox="0 0 18 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 32L16.5 16.5L1 0.999999" stroke="white" stroke-width="2"/>
      </svg>
    </button>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          dots: false
        }
      }
    ]
  }

  const imagenCss = css`
    height: calc(100vw * (calc(840 / var(--width_base))));
    object-fit: cover;
    @media (max-width: 767px){
      height: calc(100vw * (calc(345 / var(--width_base))));
    }
  `;

  const carousel = css`
    .slick-dots{
      bottom: calc(100vw * (calc(43 / var(--width_base))));
      
      & li{
        width: calc(100vw * (calc(29 / var(--width_base))));
        height: calc(100vw * (calc(29 / var(--width_base))));
        border-radius: 50%;
      }
      
      & li:not(.rounded){
        border: calc(100vw * (calc(3 / var(--width_base)))) solid white;
      }

      & li.slick-active{
        background-color: white;
      }
      & button::before{
        display: none;
      }
    }
    @media (max-width: 767px){
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
        .slick-arrow{
        :before{
          content: '';
        }}
        .slick-prev{
          left: calc(100vw * (calc(25 / var(--width_base))));
          z-index: 1;
        }
        .slick-next{
          right: calc(100vw * (calc(25 / var(--width_base))));
        }
      }
  `;

  return (
    <section css={sectionCss}>
      <Carousel settings={settings} styleCss={carousel}>
        {
        galeria && galeria.map((dato, i) => (
          <div key={i}>
            {
              dato?.link?.uri ?
                <Link to={dato?.link?.uri}>
                  {
                    dato.imagen &&
                    <Image classEle="w-full" imagen={dato.imagen} styleCss={imagenCss}/>
                  }
                </Link>
                :
                <Image classEle="w-full" imagen={dato} styleCss={imagenCss}/>
            }
          </div>
        ))
        }
      </Carousel>
    </section>
  );

}

export const queryGaleriaEntregado = graphql`
  fragment galeriaEntregado on Wordpress_Template_Entregado_Entregado{
    imagenesGaleria {
      ...imagen
    }
  }
`