import React from 'react';
import { css } from "@emotion/react"
import Image from "../../atoms/Image/Image"

export default function RendodoMapa({colorPrimario, logo}) {

  const contentLogoCss = css`
    position: relative;
    transform: translateY(-100%) translateX(-50%);
    width: calc(100vw * (calc(650 / var(--width_base))));
    z-index: 10;
  `;

  const circleCss = css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      margin: auto;
       background-color: ${colorPrimario};
      border-radius: 50%;
      z-index: 1;
      transform: translateY(calc(100vw * (calc(75 / var(--width_base)))));
  `;

  const circle1Css = css`
      width: calc(100vw * (calc(650 / var(--width_base))));
      height: calc(100vw * (calc(650 / var(--width_base))));
    opacity: .35;
      ${circleCss}
  `;

  const circle2Css = css`
      ${circleCss};
      width: calc(100vw * (calc(350 / var(--width_base))));
      height: calc(100vw * (calc(350 / var(--width_base))));
    opacity: .35;
  `;

  const circle3Css = css`
      ${circleCss};
      width: calc(100vw * (calc(100 / var(--width_base))));
      height: calc(100vw * (calc(100 / var(--width_base))));
    opacity: .35;
  `;

  const circle4Css = css`
    ${circleCss};
    width: calc(100vw * (calc(25 / var(--width_base))));
    height: calc(100vw * (calc(25 / var(--width_base))));
    
  `;

  const boxLogoCss = css`
    opacity: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(100vw * (calc(22 / var(--width_base))));
    padding: calc(100vw * (calc(18 / var(--width_base))));
    background-color: white;
    width: calc(100vw * (calc(170 / var(--width_base))));
    border-radius: calc(100vw * (calc(15 / var(--width_base))));
    box-shadow: 0 5px 15px 0 gray;
    position: relative;
    overflow: hidden;
    z-index: 1;
  `;

  const logoCss = css`
    &,& img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(134 / var(--width_base))));
    }
  `;

  return (
    <div css={contentLogoCss}>
      <div css={circle1Css}/>
      <div css={circle2Css}/>
      <div css={circle3Css}/>
      <div css={circle4Css}/>
      <div css={boxLogoCss}>
        <Image imagen={logo} styleCss={logoCss}/>
      </div>
    </div>
  );

}