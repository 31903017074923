import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import FormPiloto from "../components/templates/PilotoLosNogales/FormPiloto"
import RecorridoPiloto from "../components/templates/PilotoLosNogales/RecorridoPiloto"
import DetallePiloto from "../components/templates/PilotoLosNogales/DetallePiloto"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_PilotoLosNogales {
            pilotoLosNogales {
              ...formPiloto
              ...recorridoPiloto
              ...detallePiloto
            }
          }
        }
      }
    }
  }
`

export default function PilotoNogales({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.pilotoLosNogales

  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <FormPiloto
        imagen={template.imagenFormulario}
        colorFondo={template.colorFondo}
        info={template.infoFormulario}
        titulo={template.tituloFormulario}
      />
      <RecorridoPiloto
        texto={template.textoRecorrido}
        ruta={template.recorridoUrlRecorrido}
        textoAdicional={template.textoAdicionalRecorrido}
        colorFondo={template.colorFondo}
      />
      <DetallePiloto
        imagen={template.imagenDetalle}
        colorFondo={template.colorFondo}
        titulo={template.tituloDetalle}
        textoArea={template.areasPorDepaDetalle}
        textoEntrega={template.entregaDetalle}
        textoPenthouse={template.penthousesDetalle}
        textoDormitorio={template.dormitoriosDetalle}
        textoInfo={template.textoInfoDetalle}
        colorFondoTexto={template.colorFondoTextoDetalle}
        colorFondoImagen={template.colorFondoImagenDetalle}
        colorTexto={template.colorTextoDetalle}
      />
    </LayoutGeneral>
  )
}
