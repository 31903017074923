import React from 'react';
import {css} from '@emotion/react';

export default function Title({text, styleCss, classEle, type, colorBorde, typeEle, onlyText, color}) {

  let eleCss = {};

  if(onlyText) text = text.replace(/<[^>]*>?/g, '');

  switch (type) {
    case "draft" :
      eleCss = css`
        font-family: "Lato", sans-serif;
        font-size: calc(100vw * (calc(45 / var(--width_base))));
        text-align: center;
        padding-top: calc(100vw * (calc(20 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
        padding-right: calc(100vw * (calc(10 / var(--width_base))));
        padding-left: calc(100vw * (calc(10 / var(--width_base))));
        border-top: calc(100vw * (calc(5 / var(--width_base)))) solid ${colorBorde};
        border-bottom: calc(100vw * (calc(5 / var(--width_base)))) solid ${colorBorde};
        display: inline-block;
        color: ${color ? color : 'initial'};
        @media (max-width: 767px){
          font-size: calc(100vw * (calc(26 / var(--width_base))));
          padding-top: calc(100vw * (calc(18 / var(--width_base))));
          padding-bottom: calc(100vw * (calc(18 / var(--width_base))));
          border-width: calc(100vw * (calc(3 / var(--width_base))));
        }
      `;
    break;
    default :
      eleCss = {};
    break;
  }

  const titleCss = css`
    ${eleCss};
    ${styleCss};
    color: ${color ? color : 'inherit'};
  `;

  return (
    <>
      {
        (typeEle === "h1") &&
        <h1 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: text}} />
      }
      {
        (typeEle === "h2") &&
        <h2 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: text}} />
      }
      {
        (typeEle === "h3") &&
        <h3 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: text}} />
      }
      {
        (typeEle === "h4") &&
        <h4 css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: text}} />
      }
      {
        (typeEle === "") &&
        <div css={titleCss} className={classEle} dangerouslySetInnerHTML={{__html: text}} />
      }
    </>
  );
}

Title.defaultProps = {
  text: "",
  classEle: "",
  styleCss: {},
  type: "",
  typeEle: "",
  onlyText: false,
  colorBorde: "initial"
}

