import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import ProyectoEntry from "../../organisms/ProyectoEntry/ProyectoEntry"

const GridProyectosdDeEntregados = () => {
  const data = useStaticQuery(graphql`
{
  wordpress {
    page(id: "422", idType: DATABASE_ID) {
      title
      template {
        ... on Wordpress_Template_Proyectos {
          templateName
          proyectos {
            grillaProyectos {
              ... on Wordpress_Proyecto {
                title
                uri
                template {
                  ... on Wordpress_Template_Proyecto {
                    proyecto {
                      textoParaHomeDormitoriosGeneral
                    }
                  }
                }
                featuredImage {
                  node {
                    ...imagen
                  }
                }
                distritos {
                  nodes {
                    name
                  }
                }
                dormitorios {
                  nodes {
                    name
                  }
                }
                etapas {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
  `)

  const imageCss = css`
    width: 100%;
    height: calc(100vw * (calc(412 / var(--width_base))));
    @media (max-width: 767px){
      height: calc(100vw * (calc(345 / var(--width_base))));
    }
  `;

  const cardDraftCss = css`
    &.grid-25{
      @media (max-width: 1024px) and (min-width: 768px) {
        width: 50% !important;
        margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
      }
      @media (max-width: 767px){
        padding-left: 0;
        padding-right: 0;
      }
    }
    
  `;


  const proyectos = data?.wordpress?.page?.template?.proyectos?.grillaProyectos;

  return (
    <>
      {
        proyectos && proyectos.map((proyecto,i)=>(
          <>
            {
              (i <= 3) &&
                <div className="grid-25" css={cardDraftCss}>
                  <ProyectoEntry proyecto={proyecto} styleImageEle={imageCss}/>
                </div>
              // <Link className="grid-25" css={proyectoCss} to={proyecto.uri}>
              //   <Image imagen={proyecto.featuredImage.node} styleCss={imageCss}/>
              // </Link>
            }
          </>
        ))
      }
    </>
  )
}

export default GridProyectosdDeEntregados