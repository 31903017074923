import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import Title from "../../atoms/Title/Title"

export default function TitleProyecto({styleCss}) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "422", idType: DATABASE_ID) {
          template {
            ... on Wordpress_Template_Proyectos {
              proyectos {
                tituloConoceTodo
              }
            }
          }
        }
      }
    }
  `)

  const titulo = data?.wordpress?.page?.template?.proyectos?.tituloConoceTodo;

  return (
    <Title typeEle="h2" text={titulo} styleCss={styleCss} />
  );

}