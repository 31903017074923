import React, { useState } from 'react';
import MenuLink from "../MenuLink/MenuLink"
import HijosMenuItem from '../Utilities/HijosMenuItem';

export const CreateLocalLink = (menuItem, wordPressUrl) => {
  const { url } = menuItem;

  if (url === '#' || url === null || url === undefined) {
    return '';
  }
  // let newUri = url.replace(wordPressUrl, '');
  let newUri = url.replace(wordPressUrl, '');
  return newUri;
};

export default function MenuItem({ menuItem, wordPressUrl, classEle }) {
  const menuItemsChildrens = menuItem.childItems
  const [heightListSubMenu, setHeightListSubMenu] = useState(0);
  const handleClickSubMenu = (event) =>{
    event.stopPropagation();
    event.preventDefault();
    setHeightListSubMenu((heightListSubMenu === 0) ? "auto" : 0);
  }
  return (
    <li className={`${classEle ? classEle: 'menu__item'}`}>
      <MenuLink to={CreateLocalLink(menuItem, wordPressUrl,)}>
        {menuItem.label}
        {
          (menuItemsChildrens?.edges?.length) ?
            <button type="button" onClick={handleClickSubMenu}><i role="button" aria-label="Icono Boton Toggle" className={`fas fa-${heightListSubMenu ? 'minus' : 'plus'}`}/></button>
          :
          <></>
        }
      </MenuLink>
      {
        (menuItemsChildrens?.edges?.length) ?
          <HijosMenuItem menuItems={menuItemsChildrens} wordPressUrl={wordPressUrl} heightListSubMenu={heightListSubMenu}/>   
        :
        <></>
      }
    </li>
  );

}