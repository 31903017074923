import React from 'react';
import { css } from "@emotion/react"
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function NavStep({dataNav, numActives}) {

  const navItemCss = css`
    display: inline-flex;
    align-items: center;
    &:not(:last-of-type){
      margin-right: calc(100vw * (calc(30 / var(--width_base))));
    }
    &.is-active .step-icon{
      background-color: #e39a00;
      color: white;
    }
    
    & .step-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ddd;
      width: calc(100vw * (calc(40 / var(--width_base))));
      height: calc(100vw * (calc(40 / var(--width_base))));
      margin-right: calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(13 / var(--width_base))));
      line-height: calc(100vw * (calc(13 / var(--width_base))));
    }
  `;

  const navCss = css`
    padding-top: calc(100vw * (calc(15/ var(--width_base))));
    padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
    border-top: calc(100vw * (calc(1 / var(--width_base)))) solid #ddd;
    border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid #ddd;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    margin-left: calc(100vw * (calc(13 / var(--width_base))));
    margin-right: calc(100vw * (calc(13 / var(--width_base))));
  `;

  return (
    <div css={navCss}>
      {
        dataNav && dataNav.map((item,i)=>(
          <div key={i} css={navItemCss} className={((i+1) <=numActives) ? 'is-active': ''}>
            <span className="step-icon">{item.id}</span>
            <Paragraph text={item.text}/>
          </div>
        ))
      }
    </div>
  );

}