import React from 'react';
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { graphql, Link } from "gatsby"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { css } from "@emotion/react"

export default function InfoPrograma({link,texto,textoLink,textoTerminos}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(52 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(82 / var(--width_base))));
    @media (max-width: 767px){
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      }
  `;

  const textCss = css`
      font-size: calc(100vw * (calc(21 / var(--width_base))));
      line-height: calc(100vw * (calc(28 / var(--width_base))));
      font-family: "Gotham Book", sans-serif;
      font-weight: normal;
      @media (max-width: 767px){
        font-size: calc(100vw * (calc(16 / var(--width_base))));
        line-height: calc(100vw * (calc(16 / var(--width_base))));
      }
  `;

  const linkCss = css`
    color: #29ABE2;
    ${textCss}
  `;

  const contentCss = css`
    text-align: center;
  `;

  return (
    <SectionBackground widthContent={1200} styleCss={sectionCss} styleCssContent={contentCss}>
      <Paragraph text={texto} styleCss={textCss}/>
      <div css={textCss}>{textoTerminos} <Link to={link} css={linkCss}>{textoLink}</Link></div>
    </SectionBackground>
  );

}

export const queryInfoPrograma = graphql`
  fragment infoPrograma on Wordpress_Template_ProgramaDeReferidos_Programadereferidos{
    textoLinkFormulario
    textoFormulario
    textoTerminosFormulario
    linkFormulario {
      ... on Wordpress_Page {
        uri
      }
    }
  }
`