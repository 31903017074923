import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import {css} from '@emotion/react';
import Button from "../../atoms/Button/Button"
import { scroller,Element } from "react-scroll"
import Title from "../../atoms/Title/Title"

export default function BannerArmando({titulo,texto,botonTexto, imagen,colorTexto}) {

  const titleCss = css`
    color: ${colorTexto};
    font-size: calc(100vw * (calc(54 / var(--width_base))));
    margin-top: calc(100vw * (calc(100 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: 767px) {
      font-weight: 600;
      margin-bottom: calc(100vw * (calc(36 / var(--width_base))));
      margin-top: calc(100vw * (calc(180 / var(--width_base))));
      line-height: calc(100vw * (calc(75 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    font-style: italic;
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
  `;

  const imagenCss = css`
    &, &.gatsby-image-wrapper-constrained img{
      position: absolute;
      top: calc(100vw * (calc(-100 / var(--width_base))));
      right: calc(100vw * (calc(-60 / var(--width_base))));
      display: block;
      width: calc(100vw * (calc(300 / var(--width_base))));
      object-fit: contain;
    }
    @media (max-width: 767px) {
      top: calc(100vw * (calc(-755 / var(--width_base))));
      right: calc(100vw * (calc(0 / var(--width_base))));
      z-index: -1;
      height: calc(100vw * (calc(610/ var(--width_base))));
    }
  `;

  const contentLeftCss = css`
    padding-left: calc(100vw * (calc(100 / var(--width_base))));
    @media (max-width: 767px) {
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const sectionCss = css`
    padding-bottom: calc(100vw * (calc(100 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(150 / var(--width_base))));
  `;


  const hendleClick = ()=>{
    scroller.scrollTo('infografia', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuint',
      offset: (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)
    })
  }

  return (
    <SectionBackground widthContent={1200} styleCss={sectionCss}>
      <Element name="banner"/>
      <div className="grid-50" css={contentLeftCss}>
        <Title classEle="d-none" typeEle="h2" text={titulo} onlyText={true}/>
        <Title text={titulo} styleCss={titleCss}/>
        <Paragraph text={texto} styleCss={textCss}/>
        <Button styleType="outline-small" type="button" color={colorTexto} eventEle={hendleClick}>{botonTexto}</Button>
      </div>
      <div className="grid-50 text-right h-0 only-desk">
        <Image imagen={imagen} styleCss={imagenCss}/>
      </div>
    </SectionBackground>
  );

}

export const queryBannerArmando = graphql`
  fragment bannerArmando on Wordpress_Template_Armando_Armando{
    botonTextoBanner
    colorTextoBanner
    imagenBanner {
      ...imagen
    }
    tituloBanner
    textoBanner
  }
`