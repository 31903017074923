import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { css } from "@emotion/react"

export default function DuenoForm({bgColorInput,bgColorSubmit}) {

  const [envio, setEnvio] = useState("");

  const apellidosCss = css`
    padding-left: calc(100vw * (calc(8 / var(--width_base))));
    padding-right: 0;
    @media (max-width: 767px){
      padding-left: calc(100vw * (calc(0 / var(--width_base))));
    }
  `;

  const formCss = css`
    & .form-group{
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-error{
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-control{
      background-color: #F1EFE5;
      padding: calc(100vw * (calc(6 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))));
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ccc;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border-radius: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  const submitCss = css`
    background-color: #C7C7C7;
    padding: calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base)))) calc(100vw * (calc(8 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    line-height: calc(100vw * (calc(12 / var(--width_base))));
    width: 100%;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    display: inline-block;
    @media (max-width: 767px){
      padding: calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base)))) calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  return (
    <section>
      <Formik
        initialValues={{
          Email: '',
          Nombre: '',
          Celular: '',
          Apellido: '',
          TipoProyecto: '',
          Turno: '',
          Dia: '',}}
        validate={values => {
          const errors = {};

          if (!values.Nombre) {
            errors.Nombre = 'Campo requerido';
          }

          if (!values.Apellido) {
            errors.Apellido = 'Campo requerido';
          }

          if (!values.Celular) {
            errors.Celular = 'Campo requerido';
          }

          if (!values.Email) {
            errors.Email = 'Campo requerido';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          ) {
            errors.Email = 'Correo Invalido';
          }
          if (!values.TipoProyecto) errors.TipoProyecto = 'Campo requerido';
          if (!values.Dia) errors.Dia = 'Campo requerido';
          if (!values.Turno) errors.Turno = 'Campo requerido';
          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{
            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/16256/feedback`, {
              method: 'POST',
              body:
                'Nombre=' + values.Nombre +
                '&Apellido=' + values.Apellido +
                '&Celular=' + values.Celular +
                '&Email=' + values.Email +
                '&Proyecto=' + values.TipoProyecto +
                '&Dia=' + values.Dia +
                '&Turno=' + values.Turno,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-60 form-group grid-x-0">
              <Field className="form-control" type="text" name="Nombre" placeholder="Nombre*" />
              <ErrorMessage className="form-error" name="Nombre" component="div" />
            </div>
            <div className="grid-40 form-group" css={apellidosCss}>
              <Field className="form-control" type="text" name="Apellido" placeholder="Apellido*"/>
              <ErrorMessage className="form-error" name="Apellido" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="tel" name="Celular" placeholder="Celular*" />
              <ErrorMessage className="form-error" name="Celular" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="text" name="Email" placeholder="Correo*" />
              <ErrorMessage className="form-error" name="Email" component="div" />
            </div>
            <div className="grid-100 grid-x-0 form-group">
              <Field name="TipoProyecto" as="select" className="form-control select-gris">
                <option value="">Quiero saber más sobre este proyecto:</option>
                <option value="Ugarte y Moscoso">Ugarte y Moscoso</option>
                <option value="Machaypuito 160">Machaypuito 160</option>
                <option value="Toribio Polo 322">Toribio Polo 322</option>
                <option value="Pasaje Dos de Mayo">Pasaje Dos de Mayo</option>
                <option value="Edificio Libertad">Edificio Libertad</option>
                <option value="Pasaje Laureles">Pasaje Laureles</option>
              </Field>
              <ErrorMessage className="form-error" name="TipoProyecto" component="div" />
            </div>
            <div className="grid-50 form-group grid-x-0">
              <Field name="Dia" as="select" className="form-control select-gris">
                <option value="">Día:</option>
                <option value="17">7</option>
                <option value="18">8</option>
              </Field>
              <ErrorMessage className="form-error" name="Dia" component="div" />
            </div>
            <div className="grid-50 form-group" css={apellidosCss}>
              <Field name="Turno" as="select" className="form-control select-gris">
                <option value="">Turno:</option>
                <option value="Mañana">Mañana</option>
                <option value="Tarde">Tarde</option>
              </Field>
              <ErrorMessage className="form-error" name="Turno" component="div" />
            </div>
            <div className="grid-100 grid-x-0">
              <button className="" type="submit" disabled={isSubmitting} css={submitCss}>Enviar</button>
            </div>
            { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
            { (envio === true && envio !== "") && <div css={css`color: white;margin-top: calc(100vw * (calc(10 / var(--width_base))))`}>Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}
