import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import Carousel from "../../organisms/Carousel/Carousel"
import Image from "../../atoms/Image/Image"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function BannerMovilInicio({ slider }) {

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }

  const carousel = css`
    .slick-dots {
      left: 0;
      right: 0;
      bottom: calc(100vw * (calc(30 / var(--width_base))));
      text-align: center;

      & li {
        width: calc(100vw * (calc(15 / var(--width_base))));
        height: calc(100vw * (calc(15 / var(--width_base))));
        border-radius: 50%;
        @media (max-width: 767px) {
          width: calc(100vw * (calc(22 / var(--width_base))));
          height: calc(100vw * (calc(22 / var(--width_base))));
        }
      }

      & li:not(.rounded) {
        border: calc(100vw * (calc(1 / var(--width_base)))) solid;
      }

      & li.slick-active {
        background-color: black;
      }

      & button::before {
        display: none;
      }
    }
  `

  const boxCss = css`
    padding-top: calc(100vw * (calc(20 / var(--width_base))));
    padding-left: calc(100vw * (calc(15 / var(--width_base))));
    padding-right: calc(100vw * (calc(15 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(81 / var(--width_base))));
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    //margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
  `

  const fechaCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    font-weight: 400;
  `

  const buttonCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    //border: calc(100vw * (calc(1 / var(--width_base)))) solid black;
    padding-right: calc(100vw * (calc(26 / var(--width_base))));
    padding-left: calc(100vw * (calc(26 / var(--width_base))));
    padding-top: calc(100vw * (calc(5 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(5 / var(--width_base))));
  `

  const textCss = css`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  `

  const imageOnlyCss = css`
    height: calc(100vw * (calc(712 / var(--width_base))));
    width: 100%;
    object-fit: cover;
  `

  return (
    <section className="only-mov">
      <Carousel styleCss={carousel} settings={settings}>
        {
          slider && slider.map((dato, i) => (
            <div key={i}>
              <Link to={dato?.link ? dato?.link : "/"}>
                {
                  (dato?.tipoBanner === "banner-barra-lateral") ?
                    <>
                      {
                        dato?.imagenFondoMovil &&
                        <>
                          {
                            dato.imagenFondoMovil &&
                            <Image classEle="w-full" imagen={dato.imagenFondoMovil} />
                          }
                          <div css={css`${boxCss};background-color: ${dato.colorFondo}`}>
                            {
                              dato.texto &&
                              <Paragraph
                                text={dato.texto}
                                styleCss={css`
                                  ${titleCss};
                                  color: ${dato.colorTexto};
                                `} />
                            }
                            <div css={textCss}>
                              {dato.fecha ?
                                <Title text={dato.fecha} styleCss={fechaCss} />
                                :
                                <span />
                              }
                              {
                                dato.textoBoton &&
                                <span css={css`
                                  ${buttonCss};
                                  color: ${dato.colorTextoBoton};
                                  background-color: ${dato.colorFondoBoton};
                                `}>
                            {dato.textoBoton}
                          </span>
                              }
                            </div>
                          </div>
                        </>

                      }
                    </>
                    :
                    <Image classEle="w-full" imagen={dato.imagenFondoMovil} objectFit="cover" styleCss={imageOnlyCss} />
                }

              </Link>
            </div>
          ))
        }
      </Carousel>
    </section>
  )
}
