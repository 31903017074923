import React from 'react';
import Image from "../../atoms/Image/Image"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { css } from "@emotion/react"
import Button from "../../atoms/Button/Button"
import Icon from "../../atoms/Icon/Icon"

export default function GraciasProyecto({imagen, tituloProyecto,titulo,subtitulo,texto,pdfBrochure,pdfPlanos, colorLineas, colorFondoBotones}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(70 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(35 / var(--width_base))));
    line-height: calc(100vw * (calc(35 / var(--width_base))));
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
  `;

  const boxTitleCss = css`
    border-top: calc(100vw * (calc(3 / var(--width_base)))) solid ${colorLineas};
    border-bottom: calc(100vw * (calc(3 / var(--width_base)))) solid ${colorLineas};
    margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    padding-top: calc(100vw * (calc(27 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(27 / var(--width_base))));
    max-width: calc(100vw * (calc(430 / var(--width_base))));
  `;

  const subtitleCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));      
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    @media (max-width: 767px) {
        font-size: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const contentRightCss = css`
    padding-left: calc(100vw * (calc(45 / var(--width_base))));
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Gotham", sans-serif;
    @media (max-width: 767px) {
          padding-right: calc(100vw * (calc(20 / var(--width_base))));
          padding-left: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    font-weight: 600;
  `;

  const contentCss = css`
    display: flex;
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
  `;

  const imagenCss = css`
    height: 100%;
  `;

  return (
    <SectionBackground widthContent={950} styleCss={sectionCss} styleCssContent={contentCss}>
      <div className="grid-40 grid-x-0">
        {imagen && <Image imagen={imagen} styleCss={imagenCss}/>}
      </div>
      <div className="grid-60" css={contentRightCss}>
        <div css={boxTitleCss}>
          {titulo && <Paragraph text={titulo} styleCss={titleCss} />}
          {subtitulo && <Paragraph text={subtitulo} styleCss={subtitleCss}/>}
        </div>
        {texto && <Paragraph text={texto} styleCss={textCss}/>}
        <div>
          {
            pdfBrochure &&
            <Button link={pdfBrochure} type="pdf" nombre={`Brochure ${tituloProyecto}`} styleType="normal" color="black" typePdf="brochure">
              <Icon type="brochure" color={colorLineas}/>
              Descargar Brochure
            </Button>
          }
          {
            pdfPlanos && <Button link={pdfPlanos} type="pdf" nombre={`Planos ${tituloProyecto}`} styleType="normal" color="black" typePdf="plano">
              <Icon type="planos" color={colorLineas}/>
              Descargar Planos
          </Button>}
        </div>
      </div>
    </SectionBackground>
  );

}
