import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import { graphql} from "gatsby"
import {css} from '@emotion/react';
import { scroller,Element } from "react-scroll"

export default function VidaArmando({imagen}) {

  const hendleClick = (event)=>{
    event.preventDefault();
    scroller.scrollTo('banner', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuint',
      offset: (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)
    })
  }

  const sectionCss = css`
    padding-top: calc(100vw * (calc(80 / var(--width_base))));
  `;

  const linkCss = css`
    width: calc(100vw * (calc(54 / var(--width_base))));
    height: calc(100vw * (calc(54 / var(--width_base))));
    background-color: #4D4D4D;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: calc(100vw * (calc(10 / var(--width_base))));
    opacity: .5;
    transform: rotate(180deg);
    margin-top: calc(100vw * (calc(40 / var(--width_base))));
  `;

  return (
    <SectionBackground widthContent={1000} styleCss={sectionCss}>
      <Element name="infografia"/>
      <Image imagen={imagen} classEle="w-full"/>
      <div className="text-center">
        <button type="button" css={linkCss} onClick={hendleClick}>
          <svg role="img" aria-labelledby="icono flecha hacia arriba" width="60" height="33" viewBox="0 0 60 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 3L30 30L57 3" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </SectionBackground>
  );

}

export const queryVidaArmando = graphql`
  fragment vidaArmando on Wordpress_Template_Armando_Armando{
      imagenVida {
        ...imagen
      }
  }
`