import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"
import Title from "../../atoms/Title/Title"
import { graphql } from "gatsby"
import { css } from "@emotion/react"

export default function DetallePiloto({colorTexto, colorFondo,colorFondoTexto, colorFondoImagen,titulo, textoArea, textoDormitorio, textoEntrega, textoPenthouse, imagen, textoInfo}) {

  const contentCss = css`
    display: flex;
    height: calc(100vw * (calc(1150 / var(--width_base))));
    @media (max-width: 767px) {
        flex-wrap: wrap-reverse;
        height: auto;
    }
  `;

  const imagenCss = css`
    height: calc(100vw * (calc(596 / var(--width_base))));
    @media (max-width: 767px) {
                height: calc(100vw * (calc(305 / var(--width_base))));
            }
  `;

  const paddingCss = css`
    padding: calc(100vw * (calc(92 / var(--width_base)))) calc(100vw * (calc(66 / var(--width_base))));
    @media (max-width: 767px) {
            padding: calc(100vw * (calc(20 / var(--width_base)))) calc(100vw * (calc(30 / var(--width_base))));
        }
  `;

  const contentLeft = css`
    background-color: ${colorFondoTexto};
    color: ${colorTexto};
    ${paddingCss};
    @media (max-width: 767px) {
      width: 100%;
    }
  `;

  const contentRight = css`
    background-color: ${colorFondoImagen};
    ${paddingCss}
  `;

  const titleCss = css`
    font-family: "CircularAirLight", sans-serif;
    font-size: calc(100vw * (calc(52 / var(--width_base))));
    line-height: calc(100vw * (calc(63 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px) {
        font-size: calc(100vw * (calc(26 / var(--width_base))));
        line-height: calc(100vw * (calc(32 / var(--width_base))));
        color: #3f3d1e;
        margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const itemTextCss = css`
    font-size: calc(100vw * (calc(37 / var(--width_base))));
    line-height: calc(100vw * (calc(54 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const itemTitleCss = css`
    font-size: calc(100vw * (calc(29 / var(--width_base))));
    line-height: calc(100vw * (calc(29 / var(--width_base))));
    width: calc(100vw * (calc(170 / var(--width_base))));
    text-align: right;
    margin-right: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      width: calc(100vw * (calc(120 / var(--width_base))));
    }
  `;

  const itemCss = css`
    display: flex;
    padding-top: calc(100vw * (calc(10 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    font-weight: 400;
    align-items: center;
  `;
  const titleBottomCss = css`
      font-family: GopherDisplay-Light, sans-serif;
      font-size: calc(100vw * (calc(33/var(--width_base))));
      color: #dcd7c7;
      word-spacing: calc(100vw * (calc(90/var(--width_base))));
      position: absolute;
      bottom: calc(100vw * (calc(50/var(--width_base))));
      border-bottom: 1px solid #dcd7c7;
      padding-bottom: calc(100vw * (calc(10/var(--width_base))));
      @media (max-width: 1024px) and (min-width: 768px) {
          font-size: calc(100vw * (calc( 25/ var(--width_base))));
      }
  `;
  const titleBottomCss2 = css`
      font-family: GopherDisplay-Light, sans-serif;
      font-size: calc(100vw * (calc(33/var(--width_base))));
      color: #3f3e3c;
      word-spacing: calc(100vw * (calc(90/var(--width_base))));
      position: absolute;
      bottom: calc(100vw * (calc(50/var(--width_base))));
      border-bottom: 1px solid #3f3e3c;
      padding-bottom: calc(100vw * (calc(10/var(--width_base))));
      @media (max-width: 1024px) and (min-width: 768px) {
          font-size: calc(100vw * (calc(25 / var(--width_base))));
      }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={1653} styleCssContent={contentCss}>
      <div className="grid-50" css={contentLeft}>
        <Paragraph text={titulo} styleCss={titleCss} classEle ="only-desk"/>
        <div css={itemCss}>
          <p css={itemTitleCss}>Áreas por<br/>depa</p>
          <Paragraph text={textoArea} styleCss={itemTextCss}/>
        </div>
        <div css={itemCss}>
          <p css={itemTitleCss}>Dormitorios</p>
          <Paragraph text={textoDormitorio} styleCss={itemTextCss}/>
        </div>
        <div css={itemCss}>
          <p css={itemTitleCss}>Entrega</p>
          <Paragraph text={textoEntrega} styleCss={itemTextCss}/>
        </div>
        <div css={itemCss}>
          <p css={itemTitleCss}>Penthouses</p>
          <Paragraph text={textoPenthouse} styleCss={itemTextCss}/>
        </div>
        <Title text={textoInfo} styleCss={titleBottomCss} classEle="only-desk"/>
      </div>
      <div className="grid-50" css={contentRight}>
        <Paragraph text={titulo} styleCss={titleCss} classEle="only-mov"/>
        <Image imagen={imagen} styleCss={imagenCss} classEle="w-full"/>
        <Title text={textoInfo} styleCss={titleBottomCss2} classEle="only-desk"/>
      </div>
    </SectionBackground>
  );

}

export const queryDetallePiloto = graphql`
  fragment detallePiloto on Wordpress_Template_PilotoLosNogales_Pilotolosnogales{
    areasPorDepaDetalle
    colorFondoImagenDetalle
    colorFondoTextoDetalle
    colorTextoDetalle
    dormitoriosDetalle
    entregaDetalle
    imagenDetalle {
      ...imagen
    }
    penthousesDetalle
    textoInfoDetalle
    tituloDetalle
  }
`