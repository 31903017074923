import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Image from "../../atoms/Image/Image";
import Title from "../../atoms/Title/Title";
import {css} from "@emotion/react";

export default function OthersPage({titulo, text, imagen, bgColor}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(60 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px){
     padding-right:calc(100vw * (calc(5 / var(--width_base))));
     padding-left:calc(100vw * (calc(5 / var(--width_base))));
     padding-top: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    line-height: calc(100vw * (calc(70 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    font-weight: 400;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(36 / var(--width_base))));
      line-height: calc(100vw * (calc(50 / var(--width_base))));
      font-weight: 600;
    }
  `;

  const textCss = css`
    height: calc(100vw * (calc(350 / var(--width_base))));
    overflow: auto;
    background-color: white;
    padding: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(24 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    
    & p{
      margin-bottom: calc(100vw * (calc(17 / var(--width_base))));
    }
    @media (max-width: 767px){
      height: calc(100vw * (calc(350 / var(--width_base))));
    }
  `;

  const imageCss = css`
    //&,& img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(170 / var(--width_base))));
      object-fit: contain !important;
      margin-left: auto;
      margin-right: auto;
    //&{
    //}
  `;

  const column2Css = css`
    
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 767px){
      display :none;
    }
  `;

  return (
    <SectionBackground bgColor={bgColor} widthContent="1200" styleCss={sectionCss}>
      <div className="grid-100 grid-x-0" css={{display: 'flex', flexWrap: 'wrap'}}>
        <div className="grid-65 grid-x-0">
          <Title typeEle="h1" text={titulo} styleCss={titleCss}/>
          <Paragraph text={text} styleCss={textCss}/>
        </div>
        <div className="grid-35 grid-x-0" css={column2Css}>
          <Image imagen={imagen} styleCss={imageCss}/>
        </div>
      </div>
    </SectionBackground>
  );

}