import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerComoImaginas from "../components/templates/OthersPage/BannerComoImaginas"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_ComoImaginas {
            comoImaginas {
              tituloCuentanos
              tituloDejanos
            }
          }
        }
      }
    }
  }
`

export default function ComoImaginas({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.comoImaginas

  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <BannerComoImaginas
        titulo1={template.tituloCuentanos}
        titulo2={template.tituloDejanos}
      />
    </LayoutGeneral>
  )
}
