import * as React from "react"
import { useEffect } from "react"
import PropTypes from "prop-types"
import Header from "../Header/Header"
import FooterGeneral from "../Footer/FooterGeneral"
import { css } from "@emotion/react"
import { useStaticQuery, graphql } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import getParametersUrl from "../../../helper/getParametersUrl"

const LayoutGeneral = ({ children }) => {
  useEffect(() => {
    ;(async () => {
      await getParametersUrl()
    })()
  }, [])

  const {
    wordpress: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wordpress {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  const main = css`
    margin-top: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(0 / var(--width_base))));
    }
  `

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <div>
        <Header />
        <main css={main}>{children}</main>
        <FooterGeneral />
      </div>
    </SEOContext.Provider>
  )
}

LayoutGeneral.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutGeneral
