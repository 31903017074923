import React from 'react';
import {css} from '@emotion/react';

export default function SeccionContent({children, widthContent, styleCss}) {

  const contentCss = css`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw * (calc(${widthContent} / var(--width_base))));
    ${styleCss}
    
  `;

  return (
    <div className="grid-100 grid-x-0" css={contentCss}>
      {children}
    </div>
  );

}

SeccionContent.defaultProps = {
  children: '',
  widthContent: 'initial',
  styleCss: {}
}