import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import ObrasInicio from "../components/templates/Inicio/ObrasInicio"
import FormInicio from "../components/templates/Inicio/FormInicio"
import ConoceInicio from "../components/templates/Inicio/ConoceInicio"
import QuienInicio from "../components/templates/Inicio/QuienInicio"
import BannerInicio from "../components/templates/Inicio/BannerInicio"
import BannerMovilInicio from "../components/templates/Inicio/BannerMovilInicio"
import IntroInicio from "../components/templates/Inicio/IntroInicio"
import getUri from "../helper/getUri"
import Modal from "../components/organisms/Modal/Modal"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              activarModalHome
              activarModalProyectos
              urlModal
              imagenModal {
                ...imagen
              }
              ...bannerInicio
              ...conoceInicio
              ...quienInicio
              ...obrasInicio
              ...formInicio
              ...introInicio
            }
          }
        }
      }
    }
  }
`

export default function Inicio({ data }) {
  // if (typeof window !== "undefined") {
  //   const itemStr = storage.getItem("timeAnimation")
  //
  //   if (!itemStr) {
  //     let bodyHtml = document.querySelector("body")
  //     bodyHtml.classList.add("no-scroll")
  //     setTimeout(() => {
  //       bodyHtml.classList.remove("no-scroll")
  //     }, 6000)
  //   }
  // }

  const page = data?.wordpress?.page
  const template = page?.template?.inicio

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Modal
        active={template?.activarModalHome}
        image={template?.imagenModal}
        url={template?.urlModal}
      />
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <IntroInicio
        imagen={template?.imagenIntro}
        titulo={template?.tituloIntro}
        colorFondo={template?.colorFondoIntro}
        autor={template?.autorIntro}
      />
      <BannerInicio slider={template?.sliderBanner} />
      <BannerMovilInicio slider={template?.sliderBanner} />
      <ConoceInicio
        titulo={template?.tituloConoce}
        proyectos={template?.proyectosConoce}
      />
      <QuienInicio
        idEle={`armando-home`}
        imagenBacground={template?.imagenFondoQuienEs}
        imagenTitle={template?.tituloImagenQuienEs}
        text={template?.textoQuienEs}
        textButton={template?.botonTextoQuienEs}
        linkButton={template?.botonLinkQuienEs?.uri}
      />
      <ObrasInicio
        imagenBacground={template?.imagenFondoObrasEntregadas}
        imagenTitle={template?.tituloImagenObrasEntregadas}
        text={template?.textoObrasEntregadas}
        textButton={template?.botonTextoObrasEntregadas}
        linkButton={template?.botonLinkObrasEntregadas?.uri}
      />
      <FormInicio
        titulo={template?.tituloFormulario}
        colorFondo={template?.colorFondoFormulario}
        color={template?.colorFormulario}
      />
    </LayoutGeneral>
  )
}
