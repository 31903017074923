import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import Image from "../../atoms/Image/Image";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import { graphql } from "gatsby";
import {css} from '@emotion/react';
import { Element } from 'react-scroll';

export default function ElProyectoProyecto({colorFondo, detalle, invertir}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(110 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: 0;
      padding-bottom: 0;
    }
  `;


  const contenGridCss = css`
    display: flex;
    margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    flex-wrap: wrap;
    &:nth-last-of-type(2n){
      & .content-grid__left {
        width: 55%;
        @media (max-width: 767px){
          width: 100%;
        }
      }
      & .content-grid__right{
        width: 45%;
        padding-left: calc(100vw * (calc(60 / var(--width_base))));
        @media (max-width: 767px){
          width: 100%;
          padding-left: 0;
          padding-top: calc(100vw * (calc(15 / var(--width_base))));
        }
      }
    }

    &:nth-last-of-type(2n + 1){
      flex-direction: row-reverse;
      & .content-grid__left {
        width: 55%;
        padding-left: calc(100vw * (calc(60 / var(--width_base))));
        @media (max-width: 767px){
          width: 100%;
          padding-left: 0;
        }
      }
      & .content-grid__right{
        width: 45%;
        @media (max-width: 767px){
          width: 100%;
          padding-top: calc(100vw * (calc(15 / var(--width_base))));
        }
      }
    }
    
    & .content-grid__left,& .content-grid__right{
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  `;


  const contenGridCss2 = css`
    display: flex;
    margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    flex-wrap: wrap;
    
    &:nth-of-type(odd){
        flex-direction: row-reverse;
        & .content-grid__left {
          width: 55%;
          padding-left: calc(100vw * (calc(60 / var(--width_base))));
          @media (max-width: 767px){
            padding-left: 0;
            width: 100%;
          }
        }
        & .content-grid__right{
          width: 45%;
          @media (max-width: 767px){
            width: 100%;
            padding-top: calc(100vw * (calc(15 / var(--width_base))));
          }
        }
    }



    &:nth-of-type(even){
      & .content-grid__left {
        width: 55%;
        @media (max-width: 767px){
          width: 100%;
        }
      }
      & .content-grid__right{
        width: 45%;
        padding-left: calc(100vw * (calc(60 / var(--width_base))));
        @media (max-width: 767px){
          width: 100%;
          padding-left: 0;
          padding-top: calc(100vw * (calc(15 / var(--width_base))));
        }
      }
    }

    & .content-grid__left,& .content-grid__right{
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  `;

  const textoCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    font-family: "Source Sans Pro",sans-serif;
    font-style: italic;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(13 / var(--width_base))));
      padding-right: calc(100vw * (calc(13 / var(--width_base))));
    }
  `;

  const contentTextCss = css`
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    ${textoCss}
  `;

  const lineaCss = css`
    line-height: calc(100vw * (calc(70 / var(--width_base))));
    ${textoCss}
    &:nth-of-type(1){
      margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
      display: block;
    }
    &:nth-of-type(2){
      margin-top: calc(100vw * (calc(20 / var(--width_base))));
      display: block;
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={1200} styleCss={sectionCss}>
      <Element name="contenido"/>
      {detalle && detalle.map((item, i)=>(
        <div css={invertir === true ? contenGridCss2 : contenGridCss} key={i}>
          <div className="content-grid__left">
            {item.imagen && <Image imagen={item.imagen}/>}
          </div>
          <div className="content-grid__right">
            {
              item.texto &&
              <div>
                <span css={lineaCss}>----</span>
                <Paragraph text={item.texto} styleCss={contentTextCss}/>
                <span css={lineaCss}>----</span>
              </div>
            }
          </div>
        </div>
      ))}
    </SectionBackground>
  );

}

export const queryElProyectoProyecto = graphql`
  fragment elProyecto on Wordpress_Template_Proyecto_Proyecto{
    colorFondoElProyecto
    invertirDireccionElProyecto
    detalleElProyecto {
      texto
      imagen {
        ...imagen
      }
    }
  }
`