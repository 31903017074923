import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import {css} from '@emotion/react';

export default function DescripcionProyecto({titulo,colorFondo,texto,imagen, icono,textoInfo, colorPrimario, colorTitulo}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(108 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(108 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: calc(100vw * (calc(31 / var(--width_base)))); 
      padding-bottom: calc(100vw * (calc(60 / var(--width_base)))); 
    }
  `;

  const titleCss = css`
    margin-bottom: calc(100vw * (calc(76 / var(--width_base))));
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(32 / var(--width_base))));
    font-style: italic;
    
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      margin-top: calc(100vw * (calc(42 / var(--width_base))));
    }
  `;

  const alignCenterCss = css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  `;

  const contentCss = css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1){
      @media (max-width: 767px){
        order: 1;
      }
    }
    & > div:nth-of-type(2){
      @media (max-width: 767px){
        order: 3;
      }
    }
    & > div:nth-of-type(3){
      @media (max-width: 767px){
        order: 2;
      }
    }
    
  `;


  const entregaCss = css`
    display: flex;
    align-items: center;
    margin-top: calc(100vw * (calc(50 / var(--width_base))));

    & .entrega__imagen.gatsby-image-wrapper-constrained img, & .entrega__imagen{
      width: calc(100vw * (calc(32 / var(--width_base))));
      object-fit: contain;
    }
    
    & .entrega__texto{
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      font-style: italic;
      font-weight: 600;
      margin-left: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={1200} styleCss={sectionCss} styleCssContent={contentCss}>
      <div className="grid-100 mobile-grid-100 text-center">
        {titulo && <Title typeEle="h2" color={colorTitulo} text={titulo} type="draft" colorBorde={colorPrimario} styleCss={titleCss}/>}
      </div>
      <div className="grid-50" css={alignCenterCss}>
        {texto && <Paragraph text={texto} styleCss={textCss} brMov={true}/>}
        { (icono && textoInfo) && <div css={entregaCss}><Image imagen={icono} classEle="entrega__imagen" objectFit="contain"/><Paragraph text={textoInfo} classEle="entrega__texto"/></div>}
      </div>
      <div className="grid-50">
        {imagen && <Image imagen={imagen}/>}
      </div>
    </SectionBackground>
  );

}

export const queryDescripcionProyecto = graphql`
  fragment descripcionProyecto on Wordpress_Template_Proyecto_Proyecto{
    tituloDescripcion
    colorFondoDescripcion
    textoDescripcion
    imagenDescripcion {
      ...imagen
    }
    imagenIconoDescripcion {
      ...imagen
    }
    textoInfoDescripcion
  }
`