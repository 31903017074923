import React, { useRef } from "react"
import Title from "../../atoms/Title/Title"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import GoogleMapReact from 'google-map-react';
import { css } from "@emotion/react"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { Element } from "react-scroll"
import LogoMapa from "../../molecules/Mapa/LogoMapa"
import HitoMapa from "../../molecules/Mapa/HitoMapa"
import RendodoMapa from "../../molecules/Mapa/RendodoMapa"

export default function MapaProyecto({titulo,ubicaciones,logo, colorPrimario, latitud , longitud, colorTitulo}) {

  const boxLogo = useRef(null);

  const listCss = css`
    padding: calc(100vw * (calc(33 / var(--width_base)))) calc(100vw * (calc(33 / var(--width_base))));
    width: calc(100vw * (calc(280 / var(--width_base))));
    background: white;
    border-radius: calc(100vw * (calc(8 / var(--width_base))));
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 16%);
    position: absolute;
    z-index: 1;
    top: calc(100vw * (calc(100 / var(--width_base))));
    left: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px){
      position: initial;
      width: 100%;
      overflow: auto;
      text-align: center;
    }
  `;


  const listTitleCss = css`
    font-size: calc(100vw * (calc(27 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(27 / var(--width_base))));
  `;


  const ItemMap = css`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: calc(100vw * (calc(10 / var(--width_base))));
    &:hover{
      background-color: #DEDEDE;
    }
    & .map__imagen{
      flex-shrink: 0;
      margin-right: calc(100vw * (calc(15 / var(--width_base))));
      width: calc(100vw * (calc(20 / var(--width_base))));
    }
    & .map__imagen img, & .gatsby-image-wrapper-constrained img,& .map__imagen{
      width: calc(100vw * (calc(20 / var(--width_base))));
      height: calc(100vw * (calc(20 / var(--width_base))));
      object-fit: contain;
    }
    & .map__text{
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      text-transform: uppercase;
      font-style: italic;
      text-align: left;
      @media (max-width: 767px){
        text-align: left;
      }
    }

    @media (max-width: 767px){
      height: calc(100vw * (calc(60 / var(--width_base))));
      width: 50%;
      float: left;
    }
  `;

  const contentMapCss = css`
    height: 100vh;
    width: 100%;
    min-height: calc(100vw * (calc(900 / var(--width_base))));
    @media (max-width: 767px){
      height: auto;
    }

    & > div:nth-of-type(2){
      @media (max-width: 767px){
        height: calc(100vw * (calc(800 / var(--width_base)))) !important;
      }
    }
  `;

  const createMapOptions = () =>{
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: [{ stylers: [{ 'saturation': -100 }] }]
    }
  }

  const onClickHitoShow = (index) =>{
    const todosHitos = document.querySelectorAll(".hitoMapa");
    if(todosHitos.length !== 0){
      todosHitos.forEach((ele)=>{
        ele.classList.add("is-hidden");
      });
    }
    const hitoActual = document.getElementById(`hitoMapa${index}`);
    hitoActual && hitoActual.classList.remove("is-hidden");
  }

  const onClickHitoTodos = () =>{
    const todosHitos = document.querySelectorAll(".hitoMapa");
    if(todosHitos.length !== 0){
      todosHitos.forEach((ele)=>{
        ele.classList.remove("is-hidden");
      });
    }
    boxLogo.current.classList.remove("is-hidden");
  }

  const seccionCss = css`
    position: relative;
  `;

  return (
    <section css={seccionCss}>
      <Element name="mapa"/>
      <div css={contentMapCss}>
        <div css={listCss}>
        <Title typeEle="h2" text={titulo} color={colorTitulo} type="draft" colorBorde={colorPrimario} styleCss={listTitleCss}/>
          {ubicaciones && ubicaciones.map((ubicacion,i)=>(
            <button key={i} css={ItemMap} type="button" onClick={() => onClickHitoShow(i)}>
              <Image imagen={ubicacion.icono} classEle="map__imagen" objectFit="contain"/>
              <Paragraph text={ubicacion.titulo} classEle="map__text"/>
            </button>
          ))}
          <button css={ItemMap} type="button" onClick={onClickHitoTodos}>
            <Paragraph text="Ver todo" classEle="map__text"/>
          </button>
        </div>

        {
          (latitud && longitud) &&
          <GoogleMapReact
            bootstrapURLKeys={{ key:  'AIzaSyBcz1TJ3GOskg6R4-84JWbMXUyK-v1o-XY'}}
            defaultCenter={{ lat: parseFloat(latitud), lng: parseFloat(longitud) }}
            defaultZoom={17}
            defaultOptions={{ styles: { 'saturation': -100 } }}
            options={createMapOptions}

          >
            <RendodoMapa
              lat={parseFloat(latitud)}
              lng={parseFloat(longitud)}
              colorPrimario={colorPrimario}
              logo={logo}
            />
            <LogoMapa
              lat={parseFloat(latitud)}
              lng={parseFloat(longitud)}
              logo={logo}
              refEle={boxLogo}
            />
            {ubicaciones && ubicaciones.map((ubicacion,i)=>(
              <HitoMapa
                keyEle={i}
                icon={ubicacion.icono}
                lat={parseFloat(ubicacion.latitud)}
                lng={parseFloat(ubicacion.longitud)}
                classEle="hitoMapa"
              />
            ))}

          </GoogleMapReact>
        }
      </div>
    </section>
  );

}


export const queryEligeProyecto = graphql`
  fragment mapaProyecto on Wordpress_Template_Proyecto_Proyecto{
    tituloMapa
    longitudMapa
    latitudMapa
    logoMapa {
      ...imagen
    }
    ubicaciones {
      latitud
      longitud
      titulo
      icono {
        ...imagen
      }
    }
  }
`
