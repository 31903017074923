import React from "react"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import { css } from "@emotion/react"
import ProximamenteForm from "../../organisms/Form/ProximamenteForm"
// import ProximamenteFormToribio from "../../organisms/Form/ProximamenteFormToribio"
// import ProximamenteFormPasajeUgarte from "../../organisms/Form/ProximamenteFormPasajeUgarte"
// import ProximamenteFormParqueIncario from "../../organisms/Form/ProximamenteFormParqueIncario"

export default function BannerProximamente({
  colorFondo,
  imagen,
  titulo,
  proyectoId,
  urlGracias,
}) {
  const seccionCss = css`
    padding-top: calc(100vw * (calc(90 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(60 / var(--width_base))));
    }
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    color: #555555;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(25 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const gridRightCss = css`
    padding-left: calc(100vw * (calc(70 / var(--width_base))));
    padding-top: calc(100vw * (calc(90 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
    }
  `

  const gridLeftCss = css`
    padding-right: calc(100vw * (calc(80 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `

  return (
    <SectionBackground
      widthContent={1226}
      styleCss={seccionCss}
      bgColor={colorFondo}
    >
      <div className="grid-50 mobile-grid-100" css={gridLeftCss}>
        <Image imagen={imagen} />
      </div>
      <div className="grid-50 mobile-grid-100" css={gridRightCss}>
        <Paragraph text={titulo} styleCss={titleCss} />
        <ProximamenteForm proyectoId={proyectoId} urlGracias={urlGracias} />
        {/* {idFormulario === 18358 && ( */}
        {/*   <ProximamenteFormPasajeUgarte idForm={idFormulario} /> */}
        {/* )} */}
        {/**/}
        {/* {idFormulario === 11022 && ( */}
        {/*   <ProximamenteFormToribio idForm={idFormulario} /> */}
        {/* )} */}
        {/**/}
        {/* {idFormulario === 18584 && ( */}
        {/*   <ProximamenteFormParqueIncario idForm={idFormulario} /> */}
        {/* )} */}
      </div>
    </SectionBackground>
  )
}

