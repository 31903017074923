import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import TextArmando from "../../organisms/GroupText/TextArmando"

export default function NuestrosArmando({colorFondo, titulo, texto}) {

  const contentCss = css`
    background-color:  ${colorFondo};
  `

  return (
    <SectionBackground widthContent={1200} styleCssContent={contentCss}>
      <TextArmando titulo={titulo} texto={texto} color_titulo="#343434"/>
    </SectionBackground>
  );

}

export const queryNuestrasArmando = graphql`
  fragment nuestrosArmando on Wordpress_Template_Armando_Armando{
    colorFondoNuestros
    tituloNuestros
    textoNuestros
  }
`