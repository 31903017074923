import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import Title from "../../atoms/Title/Title"

export default function BannerEntregados({colorFondo, titulo, texto, imagen}) {

  const titleCss = css`
    font-weight: 600;
    font-family: "Lato", sans-serif;
    font-size: calc(100vw * (calc(88 / var(--width_base))));
    line-height: calc(100vw * (calc(105 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(45 / var(--width_base))));
      line-height: calc(100vw * (calc(54 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(37 / var(--width_base))));
    }
  `;

  const textCss = css`
    line-height: calc(100vw * (calc(28 / var(--width_base))));
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    font-family: "Lato", sans-serif;
    @media (max-width: 767px){
      font-style: italic;
    }
  `;

  const sectionCss = css`
    //padding-top: calc(100vw * (calc(240 / var(--width_base))));
    //padding-bottom: calc(100vw * (calc(167 / var(--width_base))));
    padding-left: calc(100vw * (calc(80 / var(--width_base))));
    padding-right: calc(100vw * (calc(80 / var(--width_base))));
    height: calc(100vh - calc(100vw * (calc(70 / var(--width_base)))));
    min-height: calc(100vw * (calc(600 / var(--width_base))));
    overflow: hidden;
    
    @media (max-width: 767px){
      height: initial;
      min-height: initial;
      padding-top: calc(100vw * (calc(33 / var(--width_base))));
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(49 / var(--width_base))));
    }
  `;

  const imageCss = css`
    &, & img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(672 / var(--width_base)))) !important;
      height: calc(100vw * (calc(509 / var(--width_base)))) !important;
      @media (max-width: 767px){
        width: calc(100vw * (calc(177 / var(--width_base)))) !important;
        height: calc(100vw * (calc(133 / var(--width_base)))) !important;
      }
    }
    @media (max-width: 767px){
      margin-top: calc(100vw * (calc(6 / var(--width_base))));
    }
  `;

  const contentCss = css`
    @media (min-width: 768px){
      display: flex;
      align-items: center;
      height: 100%;
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} styleCss={sectionCss} styleCssContent={contentCss}>
      <div className="grid-60">
        <Title classEle="d-none" typeEle="h2" onlyText={true} text={titulo}/>
        <Paragraph text={titulo} styleCss={titleCss}/>
        <Paragraph text={texto} styleCss={textCss} brMov={true}/>
      </div>
      <div className="grid-40">
        <Image imagen={imagen} styleCss={imageCss}/>
      </div>
    </SectionBackground>
  );

}

export const queryBannerEntregados = graphql`
  fragment bannerEntregados on Wordpress_Template_Entregados_Entregados{
    imagenBanner {
      ...imagen
    }
    colorFondoBanner
    textoBanner
    tituloBanner
  }
`