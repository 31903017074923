import React from 'react';
import OthersPage from "./OthersPage"
import { graphql } from "gatsby"

export default function BannerProtocolo({titulo, text, bgColor, imagen}) {

  return (
    <OthersPage
      bgColor={bgColor}
      titulo={titulo}
      text={text}
      imagen={imagen}
    />
  );

}

export const queryBannerPolitica = graphql`
  fragment bannerProtocolo on Wordpress_Template_Protocolo_Protocolo{
    colorFondo
    texto
    imagen {
      ...imagen
    }
  }
`