import * as React from "react"
import Seo from "../components/atoms/Seo/Seo"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import {css} from "@emotion/react";
import SectionBackground from "../components/molecules/SectionBackground/SectionBackground"
import Paragraph from "../components/atoms/Paragraph/Paragraph"

export default function GraciasLibroReclamaciones () {

  const colorLineas = "#284b54";
  const titulo = "<p>GRACIAS POR<br>CONTACTARNOS</p>";
  const subtitulo = "NOS COMUNICAREMOS CONTIGO PRONTO";

  const sectionCss = css`
    padding-top: calc(100vw * (calc(70 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(35 / var(--width_base))));
    line-height: calc(100vw * (calc(35 / var(--width_base))));
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
  `;

  const boxTitleCss = css`
    border-top: calc(100vw * (calc(3 / var(--width_base)))) solid ${colorLineas};
    border-bottom: calc(100vw * (calc(3 / var(--width_base)))) solid ${colorLineas};
    //margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    padding-top: calc(100vw * (calc(27 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(27 / var(--width_base))));
    max-width: calc(100vw * (calc(430 / var(--width_base))));
  `;

  const subtitleCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));      
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    @media (max-width: 767px) {
        font-size: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const contentRightCss = css`
    padding-left: calc(100vw * (calc(45 / var(--width_base))));
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Gotham", sans-serif;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
          padding-right: calc(100vw * (calc(20 / var(--width_base))));
          padding-left: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  // const textCss = css`
  //   font-size: calc(100vw * (calc(20 / var(--width_base))));
  //   line-height: calc(100vw * (calc(20 / var(--width_base))));
  //   margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
  //   font-weight: 600;
  // `;

  const contentCss = css`
    display: flex;
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
  `;

  // const imagenCss = css`
  //   height: 100%;
  // `;

  return(
    <LayoutGeneral>
      <Seo title="Gracias - Libro de reclamaciones" />
      <h1 className="d-none">Gracias - Libro de reclamaciones</h1>
      <SectionBackground widthContent={950} styleCss={sectionCss} styleCssContent={contentCss}>
        <div className="grid-100" css={contentRightCss}>
          <div css={boxTitleCss}>
            {titulo && <Paragraph text={titulo} styleCss={titleCss} />}
            {subtitulo && <Paragraph text={subtitulo} styleCss={subtitleCss}/>}
          </div>
        </div>
      </SectionBackground>
    </LayoutGeneral>
  )
}
