import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
// import { StaticImage } from "gatsby-plugin-image"
import whatsapp from "../../../images/whatsApp.png"

export default function TextWhatsapp({
  iconWhatsapp,
  iconPhone,
  classEle,
  styleCss,
  type,
}) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "896", idType: DATABASE_ID) {
          title
          template {
            ... on Wordpress_Template_Opciones {
              opciones {
                linkWhatsappHeader
                nroWhatsappHeader
              }
            }
          }
        }
      }
    }
  `)

  const nro = data?.wordpress?.page?.template?.opciones?.nroWhatsappHeader
  const link = data?.wordpress?.page?.template?.opciones?.linkWhatsappHeader

  const textWhatsapp = css`
    display: inline-flex;
    align-items: center;
    ${styleCss};
    &.only-desk {
      @media (max-width: 767px) {
        display: none;
      }
    }
  `

  const textWhatsappTexto = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    white-space: nowrap;
  `

  const textWhatsappIcon = css`
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    margin-left: calc(100vw * (calc(10 / var(--width_base))));
  `

  const textPhoneIcon = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    margin-right: calc(100vw * (calc(10 / var(--width_base))));
  `

  const whatsappImagenFloatCss = css`
    width: calc(100vw * (calc(50 / var(--width_base))));
    height: calc(100vw * (calc(50 / var(--width_base))));
  `

  const whatsappLinkFloatCss = css`
    position: fixed;
    bottom: calc(100vw * (calc(28 / var(--width_base))));
    right: calc(100vw * (calc(76 / var(--width_base))));
    z-index: 1;
    @media (max-width: 767px) {
      bottom: calc(100vw * (calc(20 / var(--width_base))));
      right: calc(100vw * (calc(14 / var(--width_base))));
    }
  `

  return (
    <>
      {type === "imagen" && (
        <a
          css={whatsappLinkFloatCss}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={`${classEle} btn-wsp`}
        >
          {/*<StaticImage*/}
          {/*  src="../../../images/whatsApp.png"*/}
          {/*  formats={["auto", "webp", "avif"]}*/}
          {/*  alt="Whatsapp"*/}
          {/*  css={whatsappImagenFloatCss}*/}
          {/*/>*/}
          <img src={whatsapp} css={whatsappImagenFloatCss} alt="Whatsapp" />
        </a>
      )}
      {type === "texto" && (
        <a
          href={link}
          target="_blank"
          id="btn-wsp"
          rel="noreferrer"
          css={textWhatsapp}
          className={`${classEle} btn-wsp`}
        >
          {iconPhone && <i css={textPhoneIcon} className="fa fa-phone-alt" />}
          <span className="menu__text" css={textWhatsappTexto}>
            {nro}
          </span>
          {iconWhatsapp && (
            <i css={textWhatsappIcon} className="fab fa-whatsapp" />
          )}
        </a>
      )}
    </>
  )
}

TextWhatsapp.defaultProps = {
  iconWhatsapp: false,
  iconPhone: false,
  type: "texto",
}
