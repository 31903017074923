import React from "react";
import {css} from "@emotion/react";
import Image from "../../atoms/Image/Image";

export default function MuchoBanner({data}) {

    const sectionArmandoCss = css`
      background-color: #232222;
      font-size: calc(100vw * (calc(127 / var(--width_base))));
      line-height: calc(100vw * (calc(127 / var(--width_base))));
      font-family: "Poppins SemiBold", sans-serif;
      text-align: center;
      color: white;
      height: calc(100vw * (calc(888 / var(--width_base))));
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(32 / var(--width_base))));
        line-height: calc(100vw * (calc(32 / var(--width_base))));
        height: calc(100vw * (calc(304 / var(--width_base))));
        padding-left: calc(100vw * (calc(90 / var(--width_base))));
        padding-right: calc(100vw * (calc(90 / var(--width_base))));
      }
    `;

    const imageCss = css`
        width: calc(100vw * (calc(300 / var(--width_base))));
        position: absolute;
        bottom: calc(100vw * (calc(50 / var(--width_base)))); 
        right: calc(100vw * (calc(400 / var(--width_base)))); 
    `;

    return(
        <section css={sectionArmandoCss}>
            {
                (data?.bannerTitulo) &&
                <p>{data?.bannerTitulo}</p>
            }
            {
                (data?.bannerLogo) &&
                <Image objectFit="contain" styleCss={imageCss} imagen={data?.bannerLogo}/>
            }
        </section>    
    )
}
