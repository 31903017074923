import React from 'react';
import { graphql } from "gatsby";
import {css} from "@emotion/react";
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import InicioForm from "../../organisms/Form/InicioForm"
import Title from "../../atoms/Title/Title"
import { Element } from "react-scroll"

export default function FormInicio({titulo,colorFondo,color}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(60 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
  `;


  const titleCss = css`
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    color: ${color};
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(23 / var(--width_base))));
      line-height: calc(100vw * (calc(33 / var(--width_base))));
      letter-spacing: 0.01em;
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent="1200" styleCss={sectionCss}>
      <Element name="contacto"/>
      <Title typeEle="h2" styleCss={titleCss} text={titulo}/>
      <InicioForm color={color}/>
    </SectionBackground>
  );
}

export const queryObrasInicio = graphql`
  fragment formInicio on Wordpress_Template_Inicio_Inicio{
    tituloFormulario
    colorFondoFormulario
    colorFormulario
  }
`