import React from 'react';
import { graphql } from "gatsby"
import {css} from '@emotion/react';
import Title from "../../atoms/Title/Title"
import GridProyectosDeEntregados from "./GridProyectosDeEntregados"

export default function InformacionEntregados({titulo}) {

  const sectionCss = css`
    padding: calc(100vw * (calc(172 / var(--width_base)))) calc(100vw * (calc(67 / var(--width_base)))) calc(100vw * (calc(100 / var(--width_base)))) calc(100vw * (calc(67 / var(--width_base))));
    @media (max-width: 767px){
      padding: calc(100vw * (calc(56 / var(--width_base)))) 0;
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(40 / var(--width_base))));
    line-height: calc(100vw * (calc(50 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(56 / var(--width_base))));
    font-weight: 600;
    margin-left: calc(100vw * (calc(10 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(25 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
      margin-left: initial;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  return (
    <section className="grid-100" css={sectionCss}>
      <Title typeEle="h2" text={titulo} styleCss={titleCss}/>
      <GridProyectosDeEntregados/>
    </section>
  );

}

export const tituloConoceEntregado = graphql`
  fragment tituloConoceEntregado on Wordpress{
    page(id: "309", idType: DATABASE_ID) {
      template {
        ... on Wordpress_Template_Entregados {
          entregados {
            tituloConoceTodos
          }
        }
      }
    }
  }
`