import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { css } from "@emotion/react"
import { navigate } from "gatsby"

export default function ProyectoForm({
  colorBoton,
  apiInputChannelIds,
  apiNivelId,
  apiProjectRelated,
  apiSourceId,
  paginaGracias,
  valoresPresupuesto,
}) {
  const contentFormCss = css`
    padding-top: calc(100vw * (calc(19 / var(--width_base))));
    padding-left: calc(100vw * (calc(46 / var(--width_base))));
    padding-right: calc(100vw * (calc(46 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(39 / var(--width_base))));
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(29 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }

    & .form-control {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid #cacaca;
      padding: calc(100vw * (calc(8 / var(--width_base))));
      font-family: "Source Sans Pro", sans-serif;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      color: #cfcfcf;

      @media (max-width: 767px) {
        border-color: black;
      }

      &::placeholder {
        color: #cfcfcf;
      }
    }

    & .form-group {
      margin-top: calc(100vw * (calc(19 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(19 / var(--width_base))));

      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    & .form-group:nth-of-type(1) {
      @media (max-width: 767px) {
        order: 1;
      }
    }
    & .form-group:nth-of-type(5) {
      @media (max-width: 767px) {
        order: 2;
      }
    }
    & .form-group:nth-of-type(2) {
      @media (max-width: 767px) {
        order: 3;
      }
    }
    & .form-group:nth-of-type(6) {
      @media (max-width: 767px) {
        order: 4;
      }
    }
    & .form-group:nth-of-type(3) {
      @media (max-width: 767px) {
        order: 5;
      }
    }
    & .form-group:nth-of-type(7) {
      @media (max-width: 767px) {
        order: 7;
      }
    }
    & .form-group:nth-of-type(7) + div {
      @media (max-width: 767px) {
        order: 8;
      }
    }
    & .form-group:nth-of-type(4) {
      @media (max-width: 767px) {
        order: 9;
      }
    }
  `

  const submitCss = css`
    padding: calc(100vw * (calc(8 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))));
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    text-transform: uppercase;
    background-color: ${colorBoton};
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    margin-left: calc(100vw * (calc(30 / var(--width_base))));
    transform: translateY(calc(100vw * (calc(50 / var(--width_base)))));

    @media (max-width: 767px) {
      transform: none;
      width: 100%;
      margin-left: 0;
      margin-top: calc(100vw * (calc(23 / var(--width_base))));
      text-align: center;
      padding: calc(100vw * (calc(5 / var(--width_base))))
        calc(100vw * (calc(40 / var(--width_base))));
    }
  `

  const textAcept = css`
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    color: #4d4d4f;
    font-family: "Source Sans Pro", sans-serif;
    font-style: italic;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(9 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
    }

    & input {
      margin-right: calc(100vw * (calc(8 / var(--width_base))));
    }
  `

  const msjCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
    margin-left: calc(100vw * (calc(13 / var(--width_base))));
  `

  const [envio, setEnvio] = useState("")

  return (
    <>
      <Formik
        initialValues={{
          Nombre: "",
          Email: "",
          DistritoResidencia: "",
          Apellido: "",
          Telefono: "",
          Presupuesto: "",
          Acepto_condiciones: "",
          Acepto_envio: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombre) errors.Nombre = "Campo requerido"
          if (!values.Email) errors.Email = "Campo requerido"
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          )
            errors.Email = "Correo Invalido"
          if (!values.DistritoResidencia)
            errors.DistritoResidencia = "Campo requerido"
          if (!values.Apellido) errors.Apellido = "Campo requerido"
          if (!values.Telefono) errors.Telefono = "Campo requerido"
          if (!values.Presupuesto) errors.Presupuesto = "Campo requerido"
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = "Campo requerido"
          if (!values.Acepto_envio) errors.Acepto_envio = "Campo requerido"
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          try {
            const jsonData = {
              email: values.Email,
              fname: values.Nombre,
              lname: values.Apellido,
              address: values.DistritoResidencia,
              phone: values.Telefono,
              project_id: parseInt(apiProjectRelated),
              interest_type_id: data?.interese_type_id,
              source_id: data?.source_id,
              observation: `Presupuesto: ${values.Presupuesto}, fbc: ${data?.fbc}, fbp: ${data?.fbp}, ip_address: ${data?.ip_address}`,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: data?.utm_content,
              utm_term: data?.gclid,
              gclid: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }

            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jsonData),
            })
              .then(res => res.json())
              .then(json => {
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()
                  if (paginaGracias) navigate(paginaGracias)

                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100" css={contentFormCss}>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field
                className="form-control"
                type="text"
                name="Nombre"
                placeholder="Nombre*"
              />
              <ErrorMessage
                className="form-error"
                name="Nombre"
                component="div"
              />
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field
                className="form-control"
                type="text"
                name="Email"
                placeholder="Correo*"
              />
              <ErrorMessage
                className="form-error"
                name="Email"
                component="div"
              />
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field
                name="DistritoResidencia"
                as="select"
                className="form-control"
              >
                <option
                  value=""
                  className="placeholder"
                  disabled=""
                  selected="selected"
                >
                  Distrito de residencia
                </option>
                <option value="Cercado de Lima">Cercado de Lima</option>
                <option value="Ancón">Ancón</option>
                <option value="Ate">Ate</option>
                <option value="Barranco">Barranco</option>
                <option value="Breña">Breña</option>
                <option value="Carabayllo">Carabayllo</option>
                <option value="Chaclacayo">Chaclacayo</option>
                <option value="Chorrillos">Chorrillos</option>
                <option value="Cieneguilla">Cieneguilla</option>
                <option value="Comas">Comas</option>
                <option value="El Agustino">El Agustino</option>
                <option value="Independencia">Independencia</option>
                <option value="Jesús María">Jesús María</option>
                <option value="La Molina">La Molina</option>
                <option value="La Victoria">La Victoria</option>
                <option value="Lince">Lince</option>
                <option value="Los Olivos">Los Olivos</option>
                <option value="Lurigancho">Lurigancho</option>
                <option value="Lurín">Lurín</option>
                <option value="Magdalena del Mar">Magdalena del Mar</option>
                <option value="Miraflores">Miraflores</option>
                <option value="Pachacamac">Pachacamac</option>
                <option value="Pucusuna">Pucusuna</option>
                <option value="Pueblo Libre">Pueblo Libre</option>
                <option value="Puente Piedra">Puente Piedra</option>
                <option value="Punta Hermosa">Punta Hermosa</option>
                <option value="Punta Negra">Punta Negra</option>
                <option value="Rímac">Rímac</option>
                <option value="San Bartolo">San Bartolo</option>
                <option value="San Borja">San Borja</option>
                <option value="San Isidro">San Isidro</option>
                <option value="San Juan de Lurigancho">
                  San Juan de Lurigancho
                </option>
                <option value="San Juan de Miraflores">
                  San Juan de Miraflores
                </option>
                <option value="San Luis">San Luis</option>
                <option value="San Martin de Porres">
                  San Martin de Porres
                </option>
                <option value="San Miguel">San Miguel</option>
                <option value="Santa Anita">Santa Anita</option>
                <option value="Santa María del Mar">Santa María del Mar</option>
                <option value="Santa Rosa">Santa Rosa</option>
                <option value="Santiago de Surco">Santiago de Surco</option>
                <option value="Surquillo">Surquillo</option>
                <option value="Villa El Salvador">Villa El Salvador</option>
                <option value="Villa María del Triunfo">
                  Villa María del Triunfo
                </option>
              </Field>
              <ErrorMessage
                className="form-error"
                name="DistritoResidencia"
                component="div"
              />
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <button css={submitCss} type="submit" disabled={isSubmitting}>
                Enviar
              </button>
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field
                className="form-control"
                type="text"
                name="Apellido"
                placeholder="Apellido*"
              />
              <ErrorMessage
                className="form-error"
                name="Apellido"
                component="div"
              />
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field
                className="form-control"
                type="tel"
                name="Telefono"
                placeholder="Teléfono*"
              />
              <ErrorMessage
                className="form-error"
                name="Telefono"
                component="div"
              />
            </div>
            <div className="grid-25 mobile-grid-100 form-group">
              <Field name="Presupuesto" as="select" className="form-control">
                <option value="">Presupuesto</option>
                {valoresPresupuesto &&
                  valoresPresupuesto.map((valor, i) => (
                    <option value={valor.valor} key={i}>
                      {valor.valor}
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                className="form-error"
                name="Presupuesto"
                component="div"
              />
            </div>
            <div className="grid-100 mobile-grid-100 grid-x-0">
              <div className="grid-40">
                <label
                  className="form-acept"
                  htmlFor="Acepto_condiciones"
                  css={textAcept}
                >
                  <Field
                    className=""
                    type="checkbox"
                    name="Acepto_condiciones"
                    id="Acepto_condiciones"
                  />
                  Acepto las condiciones de&nbsp;
                  <a
                    href="/politica-de-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    política de privacidad
                  </a>
                  .
                </label>
                <ErrorMessage
                  className="form-error mov-mb-13"
                  name="Acepto_condiciones"
                  component="div"
                />
              </div>
              <div className="grid-40">
                <label
                  className="form-acept"
                  htmlFor="Acepto_envio"
                  css={textAcept}
                >
                  <Field
                    className=""
                    type="checkbox"
                    name="Acepto_envio"
                    id="Acepto_envio"
                  />
                  Acepto el envío de precios, información comercial y mails
                </label>
                <ErrorMessage
                  className="form-error mov-mb-13"
                  name="Acepto_envio"
                  component="div"
                />
              </div>
            </div>
            {envio === false && envio !== "" && (
              <div css={msjCss}>Error en el envio</div>
            )}
            {envio === true && envio !== "" && (
              <div css={msjCss}>Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}
