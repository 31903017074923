import React from 'react';
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Title from "../../atoms/Title/Title"
import Overlay from "../../organisms/Overlay/Overlay"
import { css } from "@emotion/react"
import { Link, navigate } from "gatsby"

export default function ProyectoEntry({proyecto, keyEle, styleEle, styleImageEle, linkEle}) {

  const titleOverlayCss = css`
    font-family: "Lato", sans-serif;
    font-size: calc(100vw * (calc(36 / var(--width_base))));
    line-height: calc(100vw * (calc(43 / var(--width_base))));
    
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(24 / var(--width_base))));
    }
  `;


  const textProyectoCss = css`
    font-weight: 400;
    ${titleOverlayCss}
  `;

  const textDistritoCss = css`
    font-weight: 700;
    ${titleOverlayCss};
    @media (max-width: 767px){
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;
  const linkCss = css`
    margin-top: calc(100vw * (calc(12 / var(--width_base)))); 
    ${titleOverlayCss};
  `;

  const iconOverlayCss = css`
    position: absolute;
    right: calc(100vw * (calc(57 / var(--width_base))));
    bottom: calc(100vw * (calc(60 / var(--width_base))));
    width: calc(100vw * (calc(53 / var(--width_base))));
    height: calc(100vw * (calc(53 / var(--width_base))));

    @media (max-width: 767px){
      width: calc(100vw * (calc(15 / var(--width_base))));
      height: calc(100vw * (calc(15 / var(--width_base))));
      position: initial;
      margin-left: calc(100vw * (calc(15 / var(--width_base))));     
    }

    &.icono-mov{
      @media (max-width: 767px){
        position: absolute;
        right: calc(100vw * (calc(20 / var(--width_base))));
      }
    }
  `;

  const textOverlayCss = css`
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: calc(100vw * (calc(30 / var(--width_base))));
    line-height: calc(100vw * (calc(36 / var(--width_base))));
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

    const linkOverlayCss = css`
        position: absolute;
        top: 0;
        left: calc(100vw * (calc(55 / var(--width_base))));
        bottom: 0;
        display: flex;
        margin: auto;
        width: calc(100vw * (calc(19 / var(--width_base))));
        height: calc(100vw * (calc(39 / var(--width_base))));
    `;

    const cardCss = css`
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(45 / var(--width_base))));
    `;

    const handleClickOverlay = (event, link) => {
      if(link){
        event.preventDefault();
        let ele = event.target;
        if(ele.classList.contains('is-active')){
          navigate(link);
        }else{
          if(typeof window !== "undefined"){
            let todoEle = document.querySelectorAll(".card-entry-mov");
            Array.from(todoEle).forEach((elemento)=>{
              elemento.classList.remove("is-active");
              elemento.classList.add("is-noactive");
            });
          }
          ele.classList.remove("is-noactive");
          ele.classList.add("is-active");
        }
      }
    }

    return (
      <div css={styleEle}>
        <Overlay key={keyEle} imagen={proyecto?.featuredImage?.node} link={linkEle ? linkEle : proyecto?.uri} styleCssImage={styleImageEle} bgColor={linkEle ? "rgba(0,0,0,.5)": "rgba(255,255,255,.8)"}  classEle="only-desk block">
          {proyecto?.title && <Title styleCss={textProyectoCss} text={proyecto.title}/>}
          {proyecto?.distritos?.nodes[0]?.name && <Title styleCss={textDistritoCss} text={proyecto.distritos.nodes[0].name}/>}
          {proyecto?.template?.proyecto?.textoParaHomeDormitoriosGeneral && <Paragraph text={proyecto?.template?.proyecto?.textoParaHomeDormitoriosGeneral} styleCss={textOverlayCss}/>}
          {proyecto?.template?.proyectoProximamente?.textoParaHomeDormitoriosGeneral && <Paragraph text={proyecto?.template?.proyectoProximamente?.textoParaHomeDormitoriosGeneral} styleCss={textOverlayCss}/>}
          <svg role="img" aria-labelledby="proyecto icono más" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconOverlayCss}>
            <rect x="22.8452" width="7.31035" height="53" fill="black"/>
            <rect x="53" y="22.8448" width="7.31035" height="53" transform="rotate(90 53 22.8448)" fill="black"/>
          </svg>
          {
              linkEle &&
              <svg role="img" aria-labelledby="proyecto icono más 2" css={linkOverlayCss} width="24" height="43" viewBox="0 0 24 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 2L21 21.5L2 41" stroke="white" strokeWidth="3"/>
              </svg>
          }
        </Overlay>
        <Link className="only-mov card-entry-mov is-noactive" css={cardCss} to={(proyecto?.uri === undefined ? "/" : proyecto?.uri)} onClick={(event => handleClickOverlay(event,proyecto?.uri))}>
          <Title styleCss={textProyectoCss} text={proyecto?.title}/>
          <Title styleCss={textDistritoCss} text={proyecto?.distritos?.nodes[0]?.name}/>
          <Overlay imagen={proyecto?.featuredImage?.node} typeEle="imagen">
            {proyecto?.distritos?.nodes[0]?.name && <Title styleCss={textDistritoCss} text={proyecto.distritos.nodes[0].name}/>}
            {proyecto?.template?.proyecto?.textoParaHomeDormitoriosGeneral && <Paragraph text={proyecto?.template?.proyecto?.textoParaHomeDormitoriosGeneral} styleCss={textOverlayCss}/>}
            {proyecto?.template?.proyectoProximamente?.textoParaHomeDormitoriosGeneral && <Paragraph text={proyecto?.template?.proyectoProximamente?.textoParaHomeDormitoriosGeneral} styleCss={textOverlayCss}/>}
            <svg role="img" aria-labelledby="proyecto icono más" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconOverlayCss} className="icono-mov">
              <rect x="22.8452" width="7.31035" height="53" fill="black"/>
              <rect x="53" y="22.8448" width="7.31035" height="53" transform="rotate(90 53 22.8448)" fill="black"/>
            </svg>
          </Overlay>
          <div css={linkCss}>
            Más info
            <svg role="img" aria-labelledby="proyecto movil icono más" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconOverlayCss}>
              <rect x="22.8452" width="7.31035" height="53" fill="black"/>
              <rect x="53" y="22.8448" width="7.31035" height="53" transform="rotate(90 53 22.8448)" fill="black"/>
            </svg>
          </div>
        </Link>
      </div>


    )
  
  }