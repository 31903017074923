import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import LayoutSinNavegacion from "../components/organisms/Layout/LayoutSinNavegacion"
import MuchoFooter from "../components/templates/MuchoGusto/MuchoFooter"
import MuchoBanner from "../components/templates/MuchoGusto/MuchoBanner"
import MuchoDetalles from "../components/templates/MuchoGusto/MuchoDetalles"
import MuchoHola from "../components/templates/MuchoGusto/MuchoHola"
import "./MuchoGusto.css"
import MuchoDonde from "../components/templates/MuchoGusto/MuchoDonde"
import MuchoTengamos from "../components/templates/MuchoGusto/MuchoTengamos"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_MuchoGusto {
            templateName
            muchoGusto {
              bannerTitulo
              bannerLogo {
                ...imagen
              }
              detallesTitulo
              detallesTexto
              detallesImagen {
                ...imagen
              }
              holaTitulo
              holaTexto
              holaImagen {
                ...imagen
              }
              holaImagenPortada {
                ...imagen
              }
              dondeTitulo
              dondeTextoBoton
              dondeUrlBoton
              dondeProyectos {
                direccion
                titulo
                distrito
                imagen {
                  ...imagen
                }
              }
              tengamosTitulo
              footerTextoLink
              footerCelular
              footerTelefono
            }
          }
        }
      }
    }
  }
`

export default function MuchoGusto({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.muchoGusto

  return (
    <LayoutSinNavegacion classEle="">
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">Mucho Gusto</h1>
      <MuchoBanner data={template} />
      <MuchoHola data={template} />
      <MuchoDetalles data={template} />
      <MuchoDonde data={template} />
      <MuchoTengamos data={template} />
      <MuchoFooter data={template} />
    </LayoutSinNavegacion>
  )
}

