import React from 'react';
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"

export default function LogoMapa({logo, refEle}) {

  const contentLogoCss = css`
    position: relative;
    transform: translateY(-100%) translateX(-50%);
    width: calc(100vw * (calc(650 / var(--width_base))));
    z-index: 11;
  `;

  const boxLogoCss = css`
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(100vw * (calc(22 / var(--width_base))));
    padding: calc(100vw * (calc(18 / var(--width_base))));
    background-color: white;
    width: calc(100vw * (calc(170 / var(--width_base))));
    border-radius: calc(100vw * (calc(15 / var(--width_base))));
    box-shadow: 0 5px 15px 0 gray;
    position: relative;
    z-index: 1;
    
    &::before{
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 0;
      height: 0;
      border-top: calc(100vw * (calc(10 / var(--width_base)))) solid white;
      border-left: calc(100vw * (calc(10 / var(--width_base)))) solid transparent;
      border-right: calc(100vw * (calc(10 / var(--width_base)))) solid transparent;
      z-index: 1;
    }
  `;

  const logoCss = css`
    &,& img, &.gatsby-image-wrapper-constrained img{
      width: calc(100vw * (calc(134 / var(--width_base))));
    }
  `;

  const boxCloseCss = css`
    &{
      position: absolute;
      top: calc(100vw * (calc(7 / var(--width_base))));
      right: calc(100vw * (calc(12 / var(--width_base))));
    }
    &,& svg{
      width: calc(100vw * (calc(8 / var(--width_base))));
      height: calc(100vw * (calc(8 / var(--width_base))));
      padding: 0;
    }
  `;

  const onClickCloseBox = () =>{
    refEle.current.classList.add("is-hidden");
  }


  return (
    <div css={contentLogoCss}>
      <div css={boxLogoCss} ref={refEle}>
        <button css={boxCloseCss} onClick={onClickCloseBox}>
          <svg role="img" aria-labelledby="mapa icono cerrar" viewBox="0 0 409.806 409.806">
            <path d="M228.929,205.01L404.596,29.343c6.78-6.548,6.968-17.352,0.42-24.132c-6.548-6.78-17.352-6.968-24.132-0.42
              c-0.142,0.137-0.282,0.277-0.42,0.42L204.796,180.878L29.129,5.21c-6.78-6.548-17.584-6.36-24.132,0.42
              c-6.388,6.614-6.388,17.099,0,23.713L180.664,205.01L4.997,380.677c-6.663,6.664-6.663,17.468,0,24.132
              c6.664,6.662,17.468,6.662,24.132,0l175.667-175.667l175.667,175.667c6.78,6.548,17.584,6.36,24.132-0.42
              c6.387-6.614,6.387-17.099,0-23.712L228.929,205.01z" stroke="black"/>
          </svg>
        </button>
        <Image imagen={logo} styleCss={logoCss}/>
      </div>
    </div>
  );

}