import React from "react"
import { useEffect } from "react"
import { graphql } from "gatsby"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Title from "../../atoms/Title/Title"
import Image from "../../atoms/Image/Image"
import { css, keyframes } from "@emotion/react"
import Paragraph from "../../atoms/Paragraph/Paragraph"
// import storage from "local-storage-fallback"

export default function IntroInicio({ imagen, colorFondo, titulo, autor }) {
  useEffect(() => {
    let bodyHtml = document.querySelector("body")
    bodyHtml.classList.add("no-scroll")

    const intervalID = setInterval(() => {
      bodyHtml.classList.remove("no-scroll")
    }, 6000)

    return () => {
      bodyHtml.classList.remove("no-scroll")
      clearInterval(intervalID)
    }
  }, [])

  // const itemStr = storage.getItem("timeAnimation")
  //
  // if (!itemStr) {
  //   let tiempo = 20000;
  //   const now = new Date()
  //   const item = {
  //     value: "is-animation",
  //     expiry: 0,
  //     //expiry: now.getTime() + tiempo,
  //   }
  //   storage.setItem("timeAnimation", JSON.stringify(item))
  // }else{
  //   const item = JSON.parse(itemStr)
  //   const now = new Date()
  //   if (now.getTime() > item.expiry) {
  //     storage.removeItem("timeAnimation")
  //   }
  // }

  const sectionAnim = keyframes`
    from {
      opacity: 1;
      height: 100vh;
      padding-top: calc(100vw * (calc(182 / var(--width_base))));
    }
    to{
      opacity: 0;
      height: 0vh;
      padding-top: 0;
    }
  `
  const sectionCss = css`
    /* overflow: hidden; */
    height: 100vh;
    padding-top: calc(100vw * (calc(182 / var(--width_base))));
    animation-name: ${sectionAnim};
    animation-duration: 2s;
    animation-delay: 4s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    position: relative;
    inset: 0;
    z-index: 999;
    /* display: none; */
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(101 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(40 / var(--width_base))));
    }
  `
  const sectionContentCss = css`
    display: flex;
    flex-flow: column;
    flex-direction: column;
    height: 100%;
    align-items: center;
    @media (max-width: 767px) {
      align-items: start;
    }
  `
  const textIntro = css`
    font-family: Gotham, sans-serif;
    text-align: center;
    color: #000000;
    @media (max-width: 767px) {
      text-align: left;
    }
  `
  const paragraphAnim = keyframes`
    from {
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  `
  const paragraphCss = css`
    ${textIntro};
    font-style: normal;
    font-weight: bold;
    font-size: calc(100vw * (calc(68 / var(--width_base))));
    line-height: calc(100vw * (calc(82 / var(--width_base))));
    text-transform: uppercase;
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    opacity: 0;
    animation-name: ${paragraphAnim};
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(36 / var(--width_base))));
      line-height: calc(100vw * (calc(44 / var(--width_base))));
    }
  `

  const authorAnim = keyframes`
    from {
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  `
  const tittleCss = css`
    ${textIntro};
    font-style: italic;
    font-weight: 500;
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    line-height: calc(100vw * (calc(29 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(110 / var(--width_base))));
    opacity: 0;
    animation-name: ${authorAnim};
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    @media (max-width: 767px) {
      display: none;
    }
  `

  const imageAnim = keyframes`
    from {
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  `
  const imageCss = css`
    height: calc(100vw * (calc(280 / var(--width_base))));
    width: calc(100vw * (calc(370 / var(--width_base))));
    opacity: 0;
    animation-name: ${imageAnim};
    animation-duration: 2s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    & img {
      transition: none;
      transform: none;
      opacity: 0;
    }
    @media (max-width: 767px) {
      height: calc(100vw * (calc(184 / var(--width_base))));
      width: calc(100vw * (calc(242 / var(--width_base))));
    }
  `

  return (
    <SectionBackground
      bgColor={colorFondo}
      styleCss={sectionCss}
      styleCssContent={sectionContentCss}
      classEle="sectionintro"
    >
      <Paragraph text={titulo} styleCss={paragraphCss} />
      <Title text={autor} styleCss={tittleCss} />
      <Image imagen={imagen} styleCss={imageCss} />
    </SectionBackground>
  )
}

export const queryIntroInicio = graphql`
  fragment introInicio on Wordpress_Template_Inicio_Inicio {
    imagenIntro {
      ...imagen
    }
    colorFondoIntro
    tituloIntro
    autorIntro
  }
`
