import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { css } from "@emotion/react"

export default function DepasForm({bgColorInput,bgColorSubmit}) {

  const [envio, setEnvio] = useState("");

  const formCss = css`
    @media (max-width: 767px){
        margin-top: calc(100vw * (calc(50 / var(--width_base))));
    }
    & .form-group{
     margin-bottom: calc(100vw * (calc(25 / var(--width_base)))); 
    }
    
    & .form-error{
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
    
    & .form-control{
      background-color: ${bgColorInput};
      padding: calc(100vw * (calc(6 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border-radius: calc(100vw * (calc(6 / var(--width_base))));
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ccc;
    }
  `;

  const submitCss = css`
    background-color: #516059;
    padding: calc(100vw * (calc(13 / var(--width_base))))  calc(100vw * (calc(10 / var(--width_base)))) calc(100vw * (calc(3 / var(--width_base))))  calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
    width: calc(100vw * (calc(130 / var(--width_base))));
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    display: block;
    margin-left: auto;
    color: white;
    text-align: center;
  `;


  const formAceptCss = css`
    color: #0a0a0a;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    display: inline-block;
    font-style: italic;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
      }
    & input{
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `;

  const linkCss = css`
    color: #E39A00;
  `;

  return (
    <section>
      <Formik
        initialValues={{
          NombreyApellidos: '',
          Celular: '',
          DepaInteres: '' ,
          ComoPrefieres: '' ,
          Acepto_condiciones: ''}}
        validate={values => {
          const errors = {};

          if (!values.NombreyApellidos) errors.NombreyApellidos = 'Campo requerido';

          if (!values.Celular) errors.Celular = 'Campo requerido';

          if (!values.DepaInteres) errors.DepaInteres = 'Campo requerido';

          if (!values.ComoPrefieres) errors.ComoPrefieres = 'Campo requerido';

          if (!values.Acepto_condiciones) {
            errors.Acepto_condiciones = 'Campo requerido';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{
            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/11086/feedback`, {
              method: 'POST',
              body:
                'NombreyApellidos=' + values.NombreyApellidos +
                '&Celular=' + values.Celular +
                '&DepaInteres=' + values.DepaInteres +
                '&ComoPrefieres=' + values.ComoPrefieres,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                  console.log("correo enviado");
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="NombreyApellidos">Nombre y Apellidos*</label>
              <Field className="form-control" type="text" name="NombreyApellidos" id="NombreyApellidos"/>
              <ErrorMessage className="form-error" name="NombreyApellidos" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Celular">Celular*</label>
              <Field className="form-control" type="tel" name="Celular" id="Celular"/>
              <ErrorMessage className="form-error" name="Celular" component="div" />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="DepaInteres">Depa de interés: *</label>
              <Field name="DepaInteres" as="select" className="form-control" id="DepaInteres">
                <option value="">---</option>
                <option value="Penthouse 1201, Los Nogales">Penthouse 1201, Los Nogales</option>
                <option value="Depa 101, Basadre 1330">Depa 101, Basadre 1330</option>
                <option value="Depa B 104, Ugarte y Moscoso 330">Depa B 104, Ugarte y Moscoso 330</option>
                <option value="Depa B 105, Ugarte y Moscoso 330">Depa B 105, Ugarte y Moscoso 330</option>
              </Field>
              <ErrorMessage className="form-error" name="DepaInteres" component="div" />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="ComoPrefieres">¿Cómo prefires?: *</label>
              <Field name="ComoPrefieres" as="select" className="form-control" id="ComoPrefieres">
                <option value="">---</option>
                <option value="Llamada">Llamada</option>
                <option value="Whatsapp">Whatsapp</option>
              </Field>
              <ErrorMessage className="form-error" name="SourceId" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-acept" htmlFor="Acepto_condiciones" css={formAceptCss}>
                <Field className="accept-box" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones"/>&nbsp;&nbsp;&nbsp;Acepto la <a href="/politica-de-privacidad/" css={linkCss} target="_blank" rel="noreferrer">política de privacidad</a> de Armando Paredes.</label>
              <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />
            </div>
            <div className="grid-100 grid-x-0">
              <button className="" type="submit" disabled={isSubmitting} css={submitCss}>Enviar</button>
            </div>
            { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
            { (envio === true && envio !== "") && <div className="">Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}