import React from 'react';

export default function Redes({facebook,instagram,linkedin,spotify, styleCss}) {

  return (
    <ul css={styleCss}>
      {facebook && <li className="menu__item"><a className="menu__link" aria-label="Link" href={facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>}
      {instagram && <li className="menu__item"><a className="menu__link" aria-label="Link" href={instagram} target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>}
      {linkedin && <li className="menu__item"><a className="menu__link" aria-label="Link" href={linkedin} target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a></li>}
      {spotify && <li className="menu__item"><a className="menu__link" aria-label="Link" href={spotify} target="_blank" rel="noreferrer"><i className="fab fa-spotify"></i></a></li>}
    </ul>
  );

}