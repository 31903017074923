import React from "react"
import { css } from "@emotion/react"
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function AccordionProyectoItem({ data }) {
  const cardTitleCss = css`
    font-weight: bold;
  `

  const handleIcon = e => {
    const { target } = e
    const parent = target.parentNode.parentNode
    console.log(parent)
    parent.classList.toggle("active")
  }

  const accordionNameCss = css`
    display: flex;
    justify-content: space-between;
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    padding: calc(100vw * (calc(23 / var(--width_base))))
      calc(100vw * (calc(15 / var(--width_base))));
    border-bottom: 1px solid black;
    font-family: "Poppins Regular", sans-serif;
  `

  const accordionContentCss = css`
    // padding: calc(100vw * (calc(23 / var(--width_base)))) calc(100vw * (calc(25 / var(--width_base))));
    // font-size: calc(100vw * (calc(12 / var(--width_base))));
  `

  const cardItemCss = css`
    padding: calc(100vw * (calc(5 / var(--width_base))))
      calc(100vw * (calc(25 / var(--width_base))));
    border-bottom: 1px solid #9e9e9e;
    & p,
    & div {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      display: inline;
    }
  `

  const { items, title } = data

  return (
    <div className="accordion__item">
      <h3 css={accordionNameCss}>
        <span>{title}</span>
        <div onClick={handleIcon}>
          <i className="events-none fa fa-plus"></i>
          <i className="events-none fa fa-minus"></i>
        </div>
      </h3>
      <ul className="accordion__content" css={accordionContentCss}>
        {items &&
          items.map((item, index) => (
            <li css={cardItemCss} key={index}>
              {item?.titulo && (
                <>
                  <Paragraph styleCss={cardTitleCss} text={item?.titulo} />
                  <span> - </span>
                </>
              )}
              {item?.direccion && <Paragraph text={item?.direccion} />}
            </li>
          ))}
      </ul>
    </div>
  )
}

