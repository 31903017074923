import React from 'react';
import Image from "../../atoms/Image/Image"
import { Link } from "gatsby"
import {css} from "@emotion/react"

export default function Overlay({key,link, imagen, children, styleCssImage, styleCss, bgColor, classEle, typeEle}) {
  
  const overlayText = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(100vw * (calc(39 / var(--width_base)))) calc(100vw * (calc(59 / var(--width_base))));
    background-color: ${bgColor};
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    opacity: 0;
    transition: opacity .3s linear;
    width: 100%;
    @media (max-width: 767px){
        padding: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const overlayCss = css`
    position: relative;
    ${styleCss}
    &:hover .overlay__text{
      opacity: 1;
    }
  `;

  return (
    <>
      {
        (typeEle === "" || typeEle === "link") &&
        <Link to={link} key={key} css={overlayCss} className={classEle}>
            <Image imagen={imagen} styleCss={styleCssImage}/>
          <div css={overlayText} className="overlay__text">
            {children}
          </div>
        </Link>
      }
      {
        (typeEle === "imagen") &&
          <div css={overlayCss} className="card-overlay is-noactive">
            <Image imagen={imagen} styleCss={styleCssImage}/>
            <div css={overlayText} className="overlay__text">
              {children}
            </div>
          </div>
      }
    </>
  );

}

Overlay.defaultProps = {
  key: 0,
  link: '/',
  imagen: null,
  children: '',
  styleCssImage: {},
  styleCss: {},
  typeEle: "",
  bgColor: 'rgba(255,255,255,.8)'
}