import React from "react"
import {css} from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby"
import List from "../List/List";
import AnimateHeight from 'react-animate-height';

const MenuMovil = ({heightList}) => {

  const data = useStaticQuery(graphql`
    {
      wordpress {
        menuItems(where: {location: MENU_MOVIL, parentId: ""}) {
          nodes {
            id
            label
            url
            title
            target
            childItems(before: "") {
              edges {
                node {
                  id
                  label
                  url
                  title
                  target
                }
              }
            }
          }
        }
        generalSettings {
          url
        }
      }
    }
  `);

  const menuItems = data.wordpress.menuItems.nodes
  const wordPressUrl = data.wordpress.generalSettings.url

  const menuListCss = css`
    border-bottom: calc(100vw * (calc(1 / var(--width_base)))) solid #b3b3b3;
    & .menu__link{
      padding: calc(100vw * (calc(14.4 / var(--width_base)))) calc(100vw * (calc(16 / var(--width_base))));
      border-top: calc(100vw * (calc(1 / var(--width_base)))) solid #b3b3b3;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
    }

    & .menu__link.is-active, & .menu__link.is-active .fas{
      background-color: #C7272E;
      color: white;
    }

    & .sub-menu .menu__link{
      padding: calc(100vw * (calc(14.4 / var(--width_base)))) calc(100vw * (calc(36 / var(--width_base))));
    }
    
    & .sub-menu > .sub-menu__item .menu__link{
      color: white;
    }

    & .sub-menu > .sub-menu__item:nth-of-type(1) > .menu__link{
      background-color: #ccbeac;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(2) > .menu__link{
      background-color: #000000;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(3) > .menu__link{
      background-color: #eebeba;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(4) > .menu__link{
      background-color: #7aa576;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(5) > .menu__link{
      background-color: #a1bfcb;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(6) > .menu__link{
      background-color: #9e452a;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(7) > .menu__link{
      background-color: #bad8ce;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(8) > .menu__link{
      background-color: #d77d6b;
    }
    & .sub-menu > .sub-menu__item:nth-of-type(9) > .menu__link{
      background-color: #434a32;
    }
  `;


  const listCss= css`
    position: relative;
    z-index: 1;
    background-color: white;
  `;

  return (
    <AnimateHeight
      id='list-movil'
      duration={ 300 }
      height={heightList} className="only-mov" css={listCss}>
        <List menuItems={menuItems} wordPressUrl={wordPressUrl} styleCss={menuListCss} classEle={"active"}/>
    </AnimateHeight>
  );
}

export default MenuMovil

