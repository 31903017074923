import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import TextArmando from "../../organisms/GroupText/TextArmando"

export default function NosInspiraArmando({colorFondo, texto, titulo, imagen1, imagen2, imagen3}) {

  const contentCss = css`
    background-color:  ${colorFondo};
  `

  const imagenCss = css`
    width: 100%;
    height: calc(100vw * (calc(392 / var(--width_base))));
    @media (max-width: 767px) {
      height: initial;          
    }
  `

  return (
    <SectionBackground widthContent={1200} styleCssContent={contentCss}>
      <div className="grid-55 grid-x-0 line-height-0">
        <Image imagen={imagen1} classEle="w-full" styleCss={imagenCss}/>
        <TextArmando titulo={titulo} texto={texto} color_titulo="#343434"/>
      </div>
      <div className="grid-45 grid-x-0 line-height-0">
        <Image imagen={imagen2} classEle="w-full" styleCss={imagenCss}/>
        <Image imagen={imagen3} classEle="w-full" styleCss={imagenCss}/>
      </div>
    </SectionBackground>
  );

}

export const queryNosInspirasArmando = graphql`
  fragment nosInspirasArmando on Wordpress_Template_Armando_Armando{
    colorFondoNosInspira
    tituloNosInspira
    textoNosInspira
    imagen3NosInspira {
      ...imagen
    }
    imagen2NosInspira {
      ...imagen
    }
    imagen1NosInspira {
      ...imagen
    }
  }
`
