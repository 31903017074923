import React from 'react';
import BuscadorProyectos from "./BuscadorProyectos"
import GridProyectos from "./GridProyectos"
import {css} from "@emotion/react";
import { Element } from 'react-scroll';
import FilterAccordion from '../../molecules/FilterAccordion/FilterAccordion';

export default function SectionProyectos({location}) {

  const sectionCss = css`
    padding-left: calc(100vw * (calc(80 / var(--width_base))));
    padding-right: calc(100vw * (calc(80 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(46 / var(--width_base))));
    padding-top: calc(100vw * (calc(48 / var(--width_base))));
    @media (max-width: 767px){
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;  
    }
  `


  return (
    <section css={sectionCss} id="proyectos">
      <Element name="proyectos"/>
      <div className="only-desk">
        <BuscadorProyectos/>
      </div>
      <div className="only-mov">
        <FilterAccordion/>
      </div>
      <GridProyectos distritos={location?.state?.distritoSelect} dormitorios={location?.state?.dormitorioSelect} etapas={location?.state?.etapaSelect}/>
    </section>
  );

}