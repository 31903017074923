import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { css } from "@emotion/react"
import { navigate } from "gatsby"

export default function ProximamenteForm({
  proyectoId,
  urlGracias,
  bgColorInput,
  bgColorSubmit,
}) {
  const [envio, setEnvio] = useState("")

  const formCss = css`
    & .form-group {
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-error {
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }

    & .form-control {
      background-color: ${bgColorInput};
      padding: calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))));
      border: none;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border-radius: calc(100vw * (calc(6 / var(--width_base))));
    }
  `

  const submitCss = css`
    background-color: #79a2b1;
    padding: calc(100vw * (calc(13 / var(--width_base))))
      calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(3 / var(--width_base))))
      calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
    width: calc(100vw * (calc(130 / var(--width_base))));
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    display: block;
    margin-left: auto;
    color: white;
    text-align: center;
  `

  const formAceptCss = css`
    color: #555555;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    display: inline-block;
    font-style: italic;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
    }
    & input {
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const linkCss = css`
    color: #79a2b1;
  `

  return (
    <section>
      <Formik
        initialValues={{
          Nombres: "",
          Apellidos: "",
          Edad: "",
          Email: "",
          Celular: "",
          DistritoResidencia: "",
          Acepto_condiciones: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombres) {
            errors.Nombres = "Campo requerido"
          }

          if (!values.Apellidos) {
            errors.Apellidos = "Campo requerido"
          }

          if (!values.Edad) {
            errors.Edad = "Campo requerido"
          }

          if (!values.Celular) {
            errors.Celular = "Campo requerido"
          }
          if (!values.Email) {
            errors.Email = "Campo requerido"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          ) {
            errors.Email = "Correo Invalido"
          }
          if (!values.Acepto_condiciones) {
            errors.Acepto_condiciones = "Campo requerido"
          }

          if (!values.DistritoResidencia) {
            errors.DistritoResidencia = "Campo requerido"
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          try {
            const jsonData = {
              email: values.Email,
              fname: values.Nombres,
              lname: values.Apellidos,
              address: values.DistritoResidencia,
              phone: values.Celular,
              project_id: parseInt(proyectoId),
              interest_type_id: data?.interese_type_id,
              source_id: data?.source_id,
              observation: `Edad: ${values.Edad}, fbc: ${data?.fbc}, fbp: ${data?.fbp}, ip_address: ${data?.ip_address}`,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: data?.utm_content,
              utm_term: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }
            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              body: JSON.stringify(jsonData),
              // body:
              //   "NombreyApellidos=" +
              //   values.NombreyApellidos +
              //   "&Edad=" +
              //   values.Edad +
              //   "&Correo=" +
              //   values.Email +
              //   "&Celular=" +
              //   values.Celular +
              //   "&DistritoResidencia=" +
              //   values.DistritoResidencia,
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then(res => res.json())
              .then(json => {
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()
                  if (urlGracias) navigate(urlGracias)
                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error)
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="Nombres"
                placeholder="Nombres*"
              />
              <ErrorMessage
                className="form-error"
                name="Nombres"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="Apellidos"
                placeholder="Apellidos*"
              />
              <ErrorMessage
                className="form-error"
                name="Apellidos"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="number"
                name="Edad"
                placeholder="Edad*"
              />
              <ErrorMessage
                className="form-error"
                name="Edad"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="Email"
                placeholder="Correo*"
              />
              <ErrorMessage
                className="form-error"
                name="Email"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="tel"
                name="Celular"
                placeholder="Celular"
              />
              <ErrorMessage
                className="form-error"
                name="Celular"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field
                className="form-control"
                type="text"
                name="DistritoResidencia"
                placeholder="Distrito de residencia"
              />
              <ErrorMessage
                className="form-error"
                name="DistritoResidencia"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;Acepto la{" "}
                <a
                  href="/politica-de-privacidad/"
                  css={linkCss}
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidad
                </a>{" "}
                de Armando Paredes.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="grid-100 grid-x-0">
              <button
                className=""
                type="submit"
                disabled={isSubmitting}
                css={submitCss}
              >
                Enviar
              </button>
            </div>
            {envio === false && envio !== "" && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== "" && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
