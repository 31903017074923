import React from 'react';
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Image from "../../atoms/Image/Image";
import { graphql } from "gatsby";
import {css} from '@emotion/react';

export default function DescripcionEntregado({imagen, titulo, texto}) {

  const imagenCss = css`
    height: calc(100vw * (calc(960 / var(--width_base))));
    @media (max-width: 767px){
      height: calc(100vw * (calc(375 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(52 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(40 / var(--width_base))));
    line-height: calc(100vw * (calc(48 / var(--width_base))));
    font-family: "Lato", sans-serif;
    font-weight: 600;
    margin-bottom: calc(100vw * (calc(33 / var(--width_base))));
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    line-height: calc(100vw * (calc(34 / var(--width_base))));
    font-weight: 400;
    font-style: italic;
    @media (max-width: 767px){
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(22.78 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(52 / var(--width_base))));
    }
  `;

  const contenRight = css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: calc(100vw * (calc(97 / var(--width_base))));
    padding-right: calc(100vw * (calc(97 / var(--width_base))));
    @media (max-width: 767px){
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(120 / var(--width_base))));
    }
  `;

  return (
    <section className="grid-100 grid-x-0" css={{display: 'flex', flexWrap: 'wrap'}}>
      <div className="grid-50 grid-x-0">
        <Image imagen={imagen} classEle="w-full" styleCss={imagenCss}/>
      </div>
      <div className="grid-50" css={contenRight}>
        <Paragraph text={titulo} styleCss={titleCss}/>
        <Paragraph text={texto} styleCss={textCss} brMov={true}/>
      </div>
    </section>
  );

}

export const queryDescripcionEntregado = graphql`
  fragment descripcionEntregado on Wordpress_Template_Entregado_Entregado{
    tituloDescripcion
    textoDescripcion
    imagenDescripcion {
      ...imagen
    }
  }
`