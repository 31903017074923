import React from 'react';
import { css } from "@emotion/react"
import ProyectoEntry from '../../organisms/ProyectoEntry/ProyectoEntry';
import VerMas from '../../molecules/VerMas/VerMas';

export default function MostrarProyectos({proyectos}) {

  const contentProyectosCss = css`
    display: flex;
    clear: both;
    width: 130%;
    &.only-desk{
      @media (max-width: 767px){
        display: none;
      }
    }
  `;

  const entryCss = css`
    margin-right: calc(100vw * (calc(72 / var(--width_base))));
    flex-shrink: 0;
    overflow: hidden;
  `;

  const imagenOverlayCss = css`
     width: calc(100vw * (calc(791 / var(--width_base))));
     height: calc(100vw * (calc(619 / var(--width_base))));
     object-fit: cover;
    
    @media (max-width: 1440px) and (min-width: 1025px) {
      width: calc(100vw * (calc(591 / var(--width_base))));
      height: calc(100vw * (calc(419 / var(--width_base))));
    }
    
     @media (max-width: 1024px) and (min-width: 768px) {
       width: calc(100vw * (calc(491 / var(--width_base))));
       height: calc(100vw * (calc(319 / var(--width_base)))); 
     }
  `;

  return (
    <>
      <div css={contentProyectosCss} className="only-desk">
        {
          proyectos && proyectos.map((proyecto,i)=>(
            <>
              {
                (i === 2) ?
                  <ProyectoEntry proyecto={proyecto} keyEle={i} styleImageEle={imagenOverlayCss} linkEle="/proyectos/"/>
                  :
                  <ProyectoEntry proyecto={proyecto} keyEle={i} styleEle={entryCss} styleImageEle={imagenOverlayCss}/>
              }
            </>
          ))
        }
      </div>
      <div className="only-mov">
        {
          proyectos && proyectos.map((proyecto,i)=>(
            <ProyectoEntry proyecto={proyecto} keyEle={i}/>
          ))
        } 
        <VerMas />
      </div>
    </>
  );

}