import React from "react";
import MuchoGustoForm from "../../organisms/Form/MuchoGustoForm";
import {css} from "@emotion/react";
import Title from "../../atoms/Title/Title";

export default function MuchoTengamos({data}) {
    
    const sectionCss = css`
        background-color: #ededed;
        padding: 
            calc(100vw * (calc(141 / var(--width_base))))
            calc(100vw * (calc(110 / var(--width_base))));

        @media (max-width: 767px) {
            padding:
            calc(100vw * (calc(71 / var(--width_base))))
            calc(100vw * (calc(63 / var(--width_base))))
        }
    `;

    const sectionContentCss = css`
        // max-width: calc(100vw * (calc(1200 / var(--width_base))));
        // margin: 0 auto;
    `;

    const titleCss = css`
      text-align: center;
      font-style: italic;
      font-family: "Poppins Regular", sans-serif;
      font-size: calc(100vw * (calc(30 / var(--width_base))));
      line-height: calc(100vw * (calc(36 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(41 / var(--width_base))));

      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        line-height: calc(100vw * (calc(22 / var(--width_base))));
        margin-bottom: calc(100vw * (calc(23 / var(--width_base))));
      }
    `;

    return(
        <section css={sectionCss}>
            <div css={sectionContentCss}>
                {
                    data?.tengamosTitulo &&
                    <Title styleCss={titleCss} text={data?.tengamosTitulo}/>
                }
                <MuchoGustoForm/>
            </div>
        </section>        
    )
}