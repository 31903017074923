import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import Title from "../../atoms/Title/Title";
import { graphql, Link } from "gatsby";
import {css} from '@emotion/react';

export default function NavegacionEntregado({colorFondo, titulo, linkSiguiente, linkAnterior, distrito, anio}) {

  const sectionCss = css`
    padding: calc(100vw * (calc(24 / var(--width_base)))) calc(100vw * (calc(80 / var(--width_base))));
    @media (max-width: 767px){
      padding: calc(100vw * (calc(16 / var(--width_base))));
    }
  `;
  const contentCss = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before,&::after{
      display: none;
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    line-height: calc(100vw * (calc(35 / var(--width_base))));
  `;

  const anioCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    font-weight: 400;
    color: white;
  `;

  const iconCss = css`
    width: calc(100vw * (calc(14 / var(--width_base))));
    height: calc(100vw * (calc(28 / var(--width_base))));
  `;

  return (
    <SectionBackground bgColor={colorFondo} styleCss={sectionCss} styleCssContent={contentCss}>
      {
        linkAnterior ?
        <Link to={linkAnterior}>
          <svg role="img" aria-labelledby="icono flecha izquierda" width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconCss}>
            <path d="M17 2L3 16L17 30" stroke="white" strokeWidth="3"/>
          </svg>
        </Link>
        :
          <span/>
      }
      <div className="text-center">
        <Title color="white" text={`${titulo}, ${distrito}`} styleCss={titleCss}/>
        <Title color="white" text={anio} styleCss={anioCss}/>
      </div>
      {
        linkSiguiente ?
        <Link to={linkSiguiente} role="img" aria-labelledby="icono flecha derecha">
          <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg" css={iconCss}>
            <path d="M2 30L16 16L2 2" stroke="white" stroke-width="3"/>
          </svg>
        </Link>
        :
          <span/>
      }
    </SectionBackground>
  );

}

export const queryNavegacionEntregado = graphql`
  fragment navegacionEntregado on Wordpress_Template_Entregado_Entregado{
    anoGeneral
    colorFondoNavegacion
    proyectoAnteriorNavegacion {
      ... on Wordpress_ProyectoEntregado {
        uri
      }
    }
    proyectoSiguienteNavegacion {
      ... on Wordpress_ProyectoEntregado {
        uri
      }
    }
  }
`