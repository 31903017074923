import React, { useState, useRef, useCallback } from "react"
import { Formik, Field, ErrorMessage } from "formik"
import { css } from "@emotion/react"
import FormStyle from "./FormStyle"
import { navigate } from "gatsby"

export default function InicioForm({ color }) {
  const formCss = css`
    & > div {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
    }
    & .form-column {
      float: left;
      flex-shrink: 0;
      width: calc(50% - calc(100vw * (calc(13 / var(--width_base)))));
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    & .form-column:nth-of-type(2n) {
      margin-left: calc(100vw * (calc(23 / var(--width_base))));
      @media (max-width: 767px) {
        margin-left: initial;
      }
    }
  `

  const textareaCss = css`
    height: calc(100vw * (calc(150 / var(--width_base))));
  `

  const formAceptCss = css`
    color: ${color};
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    display: inline-block;

    &:not(:last-of-type) {
      margin-top: calc(100vw * (calc(15 / var(--width_base))));
    }

    & a {
      color: ${color};
    }
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
    }
    & input {
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const submitCss = css`
    color: white;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    padding: calc(100vw * (calc(8 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    font-style: italic;
    background-color: #516059;
    margin-left: auto;
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
    float: right;
    text-transform: uppercase;
  `

  // LOGICA

  const formikRef = useRef(null)
  const [envio, setEnvio] = useState("")
  const [paginaGracias, setPaginaGracias] = useState("")
  const [valoresTipoProyecto, setValoresTipoProyecto] = useState("")
  // const [templateId, setTemplateId] = useState(0);

  // let utm_campaign = getUrlParameter("utm_campaign")
  //   ? getUrlParameter("utm_campaign")
  //   : ""
  // let utm_content = getUrlParameter("utm_content")
  //   ? getUrlParameter("utm_content")
  //   : ""
  // let utm_medium = getUrlParameter("utm_medium")
  //   ? getUrlParameter("utm_medium")
  //   : ""
  // let utm_source = getUrlParameter("utm_source")
  //   ? getUrlParameter("utm_source")
  //   : ""
  // let utm_term = getUrlParameter("utm_term") ? getUrlParameter("utm_term") : ""

  const onChangeDistrito = useCallback(event => {
    const valorSelect = event?.target?.value
    let arrTipos = []
    if (valorSelect) {
      switch (valorSelect) {
        case "Miraflores":
          arrTipos = [{ id: 19, name: "Toribio Polo 322" }]
          setValoresTipoProyecto(arrTipos)
          break
        case "San Isidro":
          arrTipos = [
            // { "id" : 4, "name": "Depas Los Nogales"},
            // { "id" : 5, "name": "Depas Basadre"},
            { id: 12, name: "Depas Ugarte y Moscoso 330" },
            { id: 16, name: "Depas Ugarte y Moscoso 370" },
            { id: 10, name: "Depas Machaypuito" },
            { id: 15, name: "Depas Laureles" },
          ]
          setValoresTipoProyecto(arrTipos)
          break
        case "Barranco":
          arrTipos = [{ id: 6, name: "Depas Saenz Peña" }]
          setValoresTipoProyecto(arrTipos)
          break
        case "":
          setValoresTipoProyecto([])
          break
        default:
          setValoresTipoProyecto([])
          break
      }
    } else {
      setValoresTipoProyecto([])
    }

    formikRef.current.setFieldValue("TipoProyecto", "")
    formikRef.current?.handleChange(event)
  }, [])

  const onChangeTipoProyecto = useCallback(event => {
    const valorSelect = event?.target?.value
    if (valorSelect) {
      switch (valorSelect) {
        // Oficinas Libertad
        case "14":
          setPaginaGracias("/proyecto/gracias-libertad/")
          break
        // Depas Los Nogales
        case "4":
          setPaginaGracias("/proyecto/gracias-nogales/")
          break
        // Depas Basadre
        case "5":
          setPaginaGracias("/proyecto/gracias-basadre/")
          break
        //Depas Ugarte y Moscoso 330
        case "12":
          setPaginaGracias("/proyecto/gracias-ugarte-y-moscoso-330/")
          break
        //Depas Ugarte y Moscoso 370
        case "16":
          setPaginaGracias("/proyecto/gracias-ugarte-y-moscoso-370/")
          break
        // Depas Machaypuito
        case "10":
          setPaginaGracias("/proyecto/gracias-machaypuito/")
          break
        // Depas Laureles
        case "15":
          setPaginaGracias("/proyecto/gracias-laureles/")
          break
        // Depas Saenz Peña
        case "6":
          setPaginaGracias("/proyecto/gracias-saenz/")
          break
        default:
          setPaginaGracias("/")
          break
      }
    }

    formikRef.current?.handleChange(event)
  }, [])

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          Nombre: "",
          Apellido: "",
          Email: "",
          Telefono: "",
          Distrito: "",
          TipoProyecto: "",
          Acepto_condiciones: "",
          SourceId: "",
          Me_interesa: "",
          Consulta: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombre) errors.Nombre = "Campo requerido"
          if (!values.Apellido) errors.Apellido = "Campo requerido"
          if (!values.Email) errors.Email = "Campo requerido"
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          )
            errors.Email = "Correo Invalido"
          if (!values.Telefono) errors.Telefono = "Campo requerido"
          if (!values.Distrito) errors.Distrito = "Campo requerido"
          if (!values.TipoProyecto) errors.TipoProyecto = "Campo requerido"
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = "Campo requerido"
          if (!values.SourceId) errors.SourceId = "Campo requerido"
          if (!values.Consulta) errors.Consulta = "Campo requerido"
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          try {
            const meInteresa = values.Me_interesa
              ? " Me interesa ser parte de la comunidad de Armando Paredes y recibir información."
              : ""

            const jsonData = {
              email: values.Email,
              fname: values.Nombre,
              lname: values.Apellido,
              phone: values.Telefono,
              project_id: parseInt(values.TipoProyecto),
              interest_type_id: data?.interest_type_id,
              source_id: data?.source_id,
              observation: `Consulta: ${values.Consulta}, Me interesa: ${meInteresa}, fbc: ${data?.fbc}, fbp: ${data?.fbp}, ip_address: ${data?.ip_address}`,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: data?.utm_content,
              utm_term: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }

            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jsonData),
            })
              .then(res => res.json())
              .then(json => {
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()
                  if (paginaGracias) navigate(paginaGracias)
                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error)
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormStyle styleCss={formCss}>
            <div className="form-column">
              <div className="form-group">
                <Field
                  className="form-control"
                  type="text"
                  name="Nombre"
                  placeholder="Nombre*"
                />
                <ErrorMessage
                  className="form-error"
                  name="Nombre"
                  component="div"
                />
              </div>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="text"
                  name="Apellido"
                  placeholder="Apellido*"
                />
                <ErrorMessage
                  className="form-error"
                  name="Apellido"
                  component="div"
                />
              </div>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="text"
                  name="Email"
                  placeholder="Correo*"
                />
                <ErrorMessage
                  className="form-error"
                  name="Email"
                  component="div"
                />
              </div>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="tel"
                  name="Telefono"
                  placeholder="Teléfono*"
                />
                <ErrorMessage
                  className="form-error"
                  name="Telefono"
                  component="div"
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="Distrito">
                  Estoy buscando depa en:
                </label>
                <Field
                  name="Distrito"
                  as="select"
                  className="form-control"
                  onChange={event => onChangeDistrito(event)}
                >
                  <option value="">---</option>
                  <option value="Miraflores">Miraflores</option>
                  <option value="San Isidro">San Isidro</option>
                  <option value="Barranco">Barranco</option>
                </Field>
                <ErrorMessage
                  className="form-error"
                  name="Distrito"
                  component="div"
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="TipoProyecto">
                  Quiero saber más sobre este proyecto:
                </label>
                <Field
                  name="TipoProyecto"
                  as="select"
                  className="form-control"
                  onChange={onChangeTipoProyecto}
                >
                  <option value="">---</option>
                  {valoresTipoProyecto &&
                    valoresTipoProyecto.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  className="form-error"
                  name="TipoProyecto"
                  component="div"
                />
              </div>
              {/*<label className="form-acept" htmlFor="Acepto_condiciones" css={formAceptCss}>*/}
              {/*  <Field className="accept-box" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones"/>&nbsp;&nbsp;&nbsp;Acepto las condiciones de la politica de privacidad.</label>*/}
              {/*<ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />*/}
            </div>
            <div className="form-column">
              <div className="form-group">
                <label className="form-label" htmlFor="Proyectos">
                  Me enteré de sus proyectos: *
                </label>
                <Field name="SourceId" as="select" className="form-control">
                  <option value="">---</option>
                  <option value="30">En google</option>
                  <option value="18">En Instagram</option>
                  <option value="2">En Facebook</option>
                  <option value="12">Por un conocido</option>
                  <option value="14">Cerco de Obras</option>
                  <option value="19">En una valla</option>
                </Field>
                <ErrorMessage
                  className="form-error"
                  name="SourceId"
                  component="div"
                />
              </div>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="Consulta"
                  placeholder="Consulta"
                  as="textarea"
                  css={textareaCss}
                />
                <ErrorMessage
                  className="form-error"
                  name="Consulta"
                  component="div"
                />
              </div>
              <button
                css={submitCss}
                className="only-desk"
                type="submit"
                disabled={isSubmitting}
              >
                Enviar
              </button>
              <div className="only-desk">
                {envio === false && envio !== "" && (
                  <div className="">Error en el envio</div>
                )}
                {envio === true && envio !== "" && (
                  <div className="">Correo enviado</div>
                )}
              </div>
            </div>
            <div className="form-column">
              <label
                className="form-acept"
                htmlFor="Me_interesa"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Me_interesa"
                  id="Me_interesa"
                />
                &nbsp;&nbsp;&nbsp;Me interesa ser parte de la comunidad de
                Armando Paredes y recibir información.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Me_interesa"
                component="div"
              />
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;Acepto las condiciones de la{" "}
                <a
                  href="/politica-de-privacidad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidad
                </a>
                .
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <button
              css={submitCss}
              className="only-mov"
              type="submit"
              disabled={isSubmitting}
            >
              Enviar
            </button>
            <div className="only-mov">
              {envio === false && envio !== "" && (
                <div className="">Error en el envio</div>
              )}
              {envio === true && envio !== "" && (
                <div className="">Correo enviado</div>
              )}
            </div>
          </FormStyle>
        )}
      </Formik>
    </div>
  )
}
