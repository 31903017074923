import React from 'react';
import ComoForm from "../../organisms/Form/ComoForm"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { css } from "@emotion/react"

export default function BannerComoImaginas({titulo1,titulo2}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
  `;

  return (
    <SectionBackground bgColor="#f3f2e0" widthContent={1050} styleCss={sectionCss}>
      <ComoForm titulo1={titulo1} titulo2={titulo2}/>
    </SectionBackground>
  );

}