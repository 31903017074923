import React from 'react';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Image from "../../atoms/Image/Image"
import { graphql } from "gatsby"
import {css} from '@emotion/react';
import TextArmando from "../../organisms/GroupText/TextArmando"

export default function PrimerosArmando({colorFondo,titulo,imagen,texto}) {

  const contentCss = css`
    background-color:  ${colorFondo};
  `

  const imagenCss = css`
    width: 100%;
  `

  return (
    <SectionBackground  widthContent={1200} styleCssContent={contentCss}>
      <div className="grid-60 line-height-0">
        <TextArmando titulo={titulo} texto={texto} color_titulo="#343434" />
      </div>
      <div className="grid-40 grid-x-0">
        <Image imagen={imagen} styleCss={imagenCss}/>
      </div>
    </SectionBackground>
  );

}

export const queryPrimerosArmando = graphql`
  fragment primerosArmando on Wordpress_Template_Armando_Armando{
    colorFondoPrimeros
    tituloPrimeros
    textoPrimeros
    imagenPrimeros {
      ...imagen
    }
  }
`