import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerProximamente from "../components/templates/Proyecto/BannerProximamente"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      proyecto(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_ProyectoProximamente {
            proyectoProximamente {
              colorFondo
              imagen {
                ...imagen
              }
              titulo
              proyectoId
              paginaDeGracias {
                ... on Wordpress_Proyecto {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function ProyectoProximamente({ data }) {
  // if (typeof window !== "undefined") {
  //   let bodyHtml = document.querySelector("body")
  //   bodyHtml.classList.remove("no-scroll")
  // }

  const page = data?.wordpress?.proyecto
  const template = page?.template?.proyectoProximamente
  const baseUrl = "https://www.armandoparedes.com/proyecto/"

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={`${baseUrl}${page.slug}/`} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page?.title}</h1>
      <BannerProximamente
        imagen={template?.imagen}
        colorFondo={template?.colorFondo}
        titulo={template?.titulo}
        proyectoId={template?.proyectoId}
        urlGracias={template?.paginaDeGracias?.uri}
      />
    </LayoutGeneral>
  )
}
