import React from 'react';
import SeccionContent from "../SeccionContent/SeccionContent";
import Image from "../../atoms/Image/Image";
import {css} from "@emotion/react";

export default function SectionBackground({imagen, children, bgColor, idEle, align, styleCss, widthContent, styleCssContent, bgType, classEle}) {

  let imageFloatCss = {};
  let sectionCss = {};
  switch (bgType){
    case "fixed":
      // imageFloatCss = css`
      //   position: fixed !important;
      //   text-align: ${align};
      //
      // `;
      sectionCss = css`
        // clip-path: inset(0);
         ${styleCss};
         text-align: ${align};
        // background-color: ${bgColor};
        background-image: url(${imagen.sourceUrl});
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      `;
    break;
    default:
      imageFloatCss = css`
        position: absolute !important;
      `;
         sectionCss = css`
          ${styleCss};
          text-align: ${align};
          background-color: ${bgColor};
       `;
      break;
  }

  const imageBgCss = css`
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${imageFloatCss};
  `;

  return (
    <section css={sectionCss} id={idEle} className={classEle}>
      {
        (imagen && bgType !== "fixed") &&
        <Image imagen={imagen} styleCss={imageBgCss}/>
      }
      { children && <SeccionContent children={children} widthContent={widthContent} styleCss={styleCssContent}/>}
    </section>
  );

}

SectionBackground.defaultProps = {
  imagen: null,
  children: '',
  bgColor: 'transparent',
  idEle: '',
  align: 'left',
  bgType: '',
}