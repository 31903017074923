import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import BannerPrograma from "../components/templates/ProgramaReferidos/BannerPrograma"
import CuandoPrograma from "../components/templates/ProgramaReferidos/CuandoPrograma"
import FormPrograma from "../components/templates/ProgramaReferidos/FormPrograma"
import InfoPrograma from "../components/templates/ProgramaReferidos/InfoPrograma"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_ProgramaDeReferidos {
            templateName
            programaDeReferidos {
              ...bannerPrograma
              ...cuandoPrograma
              ...formPrograma
              ...infoPrograma
            }
          }
        }
      }
    }
  }
`

export default function ProgramaReferidos({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.programaDeReferidos

  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <BannerPrograma
        texto={template?.textoBanner}
        imagen={template?.imagenFondoBanner}
      />
      <CuandoPrograma
        titulo={template?.tituloCuando}
        beneficiosTi={template?.beneficiosParaTiCuando}
        beneficiosAmigo={template?.beneficiosParaTuAmigoCuando}
        textoBeneficiosTi={template?.textoBeneficiosParaTiCuando}
        textoBeneficiosAmigo={template?.textBeneficiosParaTuAmigoCuando}
        bono={template?.bonoCuando}
        descuento={template?.descuentoCuando}
      />
      <FormPrograma
        colorFondoBoton={template?.colorFondoBotonEnviarFormulario}
        colorFondoInput={template?.colorFondoCampoFormulario}
        titulo={template?.tituloFormulario}
        colorFondo={template?.colorFondoFormulario}
      />
      <InfoPrograma
        texto={template?.textoFormulario}
        textoLink={template?.textoLinkFormulario}
        textoTerminos={template?.textoTerminosFormulario}
        link={template?.linkFormulario?.uri}
      />
    </LayoutGeneral>
  )
}
