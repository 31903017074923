import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import DescripcionEntregado from "../components/templates/Entregado/DescripcionEntregado"
import GaleriaEntregado from "../components/templates/Entregado/GaleriaEntregado"
import DetalleEntregado from "../components/templates/Entregado/DetalleEntregado"
import InformacionEntregados from "../components/templates/Entregado/InformacionEntregados"
import NavegacionEntregado from "../components/templates/Entregado/NavegacionEntregado"
export const query = graphql`
  query ($id: ID!) {
    wordpress {
      proyectoEntregado(id: $id, idType: DATABASE_ID) {
        title
        slug
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        distritos {
          nodes {
            name
          }
        }
        template {
          ... on Wordpress_Template_Entregado {
            entregado {
              ...navegacionEntregado
              ...descripcionEntregado
              ...galeriaEntregado
              ...detalleEntregado
            }
          }
        }
      }
      ...tituloConoceEntregado
    }
  }
`

export default function ProyectoEntregado({ data }) {
  const page = data?.wordpress?.proyectoEntregado
  const template = page?.template?.entregado
  const pageTitle = page?.title
  const templateProyectos = data?.wordpress?.page?.template?.entregados

  // if(typeof window !== "undefined"){
  //   let bodyHtml = document.querySelector("body");
  //   bodyHtml.classList.remove("no-scroll");
  // }
  const baseUrl = "https://www.armandoparedes.com/proyecto-entregado/"

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={`${baseUrl}${page.slug}/`} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <NavegacionEntregado
        colorFondo={template?.colorFondoNavegacion}
        titulo={pageTitle}
        linkAnterior={template?.proyectoAnteriorNavegacion?.uri}
        linkSiguiente={template?.proyectoSiguienteNavegacion?.uri}
        distrito={page?.distritos.nodes[0]?.name}
        anio={template?.anoGeneral}
      />
      <DescripcionEntregado
        imagen={template?.imagenDescripcion}
        titulo={template?.tituloDescripcion}
        texto={template?.textoDescripcion}
      />
      <GaleriaEntregado galeria={template?.imagenesGaleria} />
      <DetalleEntregado
        imagen={template?.imagenDetalle}
        info={template?.infoDetalle}
        color={template?.colorFondoNavegacion}
      />
      <InformacionEntregados titulo={templateProyectos?.tituloConoceTodos} />
    </LayoutGeneral>
  )
}
