import React from "react"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"
import ProyectoForm from "../../organisms/Form/ProyectoForm"
import { graphql } from "gatsby"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import { css } from "@emotion/react"
import UbicacionWhatsapp from "../../molecules/UbicacionWhatsapp/UbicacionWhatsapp"

export default function FormProyecto({
  title,
  estado,
  text,
  icon,
  distrito,
  colorTextoEstado,
  colorTexto,
  colorFondo,
  colorFondoEstado,
  colorFondoTexto,
  apiInputChannelIds,
  apiNivelId,
  apiProjectRelated,
  apiSourceId,
  paginaGracias,
  presupuesto,
  colorPrimario,
  colorFondoBoton,
}) {
  const sectionContentCss = css`
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    bottom: calc(100vw * (calc(-27 / var(--width_base))));
    right: 0;
    left: 0;
    z-index: 1;
    @media (max-width: 767px) {
      position: relative;
      top: calc(100vw * (calc(-29 / var(--width_base))));
      bottom: initial;
      box-shadow: none;
    }
  `

  const contenidoCss = css`
    //height: calc(100vw * (calc(237 / var(--width_base))));
    overflow: hidden;
    display: flex;
    background-color: white;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      background-color: transparent;
    }
  `

  const distritoCss = css`
    background-color: black;
    display: inline-flex;
    float: right;
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(30 / var(--width_base))));
    border-top-left-radius: calc(100vw * (calc(15 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(15 / var(--width_base))));

    @media (max-width: 767px) {
      padding: calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(20 / var(--width_base))));
      position: absolute;
      right: 0;
      top: calc(100vw * (calc(-4 / var(--width_base))));
      z-index: 1;
    }

    //& .distrito__imagen img, & .distrito__imagen.gatsby-image-wrapper-constrained img{
    & .distrito__imagen {
      height: calc(100vw * (calc(21 / var(--width_base))));
      @media (max-width: 767px) {
        height: calc(100vw * (calc(18 / var(--width_base))));
      }
    }
    & .distrito__imagen {
      margin-right: calc(100vw * (calc(7 / var(--width_base))));
      max-width: calc(100vw * (calc(21 / var(--width_base))));
    }
    & .distrito__text {
      color: white;
      font-size: calc(100vw * (calc(22 / var(--width_base))));
      font-weight: 400;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(16 / var(--width_base))));
        line-height: calc(100vw * (calc(16 / var(--width_base))));
      }
    }
  `

  const textContentCss = css`
    background-color: ${colorFondoTexto};
    color: ${colorTexto};
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(30 / var(--width_base))));
    display: flex;
    align-items: center;
    flex-grow: 1;
  `

  const textCss = css`
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    line-height: calc(100vw * (calc(42 / var(--width_base))));
    font-family: "Lato", sans-serif;

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(17 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
      text-align: center;
      width: 100%;
    }
  `

  const textEstadoCss = css`
    background-color: ${colorFondoEstado};
    color: ${colorTextoEstado};
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    text-align: center;
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(30 / var(--width_base))));
    white-space: nowrap;

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      text-align: left;
      display: inline-block;
      padding: calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(14 / var(--width_base))));
      /* max-width: calc(100vw * (calc(200 / var(--width_base)))); */
    }
  `

  const contentLeft = css`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 100%;
      display: initial;
    }
  `

  const sectionCss = css`
    position: relative;
  `

  return (
    <SectionBackground
      widthContent="1300"
      styleCssContent={sectionContentCss}
      styleCss={sectionCss}
    >
      <div className="grid-100 grid-x-0 relative">
        {icon && distrito && (
          <div css={distritoCss}>
            <Image
              imagen={icon}
              classEle="distrito__imagen"
              objectFit="contain"
            />
            <Title text={distrito} classEle="distrito__text" />
          </div>
        )}
      </div>
      <div className="grid-100 grid-x-0" css={contenidoCss}>
        <div css={contentLeft}>
          <div css={textEstadoCss}>
            {estado && <Title text={estado} styleCss={{ fontWeight: "400" }} />}
          </div>
          <div css={textContentCss}>
            {text && <Paragraph text={text} styleCss={textCss} brMov={true} />}
          </div>
        </div>
        <div css={{ backgroundColor: `${colorFondo}` }}>
          {apiInputChannelIds &&
            apiSourceId &&
            apiProjectRelated &&
            apiSourceId && (
              <ProyectoForm
                title={title}
                colorBoton={colorFondoBoton}
                apiInputChannelIds={apiInputChannelIds}
                apiNivelId={apiNivelId}
                apiProjectRelated={apiProjectRelated}
                apiSourceId={apiSourceId}
                paginaGracias={paginaGracias}
                valoresPresupuesto={presupuesto}
              />
            )}
        </div>
      </div>
      <UbicacionWhatsapp colorIcon={colorPrimario} />
    </SectionBackground>
  )
}

export const queryFormProyecto = graphql`
  fragment formProyecto on Wordpress_Template_Proyecto_Proyecto {
    valoresDePresupuestoFormulario {
      valor
    }
    colorFondoEstadoFormulario
    colorFondoFormulario
    colorFondoTextoFormulario
    colorFondoBotonFormulario
    colorTextoEstadoFormulario
    colorTextoFormulario
    textoFormulario
    estadoFormulario
    distritoIconoFormulario {
      ...imagen
    }
    apiInputChannelIdsFormulario
    apiNivelIdFormulario
    apiProjectRelatedFormulario
    apiSourceIdFormulario
    paginaDeGraciasFormulario {
      ... on Wordpress_Proyecto {
        uri
      }
    }
  }
`

