import React from 'react';
import {Form} from 'formik';
import { css } from "@emotion/react"

export default function FormStyle({children, styleCss, classEle}) {

  const formCss = css`
    & .form-group{
      margin-bottom: calc(100vw * (calc(22 / var(--width_base))));
    }
    
    & .form-control{
      padding: calc(100vw * (calc(8 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))));
      font-family: "Source Sans Pro", sans-serif;
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ccc;
      &::placeholder{
        color: #CFCFCF;
      }
    }
    & .form-label{
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(22 / var(--width_base))));
    }
    ${styleCss};
  `;

  return (
    <Form css={formCss} className={classEle}>
      <div className="">
        {children}
      </div>
    </Form>
  );

}