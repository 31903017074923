import * as React from "react"
import PropTypes from "prop-types"

const LayoutSinNavegacion = ({ classEle,children,...others }) => {

  return (
    <main className={classEle} {...others}>{ children}</main>
  )
}

LayoutSinNavegacion.propTypes = {
  children: PropTypes.node.isRequired,
}

LayoutSinNavegacion.defaultProps = {
  classEle: "mainsinnavgacion"
}

export default LayoutSinNavegacion