import React from 'react';
import Image from "../../atoms/Image/Image"
import Title from "../../atoms/Title/Title"
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import PilotoForm from "../../organisms/Form/PilotoForm"
import { graphql } from "gatsby"
import { css } from "@emotion/react"

export default function FormPiloto({colorFondo, imagen,info, titulo}) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(25 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 1024px) and (min-width: 768px) {
        padding-left: calc(100vw * (calc(80 / var(--width_base))));
        padding-rigth: calc(100vw * (calc(80 / var(--width_base))));
    }
    @media (max-width: 767px) {
        padding-top: 0;
      }
  `;
  const titleCss = css`
    font-family: GopherDisplay-Light, sans-serif;
    color: #3f3d1e;
    text-align: center;
    font-size: calc(100vw * (calc(27/var(--width_base))));
    margin-bottom: calc(100vw * (calc(35/var(--width_base))));
    @media (max-width: 767px) {
        font-size: calc(100vw * (calc(20/var(--width_base))));
    }
  `;
  const titleCss2 = css`
    font-family: GopherDisplay-Light, sans-serif;
    color: #000000;
    text-align: left;
    font-size: calc(100vw * (calc(14/var(--width_base))));
    margin-bottom: calc(100vw * (calc(17/var(--width_base))));
    line-height: calc(100vw * (calc(22/var(--width_base))));
    @media (max-width: 767px) {
        display: none;
    }
  `;

  return (
    <SectionBackground bgColor={colorFondo} widthContent={1653} styleCss={sectionCss}>
      <div className="grid-50 grid-x-0 mov-pb-20">
        <Image imagen={imagen}/>
        <Title text={info} styleCss={titleCss2}/>
      </div>
      <div className="grid-50 text-center">
        <Title text={titulo} styleCss={titleCss}/>
        <PilotoForm/>
      </div>
    </SectionBackground>
  );

}

export const queryFormPiloto = graphql`
  fragment formPiloto on Wordpress_Template_PilotoLosNogales_Pilotolosnogales{
    colorFondo
    imagenFormulario {
      ...imagen
    }
    tituloFormulario
    infoFormulario
  }
`