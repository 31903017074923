import React from 'react';
import NavStep from "./NavStep"
import { css } from "@emotion/react"

export default function Step({children, navElements, navNumActives, active}) {

  const stepCss = css`
    &:not(.is-active){
      display: none;
    }
  `;

  return (
    <div css={stepCss} className={active ? "is-active" : ""}>
      <NavStep dataNav={navElements} numActives={navNumActives} />
      <div>
        {children}
      </div>
    </div>
  );

}