import React from 'react';
import MenuItem from "../../atoms/MenuItem/MenuItem"

export default function List({menuItems, wordPressUrl, styleCss, classEle}) {
  return (
    <ul css={styleCss}>
      {
        menuItems &&
        menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              menuItem={menuItem}
              wordPressUrl={wordPressUrl}/>
          )
        )}
    </ul>
  );
}

List.defaultProps = {
  menuItems: null,
  wordPressUrl: '',
  styleCss: {}
}