import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({settings, styleCss, children, refEle}) {

  return (
    <Slider {...settings} css={styleCss} ref={refEle}>
      {children}
    </Slider>
  );

}