import React, { useCallback, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProyectoEntry from '../../organisms/ProyectoEntry/ProyectoEntry';
import { css } from "@emotion/react"
import { scroller } from "react-scroll"

export default function GridProyectos({distritos, dormitorios, etapas}) {

  const data = useStaticQuery(graphql`
{
  wordpress {
    page(id: "422", idType: DATABASE_ID) {
      title
      template {
        ... on Wordpress_Template_Proyectos {
          templateName
          proyectos {
            grillaProyectos {
              ... on Wordpress_Proyecto {
                title
                uri
                template {
                  ... on Wordpress_Template_Proyecto {
                    templateName
                    proyecto {
                      textoParaHomeDormitoriosGeneral
                    }
                  }
                  ... on Wordpress_Template_ProyectoProximamente {
                    templateName
                    proyectoProximamente {
                      textoParaHomeDormitoriosGeneral
                    }
                  }
                }
                
                featuredImage {
                  node {
                    ...imagen
                  }
                }
                distritos {
                  nodes {
                    name
                  }
                }
                dormitorios {
                  nodes {
                    name
                  }
                }
                etapas {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
  `);

  const proyectos = data?.wordpress?.page?.template?.proyectos?.grillaProyectos;

  const [proyectosMostrar, setProyectosMostrar] = useState([]);
  const [urlPageSearch, seturlPageSearch]  = useState("");
  typeof window !== `undefined` && seturlPageSearch(window.location.search);

  const filtrarProyectos = useCallback(()=>{
      if(urlPageSearch !== ""){
        let params = new URLSearchParams(urlPageSearch);
        let distritoUrl = params.get("distrito");
        let dormitorioUrl = params.get("dormitorio");
        let etapaUrl = params.get("etapa");
        let newProyectos = [...proyectos];
        let arrDistritos = distritoUrl.split("|");
        let arrDormitorios = dormitorioUrl.split("|");
        let arrEtapas = etapaUrl.split("|");

        if(params && (distritoUrl || dormitorioUrl || etapaUrl)){
          if(distritoUrl !== "" ) newProyectos = newProyectos.filter((proyectoM) => arrDistritos.includes(proyectoM?.distritos?.nodes[0]?.name));
          if(etapaUrl !== "" ) newProyectos = newProyectos.filter((proyectoM) =>  arrEtapas.includes(proyectoM?.etapas?.nodes[0]?.name));
          if(dormitorioUrl !== "" ) newProyectos = newProyectos.filter((proyectoM) => proyectoM?.dormitorios?.nodes.some(arrele => arrDormitorios.includes(arrele?.name)));
          setProyectosMostrar(newProyectos);

          scroller.scrollTo('proyectos', {
            duration: 0,
            delay: 0,
            smooth: 'easeInOutQuint',
            offset: (typeof window !== `undefined`) && window.innerWidth * (-90 / 1920)
          })

        }
      }else{
        setProyectosMostrar(proyectos);
      }
    },
    [proyectos, urlPageSearch]
  );



  useEffect(() => {
    filtrarProyectos();
  },[filtrarProyectos]);


  const entryCss = css`
    // margin-bottom: calc(100vw * (calc(87 / var(--width_base))));
    // float: left;
    // @media (max-width: 767px){
    //   margin-bottom: 0;
    // }

    // &:nth-of-type(2n){
    //   float: right;
    // }
  `;



  const imagenOverlayCss = css`
    width: 100%;
    height: calc(100vw * (calc(619 / var(--width_base))));
    @media (max-width: 1440px) and (min-width: 1025px) {
      width: 100%;
      height: calc(100vw * (calc( 519/ var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      width: 100%;
      height: calc(100vw * (calc( 419/ var(--width_base))));
    }
  `;

  const gridProyectos = css`
    display: grid;
    grid-gap: 0 calc(100vw * (calc( 60/ var(--width_base))));
    @media (min-width: 768px){
      grid-template-columns: repeat(2, 1fr);
      grid-gap: calc(100vw * (calc( 100/ var(--width_base)))) calc(100vw * (calc( 60/ var(--width_base))));
    }
  `;

  return (
    <section className="grid-x-0" css={gridProyectos}>
      {
        proyectosMostrar && proyectosMostrar.map((proyecto, i) => (
          <ProyectoEntry proyecto={proyecto} keyEle={i} styleEle={entryCss} styleImageEle={imagenOverlayCss}/>
        ))
      }
    </section>
  )

}

GridProyectos.defaultProps = {
  distritos: "",
  dormitorios: "",
  etapas: ""
}