import React from 'react';
import OthersPage from "./OthersPage"
import { graphql } from "gatsby"

export default function BannerPolitica({titulo, text, bgColor, imagen}) {

  return (
    <OthersPage
      bgColor={bgColor}
      titulo={titulo}
      text={text}
      imagen={imagen}
    />
  );

}

export const queryBannerPolitica = graphql`
  fragment bannerPolitica on Wordpress_Template_PolticaDePrivacidad_Politicadeprivacidad{
    colorFondo
    texto
    imagen {
      ...imagen
    }
  }
`