import * as React from "react"
import Seo from "../components/atoms/Seo/Seo"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import {css} from "@emotion/react";

export default function NotFoundPage () {

  const textCss = css`
    text-align: center;
    margin-top: calc(100vw * (calc(130 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(100 / var(--width_base))));
  `;

  return(
    <LayoutGeneral>
      <Seo title="Armando Paredes - Error 404: página no encontrada" />
      <h1 css={textCss}>Error 404: página no encontrada</h1>
    </LayoutGeneral>
  )
}
