import React from 'react';
import Image from "../../atoms/Image/Image"
import {css} from "@emotion/react"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import TextWhatsapp from "../../molecules/TextWhatsapp/TextWhatsapp"
import DepasForm from "../../organisms/Form/DepasForm"

export default function BannerDepas({
    imagenClara,
    imagenOscura,
    correo,
    direccion,
    logo,
    nroWhatsapp,texto}) {

  const contentlogoCss = css`
    background-color: #1d1d1b;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(40 / var(--width_base))));
    justify-content: center;
    display: flex;
  `;

  const logoCss = css`
    max-width: calc(100vw * (calc(350 / var(--width_base))));
  `;

  const contentForm = css`
    width: calc(100vw * (calc(1226 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(100vw * (calc(150 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(150 / var(--width_base))));
    @media (max-width: 767px){
        padding-top: 0;
        width: 100%;
        padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const contentContact = css`
    width: calc(100vw * (calc(1226 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px){
      width: 100%;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    & *{
      color: white;
    }
  `;

  const contactCss = css`
    background-color: #1d1d1b;
    padding-top: calc(100vw * (calc(20 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    @media (max-width: 767px){
        padding-bottom: calc(100vw * (calc(35 / var(--width_base))));
    }
  `;

  const textWspCss = css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    @media (max-width: 767px){
        justify-content: center;
    }
    & .menu__text{
      font-size: calc(100vw * (calc(18 / var(--width_base))));
    }
    & i.fab{
      margin-left: 0;
      margin-right: calc(100vw * (calc(5 / var(--width_base))));
      @media (max-width: 767px){
          font-size: calc(100vw * (calc(16 / var(--width_base))));
      }
    }
  `;

  const direccionCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 767px){
        margin-top: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const gridLeftCss = css`
    display: flex;
    @media (max-width: 767px){
      background-color: #1d1d1b;
      padding-left: 0;
      padding-right: 0;
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    padding-left: calc(100vw * (calc(9 / var(--width_base))));
    font-style: italic;
    @media (max-width: 767px){
        color: white;
      padding-left: calc(100vw * (calc(25 / var(--width_base))));
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const imagenOscuraCss = css`
    @media (max-width: 767px){
        max-width: calc(100vw * (calc(157 / var(--width_base))));
    }
  `;

  let correoTexto = correo.replace(/<[^>]*>?/g, '');
  const linkCorreo = `<a href="mailto:${correoTexto}">${correoTexto}</a>`;

  return (
    <section>
      <div css={contentlogoCss}>
        <Image imagen={logo} styleCss={logoCss}/>
      </div>
      <div className="grid-100 grid-x-0" css={contentForm}>
        <div className="grid-50 mobile-grid-100" css={gridLeftCss}>
          <div className="only-desk">
            <Image imagen={imagenClara}/>
          </div>
          <div className="only-mov">
            <Image imagen={imagenOscura} styleCss={imagenOscuraCss}/>
          </div>
          <Paragraph text={texto} styleCss={titleCss}/>
        </div>
        <div className="grid-50 mobile-grid-100">
          <DepasForm/>
        </div>
      </div>
      <div css={contactCss}>
        <div css={contentContact}>
          <div>
            <TextWhatsapp iconWhatsapp={true} styleCss={textWspCss}/>
            <div dangerouslySetInnerHTML={{__html: linkCorreo}}/>
          </div>
          <Paragraph text={direccion} styleCss={direccionCss}/>
        </div>
      </div>
    </section>
  );

}